import React, { useContext } from "react";
import * as classNames from "classnames";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useQuoteAction } from "../../../contexts/quoteActionContext";
import { useTableState } from "../../../contexts/tableContext";
import GlobalUiContext from "../../../contexts/globalUiContext";
import { permissionQuote, hasPermission } from "../../../lib/permissions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: "bold",
    minWidth: 160,
  },
  buttonPrimary: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
}));

const QuotesBulkActions = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const hasApproval = hasPermission(permissionQuote.APPROVAL, permissions);
  const hasDueDate = hasPermission(permissionQuote.DUE_DATE, permissions);
  const hasEdit = hasPermission(permissionQuote.EDIT, permissions);
  const quoteAction = useQuoteAction();
  const tableUi = useTableState();
  const dispatch = quoteAction[1];
  const { currentTab } = tableUi;
  const { selected, project } = props;

  const handleOpenDueDate = () => {
    dispatch({
      type: "TOGGLE_DUEDATE",
      open: true,
    });
  };

  const handleOpenApprove = () => {
    dispatch({
      type: "TOGGLE_APPROVE",
      open: true,
    });
  };

  const handleOpenSnooze = () => {
    dispatch({
      type: "TOGGLE_SNOOZE",
      open: true,
    });
  };

  return (
    hasEdit && (
      <div className={classes.root}>
        {[1].includes(currentTab) && (
          <Button
            variant="outlined"
            color="primary"
            size="large"
            className={classNames(classes.button, classes.buttonPrimary)}
            onClick={handleOpenSnooze}
          >
            Snooze
          </Button>
        )}
        {[-1, 0, 1, 6, 10, 20, 30].includes(currentTab) && (
          <>
            {hasDueDate && (
              <Button
                variant="outlined"
                color="primary"
                size="large"
                className={classNames(classes.button, classes.buttonPrimary)}
                onClick={handleOpenDueDate}
              >
                Change Due Date
              </Button>
            )}
            {hasApproval && [1, 6].includes(currentTab) && !selected ? (
              !project ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classNames(
                    classes.button,
                    classes.buttonSecondary
                  )}
                  onClick={handleOpenApprove}
                >
                  Approve
                </Button>
              ) : project &&
                [1, 6].includes(currentTab) &&
                !project.has_completed_worktickets ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classNames(
                    classes.button,
                    classes.buttonSecondary
                  )}
                  onClick={handleOpenApprove}
                >
                  Approve
                </Button>
              ) : (
                ""
              )
            ) : hasApproval &&
              [1, 6].includes(currentTab) &&
              selected &&
              selected === 1 ? (
              !project ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classNames(
                    classes.button,
                    classes.buttonSecondary
                  )}
                  onClick={handleOpenApprove}
                >
                  Approve
                </Button>
              ) : project &&
                [1, 6].includes(currentTab) &&
                !project.has_completed_worktickets ? (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  className={classNames(
                    classes.button,
                    classes.buttonSecondary
                  )}
                  onClick={handleOpenApprove}
                >
                  Approve
                </Button>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </>
        )}
      </div>
    )
  );
};

export default QuotesBulkActions;
