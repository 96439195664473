import React, { useState, useContext, useEffect } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { ButtonBase } from "components/ui/core/button";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketState } from "contexts/workticketContext";
import { useWorkticketAction } from "contexts/workticketActionContext";
import { useTableState, useTableDispatch } from "contexts/tableContext";
import { workticketExport } from "services/workticketService";
import { logException } from "components/util/logUtil";
import { permissionWorkticket, hasPermission } from "lib/permissions";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "inline-block",
    paddingTop: 3,
    paddingBottom: 3,
  },
  button: {
    textTransform: "Capitalize",
    paddingLeft: 15,
    paddingRight: 15,
    marginLeft: 10,
    marginRight: 10,
    fontWeight: "bold",
    minWidth: 160,
  },
  buttonPrimary: {
    color: "#4f98bc",
    background: "#ffffff",
  },
  buttonSecondary: {
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4f98bc",
    },
  },
}));

const CATEGORY_NOT_MARK_DONE = [7];

const WorkticketsBulkActions = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [markDoneActive, setMarkDoneActive] = useState(true);
  const workticketAction = useWorkticketAction();
  const { scheduleMode } = useWorkticketState();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const tableUi = useTableState();
  const dispatchTable = useTableDispatch();
  const dispatch = workticketAction[1];
  const { currentTab, selected, rows } = tableUi;

  useEffect(() => {
    const selectedRows = rows
      .filter((item) => selected.includes(item.id))
      .filter((item) =>
        CATEGORY_NOT_MARK_DONE.includes(item.workticket_category)
      );

    if (selectedRows && selected.length === selectedRows.length) {
      setMarkDoneActive(false);
    } else {
      setMarkDoneActive(true);
    }
  }, [selected, rows]);

  // const handleOpenSchedule = () => {
  //   dispatch({
  //     type: "TOGGLE_SCHEDULE",
  //     open: true,
  //   });
  // };

  const handleOpenDueDate = () => {
    dispatch({
      type: "TOGGLE_DUEDATE",
      open: true,
    });
  };

  const handleOpenDone = () => {
    dispatch({
      type: "TOGGLE_DONE",
      open: true,
    });
  };

  const handleOpenAssign = () => {
    dispatch({
      type: "TOGGLE_ASSIGN_MULTIPLE",
      open: true,
    });
  };

  const handleDownload = async () => {
    try {
      setIsLoading(true);
      const result = await workticketExport({ entry_ids: selected });
      setIsLoading(false);
      dispatchTable({
        type: "SET_SELECTED",
        selected: [],
      });
      window.open(result.data.data.file.presigned_url);
    } catch (e) {
      logException(e, "Cannot export timekeeping entries");
    }
  };

  return (
    <Box className={classes.root}>
      {[0, 1, 80].includes(currentTab) && (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          className={classNames(classes.button, classes.buttonPrimary)}
          onClick={handleOpenAssign}
        >
          Assign
        </Button>
      )}
      {[4, 40, 30, 10, 20, 0, 1, 80].includes(currentTab) && (
        <>
          {/* <Button
              variant="outlined"
              color="primary"
              size="large"
              className={classNames(classes.button, classes.buttonPrimary)}
              onClick={handleOpenSchedule}
            >
              Schedule
            </Button> */}
          {hasPermission(permissionWorkticket.EDIT, permissions) ? (
            <Button
              variant="outlined"
              color="primary"
              size="large"
              className={classNames(classes.button, classes.buttonPrimary)}
              onClick={handleOpenDueDate}
            >
              Change Due Date
            </Button>
          ) : null}
          {hasPermission(permissionWorkticket.MARK_DONE, permissions) &&
            !scheduleMode &&
            markDoneActive && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classNames(classes.button, classes.buttonSecondary)}
                onClick={handleOpenDone}
              >
                Mark Verified
              </Button>
            )}
          {!hasPermission(permissionWorkticket.MARK_DONE, permissions) &&
            hasPermission(permissionWorkticket.STATUS_UPDATE, permissions) &&
            !scheduleMode &&
            markDoneActive && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classNames(classes.button, classes.buttonSecondary)}
                onClick={handleOpenDone}
              >
                Mark Completed
              </Button>
            )}
        </>
      )}
      {hasPermission(permissionWorkticket.EXPORT_LIST, permissions) ? (
        <ButtonBase
          variant="outlined"
          color="secondary"
          disabled={isLoading}
          onClick={handleDownload}
        >
          Download
        </ButtonBase>
      ) : null}
    </Box>
  );
};

export default WorkticketsBulkActions;
