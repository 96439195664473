import React from "react";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  loadingContainer: {
    padding: 8,
    background: "#F8F8F8",
    boxShadow: "0px 2px 20px rgba(88.54, 88.54, 88.54, 0.25)",
    borderRadius: 4,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    gap: 10,
    display: "inline-flex",
  },
}));
export const Style = () => {
  return (
    <style>
      {`
          .loader-container {
            display: flex;
            justify-content: center;
            align-items: center;
            }

            .loader {
            border: 2px solid #D9D9D9; /* Light grey */
            border-top: 2px solid #4F98BC; /* Blue */
            border-radius: 50%;
            width: 18px;
            height: 18px;
            animation: spin 1.5s linear infinite;
            }

            @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
            }
        `}
    </style>
  );
};
export default useStyles;
