import React, { useEffect, useState, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import OfficeBuildingIcon from "../icons/OfficeBuilding";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { usePartnerMainInfo } from "./usePartnerMainInfo";
import { logException } from "components/util/logUtil";
import { getStates, getCities } from "services/partnerService";
import { updatePartner } from "services/partners/partnerService";
import { ownershipTypes } from "constants.js";
import { useHistory } from "react-router-dom";
import Joi from "joi-browser";
import useStyles from "../styles";

const phoneNumberRegex = /^(\+1\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
const schema = Joi.object({
  companyName: Joi.string()
    .required()
    .label("Company Name")
    .error(() => {
      return {
        message: "Company Name is required.",
      };
    }),
  contactName: Joi.string()
    .required()
    .label("Contact Name")
    .error(() => {
      return {
        message: "Contact Name is required.",
      };
    }),
  phoneNumber: Joi.string()
    .regex(phoneNumberRegex)
    .required()
    .label("Phone Number")
    .error(() => {
      return {
        message: "Phone Number is required and must be a valid phone number.",
      };
    }),
  companyState: Joi.object({
    value: Joi.string().required(),
    label: Joi.string().required(),
  })
    .required()
    .label("Company State")
    .error(() => {
      return {
        message: "Company State is required.",
      };
    }),
});

export function ProfileUpdate({ id, mode }) {
  const classes = useStyles();
  const history = useHistory();
  const { partnerMainData, isPartnerMainInfoLoading, refetch } =
    usePartnerMainInfo();
  const [vendorId, setVendorId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [companyState, setCompanyState] = useState(null);
  const [companyCity, setCompanyCity] = useState(null);
  const [cityIdAssigned, setCityIdAssigned] = useState(null);
  const [filteredCities, setFilteredCities] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [ownedTypes, setOwnedTypes] = useState({
    woman_owned: false,
    disability_owned: false,
    veteran_owned: false,
    minority_owned: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statesResponse = await getStates();
        setStatesList(statesResponse.data.data.states || []);

        const citiesResponse = await getCities();
        setCitiesList(citiesResponse.data.data.cities || []);
      } catch (error) {
        logException(error, "Error fetching states or cities data");
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (partnerMainData) {
      const state = statesList.find(
        (state) => state.abbreviation === partnerMainData.state_abbreviation
      );
      const city = citiesList.find(
        (city) => city.id === partnerMainData.city_id
      );

      setCompanyState(
        state ? { value: state.abbreviation, label: state.name } : null
      );
      setVendorId(partnerMainData.vendorId || "");
      setCompanyCity(city ? { value: city.id, label: city.city_name } : null);
      setCityIdAssigned(partnerMainData.city_id || null);
      setVendorId(partnerMainData.external_partner_number || "");
      setCompanyName(partnerMainData.company_name || "");
      setWebsite(partnerMainData.company_website || "");
      setContactName(partnerMainData.contact_name || "");
      setEmail(partnerMainData.contact_email || "");
      setPhoneNumber(partnerMainData.phone_number || "");
      setCompanyAddress(partnerMainData.address || "");
      setOwnedTypes({
        woman_owned: partnerMainData.woman_owned === "1",
        disability_owned: partnerMainData.disability_owned === "1",
        veteran_owned: partnerMainData.veteran_owned === "1",
        minority_owned: partnerMainData.minority_owned === "1",
      });
    }
  }, [partnerMainData, statesList, citiesList]);

  useEffect(() => {
    if (!companyState) {
      setFilteredCities([]);
      setCompanyCity(null);
      return;
    }

    const filtered = citiesList.filter(
      (city) => city.state_abbreviation === companyState.value
    );
    setFilteredCities(filtered);

    const cityInFiltered = filtered.find(
      (city) => parseInt(city.id) === parseInt(cityIdAssigned)
    );

    setCompanyCity(
      cityInFiltered
        ? { value: cityInFiltered.id, label: cityInFiltered.city_name }
        : null
    );
  }, [companyState, citiesList, cityIdAssigned]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    const setters = {
      companyName: setCompanyName,
      contactName: setContactName,
      email: setEmail,
      phoneNumber: setPhoneNumber,
      companyAddress: setCompanyAddress,
      vendorId: setVendorId,
    };
    if (setters[id]) {
      setters[id](value);
      if (value) {
        setErrors((prev) => ({ ...prev, [id]: "" }));
      }
    }
  };

  const validate = () => {
    const { error } = schema.validate(
      {
        companyName,
        companyState: companyState
          ? { value: companyState.value, label: companyState.label }
          : null,
        contactName,
        phoneNumber,
      },
      { abortEarly: false }
    );

    if (!error) return null;

    const newErrors = {};
    error.details.forEach((detail) => {
      newErrors[detail.path[0]] = detail.message;
    });
    return newErrors;
  };

  const handleUpdate = async () => {
    const errors = validate();
    setErrors(errors || {});
    if (errors) return;

    const data = {
      partner_id: id,
      data: {
        company_name: companyName,
        company_website: website,
        state_abbreviation: companyState.value,
        city_id: companyCity?.value ?? null,
        address: companyAddress,
        contact_name: contactName,
        contact_email: email,
        phone_number: phoneNumber,
        woman_owned: ownedTypes.woman_owned ? "1" : "0",
        disability_owned: ownedTypes.disability_owned ? "1" : "0",
        veteran_owned: ownedTypes.veteran_owned ? "1" : "0",
        minority_owned: ownedTypes.minority_owned ? "1" : "0",
        external_partner_number: vendorId,
      },
    };

    setIsLoadingData(true);
    try {
      await updatePartner(data);
      setIsSuccessDialogOpen(true);
      refetch();
    } catch (error) {
      logException(error, "Error updating partner");
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleCancel = () => {
    history.push(`/partner/${id}`);
  };

  const handleSelectStateChange = (event, newValue) => {
    setCityIdAssigned(null);
    setCompanyState(newValue);
    if (!newValue || !newValue.value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        companyState: "Company state is required",
      }));
    } else {
      setErrors((prevErrors) => {
        const { companyState, ...rest } = prevErrors;
        return rest;
      });
    }
  };
  const handleSelectCityChange = (event, newValue) => {
    setCompanyCity(newValue);
    // if (!newValue || !newValue.value) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     companyCity: "Company City is required",
    //   }));
    // } else {
    //   setErrors((prevErrors) => {
    //     const { companyCity, ...rest } = prevErrors;
    //     return rest;
    //   });
    // }
  };
  const handleOwnedTypeChange = (optionKey) => {
    setOwnedTypes((prevState) => ({
      ...prevState,
      [optionKey]: !prevState[optionKey],
    }));
  };
  const handleCloseSuccessDialog = () => setIsSuccessDialogOpen(false);

  return (
    <Fragment>
      <Dialog
        open={isLoadingData || isPartnerMainInfoLoading}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <LoadingIndicator />
        </DialogContent>
      </Dialog>

      <Box className={classes.partnersWrapper}>
        <Box className={classes.headerBoxEdit}>
          <Box className={classes.externalHeaderWrapper}>
            <Box>
              <Box className={classes.officeBuildingIconHolder}>
                <OfficeBuildingIcon />
              </Box>
            </Box>
            <Box className={classes.innerHeaderWrapper}>
              <Grid container>
                <Grid item xs={12} sm={3} md={3}>
                  <FormControl className={classes.formControl} fullWidth>
                    <FormLabel
                      htmlFor="vendorId"
                      className={classes.labelInputs}
                    >
                      Vendor Id
                    </FormLabel>
                    <TextField
                      id="vendorId"
                      name="vendorId"
                      variant="standard"
                      placeholder="Write Vendor Id"
                      value={vendorId}
                      onChange={handleChange}
                      InputProps={{ disableUnderline: true }}
                      className={classes.inputField}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl className={classes.formControl} fullWidth>
                <FormLabel
                  htmlFor="companyName"
                  className={classes.labelInputs}
                >
                  Company Name*
                </FormLabel>
                <TextField
                  id="companyName"
                  name="companyName"
                  variant="standard"
                  placeholder="Write the Name"
                  value={companyName}
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  className={classes.inputField}
                />
                {errors.companyName && (
                  <FormHelperText className={classes.formHelperText}>
                    {errors.companyName}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl className={classes.formControl} fullWidth>
                <FormLabel
                  htmlFor="companyWebsite"
                  className={classes.labelInputs}
                >
                  Company Website
                </FormLabel>
                <TextField
                  id="companyWebsite"
                  name="companyWebsite"
                  variant="standard"
                  placeholder="Write the website"
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                  InputProps={{ disableUnderline: true }}
                  className={classes.inputField}
                />
              </FormControl>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <FormLabel className={classes.labelInputs}>
                      Company State*
                    </FormLabel>
                    <FormSelectAuto
                      id="companyState"
                      name="companyState"
                      placeholder="Select State"
                      value={companyState}
                      handleChange={handleSelectStateChange}
                      options={statesList.map((state) => ({
                        value: state.abbreviation,
                        label: state.name,
                      }))}
                      error={
                        errors.companyState
                          ? [
                              {
                                key: "companyState",
                                message: errors.companyState,
                              },
                            ]
                          : []
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl className={classes.formControl} fullWidth>
                    <FormLabel className={classes.labelInputs}>
                      Company City
                    </FormLabel>
                    <FormSelectAuto
                      id="companyCity"
                      name="companyCity"
                      placeholder="Select City"
                      disabled={!companyState}
                      value={companyCity}
                      handleChange={handleSelectCityChange}
                      options={filteredCities.map((city) => ({
                        value: city.id,
                        label: city.city_name,
                      }))}
                      error={
                        errors.companyCity
                          ? [
                              {
                                key: "companyCity",
                                message: errors.companyCity,
                              },
                            ]
                          : []
                      }
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <FormControl className={classes.formControl} fullWidth>
                <FormLabel
                  htmlFor="companyAddress"
                  className={classes.labelInputs}
                >
                  Company Address
                </FormLabel>
                <TextField
                  id="companyAddress"
                  name="companyAddress"
                  variant="standard"
                  placeholder="Write Address"
                  value={companyAddress}
                  onChange={handleChange}
                  InputProps={{ disableUnderline: true }}
                  className={classes.inputField}
                />
                {errors.companyAddress && (
                  <FormHelperText className={classes.formHelperText}>
                    {errors.companyAddress}
                  </FormHelperText>
                )}
              </FormControl>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={5} md={5}>
                  <FormControl className={classes.formControl} fullWidth>
                    <FormLabel
                      htmlFor="contactName"
                      className={classes.labelInputs}
                    >
                      Contact Name*
                    </FormLabel>
                    <TextField
                      id="contactName"
                      name="contactName"
                      variant="standard"
                      placeholder="Write a Name"
                      value={contactName}
                      onChange={handleChange}
                      InputProps={{ disableUnderline: true }}
                      className={classes.inputField}
                    />
                    {errors.contactName && (
                      <FormHelperText className={classes.formHelperText}>
                        {errors.contactName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4} md={5}>
                  <FormControl className={classes.formControl} fullWidth>
                    <FormLabel
                      htmlFor="contactEmail"
                      className={classes.labelInputs}
                    >
                      Contact Email*
                    </FormLabel>
                    <TextField
                      id="email"
                      name="email"
                      variant="standard"
                      placeholder="Write an Email"
                      value={email}
                      InputProps={{ disableUnderline: true }}
                      className={classes.inputField}
                      disabled={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3} md={2}>
                  <FormControl className={classes.formControl} fullWidth>
                    <FormLabel
                      htmlFor="phoneNumber"
                      className={classes.labelInputs}
                    >
                      Phone Number*
                    </FormLabel>
                    <TextField
                      id="phoneNumber"
                      name="phoneNumber"
                      variant="standard"
                      placeholder="Write Number"
                      value={phoneNumber}
                      onChange={handleChange}
                      InputProps={{ disableUnderline: true }}
                      className={classes.inputField}
                    />
                    {errors.phoneNumber && (
                      <FormHelperText className={classes.formHelperText}>
                        {errors.phoneNumber}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <Box className={classes.checkboxListContainer}>
                    {ownershipTypes.map((option) => (
                      <Box
                        key={option.value}
                        className={classes.checkboxWrapper}
                        display="flex"
                        alignItems="center"
                      >
                        <Checkbox
                          checked={ownedTypes[option.value]}
                          onChange={() => handleOwnedTypeChange(option.value)}
                          className={classes.filterCheckbox}
                        />
                        <Typography
                          variant="body1"
                          className={classes.checkboxLabel}
                        >
                          {option.label}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button
                  variant="contained"
                  className={classes.cancelButton}
                  style={{ marginRight: 16, minWidth: 100 }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleUpdate}
                  style={{ minWidth: 100 }}
                  className={classes.formBtn}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <MessageDialog
          open={isSuccessDialogOpen}
          title="Success!"
          message="The partner has been updated successfully."
          handleClose={handleCloseSuccessDialog}
        />
      </Box>
    </Fragment>
  );
}
