import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  root: {
    backgroundColor: "transparent",
    boxShadow: "none",
    fontSize: "10px",
    padding: "0px 6px",
    fontWeight: "700",
    borderColor: "#78c1ce",
    marginLeft: "10px",
    textTransform: "default",
    minWidth: 0,
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "rgba(116, 116, 116, 0.08)"
    }
  }
});

export default withStyles(styles)(props => (
  <Button variant="contained" {...props}>
    <Typography
      style={{
        fontSize: "10px",
        textDecoration: "underline"
      }}
    >
      Cancel
    </Typography>
  </Button>
));
