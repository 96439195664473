import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/CloudDownloadOutlined";
import CommentIcon from "@material-ui/icons/QuestionAnswerOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import {
  imageIcon,
  documentIcon,
  videoIcon,
  chatIconSolid,
  chatIconOutlined,
  linkIcon,
} from "./icons";
import ConfirmationDialog from "./confirmationDialog";
import CommentDialog from "./commentDialog";
import MessageDialog from "./dialog/messageDialog";
import FilePreviewPartnerDialog from "./filePreviewPartnerDialog";
import { dateFormatField } from "components/util/timeFormat";
import { logException } from "components/util/logUtil";
import { deleteFile, updateFile } from "services/fileService";
import { mimeTypes } from "constants.js";
import * as classNames from "classnames";
import useStyles from "./styles";

const iconMap = {
  1: documentIcon(),
  2: imageIcon(),
  3: videoIcon(),
  4: linkIcon(),
  5: linkIcon(),
};

const getMenuOptions = (row) => {
  const options = [
    {
      id: 1,
      label: "Edit Name",
      value: "EDIT",
      icon: <EditIcon fontSize="inherit" />,
    },
  ];

  options.push({
    id: 4,
    label: "Add Comment",
    value: "ADD_COMMENT",
    icon: <CommentIcon fontSize="inherit" />,
  });

  if (parseInt(row.mime_type) !== 4) {
    options.push({
      id: 5,
      label: "Download",
      value: "DOWNLOAD",
      icon: <DownloadIcon fontSize="inherit" />,
    });
  }

  options.push({
    id: 6,
    label: "Delete",
    value: "DELETE",
    icon: <DeleteIcon fontSize="inherit" />,
  });

  return options;
};

const columnNames = ["", "Name", "Owner", "Size", "Uploaded", ""];

const TablePartnerComponent = ({ data, partnerId, refreshData }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [newName, setNewName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [openCollapsibleIndex, setOpenCollapsibleIndex] = useState(null);
  const [openComment, setOpenComment] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);

    if (openCollapsibleIndex !== null) {
      setOpenCollapsibleIndex(null);
    }
  };

  const closeMessage = () => {
    setOpenMessage(false);
  };

  const handleClose = (option) => {
    setAnchorEl(null);
    setSelectedRow(null);
    if (option) {
      handleSelect(option);
    }
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    const actionHandlers = {
      EDIT: handleEditClick,
      ADD_COMMENT: handleAddCommentClick,
      DELETE: handleDeleteClick,
      DOWNLOAD: handleDownloadClick,
    };

    const selectedAction = actionHandlers[action];
    if (selectedAction) {
      selectedAction();
    }
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setOpenEditDialog(true);
    setSelectedRow(selectedRow);
    setNewName(selectedRow.display_name);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setNameError(false);
  };

  const handleConfirmEdit = async () => {
    if (!newName.trim()) {
      setNameError(true);
      return;
    }
    const fileExtension = selectedRow.display_name.split(".").pop();
    const baseName = newName.includes(".")
      ? newName.split(".").slice(0, -1).join(".")
      : newName;

    setIsLoadingData(true);
    setOpenEditDialog(false);
    setOpenMessage(true);
    const data = {
      display_name: `${baseName}.${fileExtension}`,
      description: "",
    };
    try {
      await updateFile(selectedRow.id, data);
      refreshData();
      setOpenMessageContent("File has been updated successfully.");
      setIsLoadingData(false);
    } catch (e) {
      logException(e, "Cannot update file");
      setIsLoadingData(false);
    }
  };

  const handleDeleteClick = () => {
    setOpenDelete(true);
    setSelectedRow(selectedRow);
  };

  const handleAddCommentClick = () => {
    setOpenComment(true);
  };

  const handleCommentsToggle = (index, row) => {
    setSelectedRow(row);
    setOpenComment(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = async () => {
    try {
      setIsLoadingData(true);
      setOpenDelete(false);
      setOpenMessage(true);
      await deleteFile(selectedRow.id);
      refreshData();
      setOpenMessageContent("File has been deleted successfully.");
      setIsLoadingData(false);
    } catch (e) {
      logException(e, "Cannot remove file");
      setIsLoadingData(false);
    }
  };

  const handleDownloadClick = () => {
    if (selectedRow) {
      window.open(selectedRow.file_url, "_blank");
    }
  };

  const renderEmptyTableBody = () => {
    return (
      <>
        <TableRow className={classes.emptyTableRow}>
          {columnNames.map((columnName, index) => (
            <TableCell
              align="center"
              key={index}
              className={classNames(
                classes.emptyTableCells,
                classes.headerTableText,
                {
                  [classes.nameCell]: index === 2,
                }
              )}
            ></TableCell>
          ))}
        </TableRow>
        <TableRow>
          <TableCell
            colSpan={columnNames.length}
            className={classes.noFilesCell}
          >
            <Typography variant="body1">No files uploaded yet.</Typography>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <Box>
      <Box className={classes.tableContainer}>
        <TableContainer>
          <Table
            aria-label="simple table"
            className={classes.tableWorkticketFiles}
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                {columnNames.map((name, index) => (
                  <TableCell
                    align="left"
                    key={index}
                    className={classNames(
                      classes.emptyTableCells,
                      classes.headerTableText,
                      {
                        [classes.nameCell]: index === 2,
                      }
                    )}
                  >
                    {name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.length > 0
                ? data.map((row, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        className={classNames(
                          {
                            [classes.stripedRow]: index % 2 !== 0,
                          },
                          classes.borderRow
                        )}
                      >
                        <TableCell className={classes.imageCell}>
                          <IconButton
                            onClick={() => handleThumbnailClick(index)}
                          >
                            {iconMap[row.mime_type]}
                          </IconButton>
                        </TableCell>
                        <TableCell className={classes.nameCell}>
                          {[mimeTypes.LINK, mimeTypes.LINK_360].includes(
                            parseInt(row.mime_type)
                          ) ? (
                            <Typography
                              variant="body1"
                              className={classes.linkStyle}
                              onClick={() => handleThumbnailClick(index)}
                            >
                              {row.display_name}
                            </Typography>
                          ) : (
                            row.display_name
                          )}
                        </TableCell>
                        <TableCell>
                          <nobr>{row.owner}</nobr>
                        </TableCell>
                        <TableCell className={classes.sizeCell}>
                          <nobr>
                            {row.file_size ? row.file_size + " MB" : " "}
                          </nobr>
                        </TableCell>
                        <TableCell className={classes.uploadedCell}>
                          {dateFormatField(row.created_at)}
                        </TableCell>
                        <TableCell className={classes.cellContent}>
                          <IconButton
                            onClick={() => handleCommentsToggle(index, row)}
                          >
                            {parseInt(row.has_comments) === 1
                              ? chatIconSolid()
                              : chatIconOutlined()}
                          </IconButton>
                          <IconButton
                            onClick={(event) => handleClick(event, row)}
                          >
                            <MoreHorizIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl) && selectedRow === row}
                        onClose={handleClose}
                      >
                        {getMenuOptions(row).map((option, index) => (
                          <MenuItem
                            key={index}
                            onClick={handleSelect}
                            data={option.value}
                          >
                            <Box className={classes.menuIcon}>
                              {option.icon}
                            </Box>
                            <Typography
                              variant="caption"
                              className={classes.menuText}
                            >
                              {option.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Menu>
                    </React.Fragment>
                  ))
                : renderEmptyTableBody()}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <ConfirmationDialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        onConfirm={handleConfirmEdit}
        title="Documents"
        body={
          <>
            <FormControl
              className={classes.formControl}
              fullWidth
              style={{ textAlign: "left" }}
            >
              <FormLabel
                className={classes.videoLinkLabelText}
                style={{ paddingTop: 0 }}
              >
                Name
              </FormLabel>
              <TextField
                className={classes.videoLinkFormText}
                variant="outlined"
                size="small"
                value={newName}
                error={nameError}
                helperText={nameError ? "Name is Required" : ""}
                FormHelperTextProps={{ style: { marginLeft: 0 } }}
                onChange={(e) => {
                  setNewName(e.target.value);
                  setNameError(false);
                }}
                inputProps={{ style: { fontSize: 14 } }}
              />
            </FormControl>
          </>
        }
      />
      <ConfirmationDialog
        open={openDelete}
        onClose={handleCloseDelete}
        onConfirm={handleConfirmDelete}
        title={"Documents"}
        content="Are you sure you want to remove the file?"
      />
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
      />
      <CommentDialog
        open={openComment}
        partnerId={partnerId}
        fileId={selectedRow?.id}
        handleDialogClose={() => setOpenComment(false)}
      />
      {selectedImageIndex !== null && openDialog && (
        <FilePreviewPartnerDialog
          open={openDialog}
          onClose={handleCloseDialog}
          classes={classes}
          files={data}
          partnerId={partnerId}
          currentImageIndex={selectedImageIndex}
        />
      )}
    </Box>
  );
};
export default TablePartnerComponent;
