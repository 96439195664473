import React, { useState, useContext } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useQuoteAction } from "../../contexts/quoteActionContext";
import GlobalUiContext from "../../contexts/globalUiContext";
import { useQuoteDispatch } from "../../contexts/quoteTableContext";
import { permissionQuote, hasPermission } from "../../lib/permissions";

const QuoteTableActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const quoteAction = useQuoteAction();
  const dispatcQuoteAction = quoteAction[1];
  const dispatchQuotes = useQuoteDispatch();
  const open = Boolean(anchorEl);
  const { row, project } = props;
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const hasApproval = hasPermission(permissionQuote.APPROVAL, permissions);
  const hasEdit = hasPermission(permissionQuote.EDIT, permissions);
  const hasDelete = hasPermission(permissionQuote.DELETE, permissions);

  const options = [];

  if (hasApproval) {
    options.push({
      id: 1,
      label: "Approve",
      value: "APPROVE",
      available: [1, 7, 6, 20, 30, 40],
    });
    options.push({
      id: 2,
      label: "Reject",
      value: "REJECT",
      available: [1, 5, 7, 6, 20, 30, 40],
    });
  }
  if (hasEdit) {
    options.push({
      id: 3,
      label: "Clone",
      value: "CLONE",
      available: [0, 1, 5, 6, 7, 10, 20, 30, 40],
    });
    options.push({
      id: 4,
      label: "Snooze",
      value: "SNOOZE",
      available: [1],
    });
    options.push({
      id: 5,
      label: "Complete",
      value: "COMPLETE",
      available: [0],
    });
  }
  if (hasDelete) {
    options.push({
      id: 6,
      label: "Delete",
      value: "DELETE",
      available: [0, 6, 10],
    });
  }

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    const action = event.currentTarget.getAttribute("data");
    let type = "";
    switch (action) {
      case "APPROVE":
        type = "TOGGLE_APPROVE_SINGLE";
        break;
      case "REJECT":
        type = "TOGGLE_REJECT_SINGLE";
        break;
      case "CLONE":
        type = "TOGGLE_CLONE_SINGLE";
        break;
      case "SNOOZE":
        type = "TOGGLE_SNOOZE_SINGLE";
        break;
      case "COMPLETE":
        type = "TOGGLE_COMPLETE_SINGLE";
        break;
      case "DELETE":
        type = "TOGGLE_DELETE_SINGLE";
        break;
      default:
        break;
    }
    dispatcQuoteAction({
      type: type,
      open: true,
    });
    dispatchQuotes({
      type: "SET_QUOTE_SELECTED",
      quoteSelected: row,
    });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        {options.map((option) =>
          option.available.includes(row.status) && !project ? (
            <MenuItem
              key={option.id}
              onClick={handleSelect}
              data={option.value}
            >
              {option.label}
            </MenuItem>
          ) : option.available.includes(row.status) && project ? (
            option.label === "Approve" && project.has_completed_worktickets ? (
              ""
            ) : (
              <MenuItem
                key={option.id}
                onClick={handleSelect}
                data={option.value}
              >
                {option.label}
              </MenuItem>
            )
          ) : (
            ""
          )
        )}
      </Menu>
    </div>
  );
};

export default QuoteTableActions;
