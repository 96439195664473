import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addPartnerAnswer,
  deletePartnerQuestion,
  getPartnersQuestions,
  updatePartnerQuestion,
} from "../../../services/partners/questionsService";
import { useGetUserId } from "../context/UserIdContext";

export function useQuestionsLogic() {
  const [isDeleteQuestionDialogueOpen, setIsDeleteQuestionDialogueOpen] =
    useState(false);
  const [deleteQuestionId, setDeleteQuestionId] = useState(null);
  const { userId } = useGetUserId();
  const queryClient = useQueryClient();
  const [expanded, setExpanded] = useState([]);
  const [anchorEls, setAnchorEls] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const [questionTitle, setQuestionTitle] = useState("");
  const [questionAnswer, setQuestionAnswer] = useState("");

  const { data, isLoading } = useQuery(
    ["questions", userId],
    async () => {
      const { data } = await getPartnersQuestions(userId);
      return data.data.partner_questions;
    },
    {
      enabled: !!userId,
    }
  );

  const handleChange = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    setExpanded(newExpanded);
  };

  const handleClose = (index) => () => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };
  const handleCloseOnDelete = (index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = null;
    setAnchorEls(newAnchorEls);
  };

  const handleClick = (event, index) => {
    const newAnchorEls = [...anchorEls];
    newAnchorEls[index] = event.currentTarget;
    setAnchorEls(newAnchorEls);
  };

  const handleEditClick = (index) => {
    setEditIndex(index);
  };

  const handleTitleChange = (index, newTitle) => {
    setQuestionTitle(newTitle);
  };

  const handleBodyChange = (index, event) => {
    setQuestionAnswer(event.target.value);
  };

  const handleOnDelete = (question_id) => {
    setLoadingState(true);
    const dummyOjb = {
      question_id: question_id,
    };
    deleteMutation.mutate({ data: dummyOjb, partner_id: userId });
  };

  const handleEditQuestion = (index, question) => {
    const data = { question_id: question.id, question: questionTitle };
    setLoadingState(true);
    editMutation.mutate({
      data,
      partner_id: userId,
    });
  };

  const handleUploadAnswer = (question) => {
    const data = { question_id: question.id, answer: questionAnswer };
    setLoadingState(true);
    addAnswerMutation.mutate({
      data,
      partner_id: userId,
    });
  };

  const deleteMutation = useMutation(deletePartnerQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries("questions");
      setLoadingState(false);
    },
    onError: () => {
      setLoadingState(false);
    },
  });
  const handleEditingQuestionAnswer = (question, index, hasPermissions) => {
    if (hasPermissions) {
      if (questionTitle.length === 0) {
      } else {
        handleEditQuestion(index, question);
      }
      if (questionAnswer.length === 0) {
      } else {
        handleUploadAnswer(question);
      }
    } else {
      if (questionAnswer.length === 0) {
      } else {
        handleUploadAnswer(question);
      }
    }
  };
  const editMutation = useMutation(updatePartnerQuestion, {
    onSuccess: () => {
      queryClient.invalidateQueries("questions");
      setQuestionTitle("");
      setEditIndex(null);
      setLoadingState(false);
    },
    onError: () => {
      setLoadingState(false);
    },
  });

  const addAnswerMutation = useMutation(addPartnerAnswer, {
    onSuccess: () => {
      queryClient.invalidateQueries("questions");
      setQuestionAnswer("");
      setEditIndex(null);
      setLoadingState(false);
    },
    onError: () => {
      setLoadingState(false);
    },
  });

  return {
    data,
    isLoading,
    expanded,
    anchorEls,
    editIndex,
    setEditIndex,
    loadingState,
    questionTitle,
    questionAnswer,
    handleChange,
    handleClose,
    handleClick,
    handleEditClick,
    handleTitleChange,
    handleBodyChange,
    handleOnDelete,
    handleEditQuestion,
    handleUploadAnswer,
    isDeleteQuestionDialogueOpen,
    setIsDeleteQuestionDialogueOpen,
    handleCloseOnDelete,
    deleteQuestionId,
    setDeleteQuestionId,
    userId,
    handleEditingQuestionAnswer,
  };
}
