import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import VideoLinkComponent from "components/common/File/videoLinkButton";
import UploadFile from "components/common/File/uploadFileButton";
import { TabPanel } from "components/common/File/tabPanel";
import { workticketFiles } from "services/workticketService";
import { useUploadFileState } from "contexts/uploadFileContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";

export const WorkticketUpload = ({ workTicketId, workTicketNumber }) => {
  const classes = useStyles();
  const [stateContext, dispatchContext] = useWorkticketView();
  const [isLoadingFiles, setIsLoadingFiles] = useState(true);
  const { workticketFiles: files, isLoading } = stateContext ?? null;
  const { isUploadFile } = useUploadFileState();
  const { uploadFiles } = useUploadFileState();

  const setMedia = useLunaLensStore((state) => state.setMedia);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!isUploadFile) {
          setIsLoadingFiles(true);
          const response = await workticketFiles(workTicketId);
          dispatchContext({
            type: "SET_WORKTICKET_FILES",
            workticketFiles: response.data.data,
          });
          const allLensMedia = response?.data?.data?.all
            .map((item) => {
              return {
                id: item.id,
                url: item.file_url,
                mime_type: item.mime_type,
                frames: item.frames,
                track:
                  item.mime_type === "3" && item.frames === "0" ? true : false,
                done:
                  item.mime_type === "3" && item.frames === "0" ? true : false,
              };
            })
            .filter((item) => {
              const mimeTypes = ["2", "3", "5"];
              return mimeTypes.some((type) => item.mime_type?.includes(type));
            });
          setMedia(allLensMedia);
          setIsLoadingFiles(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (workTicketId && workTicketId !== "new") {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workTicketId, uploadFiles]);

  if (isLoading || isLoadingFiles) {
    return (
      <Skeleton
        animation="wave"
        variant="rect"
        height={80}
        style={{ marginBottom: 20 }}
      />
    );
  }
  return (
    <Box className={classes.containerUpload} style={{ marginBottom: 90 }}>
      <Box className={classes.tabPanelBoxUpload}>
        <Box>
          <TabPanel
            data={isUploadFile ? uploadFiles : files}
            workTicketNumber={workTicketNumber}
            workTicketId={workTicketId}
          />
        </Box>
        {!isUploadFile && (
          <Box className={classes.flexBoxUpload}>
            <VideoLinkComponent
              workTicketId={workTicketId}
              workTicketNumber={workTicketNumber}
            />
            <Box pl={1}>
              <UploadFile workTicketId={workTicketId} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
