import React, { useEffect, useState } from "react";
import useStyles, { Style } from "./styles";
import { CheckGreen } from "../LunaLens/images/images";
import useLunaLensStore from "store/lunaLensAnalysis";

export const LoadingMediaIndicator = ({
  isVisible,
  pending,
  id,
  style,
  propStyle,
}) => {
  const [done, setDone] = useState(false);
  const classes = useStyles();

  const setMediaProcessed = useLunaLensStore(
    (state) => state.setMediaProcessed
  );

  useEffect(() => {
    if (pending) {
      setDone(false);
    } else {
      setTimeout(() => {
        setDone(true);
        setMediaProcessed(id);
      }, 1000);
    }
  }, [pending, id, setMediaProcessed]);

  if (!isVisible || done) {
    return null;
  }

  return (
    <div className={`${classes.loadingContainer}`}>
      {pending ? (
        <>
          <Style />
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        </>
      ) : (
        <CheckGreen />
      )}
    </div>
  );
};

export default LoadingMediaIndicator;
