import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import useLunaLensStore from "store/lunaLensAnalysis";

import useStyles from "./styles";
import {
  DocumentTextIcon,
  ListAnalysisIcon,
  MapPinIcon,
} from "./images/images";
import AnalysisLunaChat from "./analysisLunaChat";
import WorkticketTypeBadge from "./workticketTypeBadge";
import { editAnalysisTitle } from "services/lunaLensService";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";

const AnalysisInformationCard = ({
  analysisTitle,
  location,
  summary,
  description,
  WTType,
  analysisId,
}) => {
  const classes = useStyles();
  const [title, setTitle] = useState(analysisTitle);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const setTitleAnalysisThread = useLunaLensStore(
    (state) => state.setTitleAnalysisThread
  );

  const handleChange = (event) => {
    setTitle(event.target.value);
  };

  const onHandleEdit = () => {
    setEdit(true);
  };

  const onHandleDone = async () => {
    const trimmedTitle = title.trim().replace(/\n/g, "");
    if (trimmedTitle.length === 0) return;
    setTitleAnalysisThread(title.substring(0, 200));
    setTitle(title.substring(0, 200));
    setEdit(false);
    setLoading(true);
    await editAnalysisTitle(analysisId, { title: title.substring(0, 200) });
    setLoading(false);
  };

  return (
    <Box className={classes.cardWTContainer}>
      <Box className={classes.chatLensIconContainer}>
        <AnalysisLunaChat />
      </Box>
      <Box className={classes.cardWTContainerHeader}>
        <Box
          style={{ display: "flex", flexDirection: "column", gap: 4, flex: 1 }}
        >
          {!edit ? (
            <>
              <Typography className={classes.cardWTTitle}>
                {analysisTitle}
              </Typography>
              <Box>
                {!loading ? (
                  <Button
                    className={classes.buttonEditTitle}
                    onClick={onHandleEdit}
                    startIcon={<EditIcon style={{ fontSize: "16" }} />}
                  >
                    Edit
                  </Button>
                ) : (
                  <Box className={classes.loadingContainerEdit}>
                    <LoadingStateHorizontal isVisible />
                  </Box>
                )}
              </Box>
            </>
          ) : (
            <>
              <TextField
                className={classes.addTitleField}
                value={title}
                multiline={true}
                onChange={(event) => {
                  handleChange(event);
                }}
                variant="standard"
                placeholder="Add a title"
                InputProps={{
                  disableUnderline: true,
                  style: { backgroundColor: "white" },
                  readOnly: loading,
                }}
              />
              <Box style={{ marginTop: 4 }}>
                {!loading && (
                  <Button
                    className={classes.buttonEditTitle}
                    onClick={onHandleDone}
                    startIcon={<DoneIcon style={{ fontSize: "16" }} />}
                  >
                    Done
                  </Button>
                )}
              </Box>
            </>
          )}
        </Box>
        <WorkticketTypeBadge WTType={parseInt(WTType)} />
      </Box>
      <Box className={classes.cardWTDescription}>
        <Box className={classes.cardWTContainerTitle}>
          <MapPinIcon />
          <Typography component="span" className={classes.cardWTTitle}>
            Location:
          </Typography>
        </Box>
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {location}
        </Typography>
      </Box>
      <Box className={classes.cardWTDescription}>
        <Box className={classes.cardWTContainerTitle}>
          <ListAnalysisIcon />
          <Typography component="span" className={classes.cardWTTitle}>
            WT Summary:
          </Typography>
        </Box>
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {summary}
        </Typography>
      </Box>
      <Box className={classes.cardWTDescription}>
        <Box className={classes.cardWTContainerTitle}>
          <DocumentTextIcon />
          <Typography component="span" className={classes.cardWTTitle}>
            WT Description:
          </Typography>
        </Box>
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default AnalysisInformationCard;
