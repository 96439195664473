import React from "react";
import BadgeComponent from "components/common/Badge/index";
import { getServiceTypeDetails } from "components/util/getServiceTypeUtil";

const WorkticketTypeBadge = ({ WTType }) => {
  const serviceTypeDetails = getServiceTypeDetails(WTType);
  return (
    <BadgeComponent
      icon={serviceTypeDetails?.icon}
      label={serviceTypeDetails?.label}
      height={25}
      minWidth={120}
      marginRight={5}
      paddingLeft={10}
      border="1px solid #BAE7FA"
      borderRadius={4}
      fontSize={14}
      fontWeight="normal"
      color="#17457A"
      backgroundColor="#EDF5F8"
    />
  );
};

export default WorkticketTypeBadge;
