import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { List } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";
import ResponseMediaGallery from "./responseMediaGallery";
import { TrashIcon } from "./images/images";

const AnalysisList = ({
  analysis,
  background,
  edit,
  thread,
  category,
  workticketId,
  workticketNumber,
  loadingUpdateAnalysis,
  action,
}) => {
  const classes = useStyles();
  const updateDescriptionEditThread = useLunaLensStore(
    (state) => state.updateDescriptionEditThread
  );

  const addAnalysisTrade = useLunaLensStore((state) => state.addAnalysisTrade);
  const deleteAnalysisTrade = useLunaLensStore(
    (state) => state.deleteAnalysisTrade
  );

  const onHandleAddTrade = () => {
    addAnalysisTrade(thread, category, null, "");
  };

  return (
    <Box
      className={classes.listContainer}
      style={{ backgroundColor: background ? "#F8F8F8" : "white" }}
    >
      <List>
        {analysis.map((a, index) =>
          !edit ? (
            <React.Fragment key={index}>
              <Box key={index} style={{ display: "flex" }}>
                <span className={classes.bulletPoint}>•</span>
                <Box>
                  {a.title && (
                    <Typography component="span" className={classes.listSpan}>
                      {`${a.title}: `}
                    </Typography>
                  )}
                  <Typography component="span" className={classes.description}>
                    {a?.description}
                  </Typography>
                </Box>
              </Box>
              <Box style={{ margin: "24px" }}>
                <ResponseMediaGallery
                  media={a.luna_lens_trade_media}
                  category={category.luna_lens_category?.value}
                  title={a.title}
                  workticketId={workticketId}
                  workticketNumber={workticketNumber}
                />
              </Box>
            </React.Fragment>
          ) : (
            <Box key={index}>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {a.title ? (
                  <Typography
                    className={
                      action === "deleteCategory"
                        ? classes.listSpanLineThrough
                        : classes.listSpan
                    }
                  >{`${a.title}: `}</Typography>
                ) : (
                  <Box component="span" />
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <TextField
                  className={classes.textAreaCard}
                  value={a.description}
                  onChange={(e) =>
                    updateDescriptionEditThread(
                      thread,
                      category,
                      index,
                      e.target.value
                    )
                  }
                  placeholder="Write here..."
                  multiline
                  minRows={4}
                  maxRows={4}
                  InputProps={{
                    disableUnderline: true,
                    readOnly:
                      loadingUpdateAnalysis ||
                      a.deleted ||
                      action === "deleteCategory",
                    classes: {
                      input:
                        a.deleted || action === "deleteCategory"
                          ? classes.inputAreaLineThrough
                          : classes.inputArea,
                    },
                  }}
                />
                {edit && action !== "deleteCategory" && (
                  <Button
                    className={
                      a.deleted || loadingUpdateAnalysis
                        ? classes.buttonDeleteBulletDisabled
                        : classes.buttonDeleteBullet
                    }
                    onClick={() => {
                      deleteAnalysisTrade(thread, category, index);
                    }}
                    disabled={a.deleted || loadingUpdateAnalysis}
                  >
                    <TrashIcon
                      width={26}
                      height={26}
                      background="#F8F8F8"
                      color={
                        a.deleted || loadingUpdateAnalysis
                          ? "#F8F8F8"
                          : "#4F98BC"
                      }
                    />
                  </Button>
                )}
              </Box>
            </Box>
          )
        )}
      </List>
      {edit && !loadingUpdateAnalysis && action !== "deleteCategory" && (
        <Button
          className={classes.buttonHeader}
          style={{ marginBottom: "10px" }}
          onClick={() => {
            onHandleAddTrade();
          }}
        >
          <AddIcon style={{ color: "#4F98BC", fontSize: "18px" }} />
        </Button>
      )}
    </Box>
  );
};

export default AnalysisList;
