import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";

const AnalysisStatusBadge = ({ status }) => {
  const classes = useStyles();

  const statusBackgroundColor = {
    0: "#FFEAD5",
    1: "#E8F8CB",
    2: "#FFDFDF",
  };

  const statusColor = {
    0: "#DB6F00",
    1: "#328914",
    2: "#9A1010",
  };

  const statusText = {
    0: "Pending",
    1: "Completed",
    2: "Failed",
  };

  return (
    <Box
      className={classes.statusBadgeContainer}
      style={{ backgroundColor: statusBackgroundColor[status] }}
    >
      <Typography style={{ color: statusColor[status] }}>
        {statusText[status]}
      </Typography>
    </Box>
  );
};

export default AnalysisStatusBadge;
