import React, { useState, useEffect, useContext } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import Input from "@material-ui/core/Input";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Skeleton from "@material-ui/lab/Skeleton";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TimelineIcon from "@material-ui/icons/Timeline";
import CommentsIcon from "assets/icons/commentsIcon";
import MessageDialog from "./dialog/successDialog";
import ConfirmDialog from "components/ui/dialog/confirmDialog";
import WorkticketComment from "components/ui/Worktickets/WorkticketComment";
import { assign } from "lodash";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionWorkticket } from "lib/permissions";
import {
  getWorkticketComments,
  addWorkticketComment,
  updateWorkticketComment,
  deleteWorkticketComment,
} from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";
import { convertDateFormatField } from "components/util/timeFormat";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const INTERNAL = "internal";
const PARTNER = "partner";
const CUSTOMER = "customer";

const WorkticketActivity = (props) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { role, permissions } = globalUi;
  const [stateContext, dispatchContext] = useWorkticketView();
  const [tab, setTab] = useState(0);
  const [subTab, setSubTab] = useState(null);
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { workticket, isLoading } = stateContext ?? null;
  const { workticketComments } = stateContext;

  const setWorkTicketComments = (comments) => {
    dispatchContext({
      type: "SET_WORKTICKET_COMMENTS",
      workticketComments: comments,
    });
    setCommentList(assign(comments, { isEditable: false }));
  };

  useEffect(() => {
    if (!isLoading) {
      setActivityList(workticket.activity);
    }
    if (workticketComments && !isLoading) {
      if (subTab === null) {
        const firstKey = Object.keys(workticketComments)[0];
        if (firstKey) {
          setSubTab(firstKey);
        }
      }

      setCommentList(workticketComments || []);
    } else {
      setCommentList([]);
    }
  }, [
    isLoading,
    workticket.id,
    workticketComments,
    workticket.activity,
    subTab,
  ]);

  const handleSubTabChange = (event, newValue) => {
    setSubTab(newValue);
  };

  const handleTabButtonChange = (newValue) => {
    setTab(newValue);
  };

  const handleDeleteConfirmation = (commentId) => {
    setSelectedComment(commentId);
    setOpenConfirm(true);
  };

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  const closeMessage = () => {
    if (!isLoadingData) {
      setOpenMessage(false);
    }
  };

  const handleAddComment = async () => {
    if (comment === "") return;

    const data = { body: comment, tab: subTab };

    try {
      setIsLoadingData(true);
      setOpenMessage(true);

      await addWorkticketComment(workticket.id, data);
      const refreshData = await getWorkticketComments(workticket.id);
      setWorkTicketComments(refreshData.data.data);

      setComment("");
      setOpenMessageContent("Comment has been added successfully.");
    } catch (e) {
      logException(e, "Cannot add comment");
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleUpdateComment = async (commentId, newBody) => {
    const data = { body: newBody, tab: subTab };

    try {
      setIsLoadingData(true);
      setOpenMessage(true);

      await updateWorkticketComment(workticket.id, commentId, data);

      setCommentList((prevCommentList) => {
        const updatedCommentList = { ...prevCommentList };
        updatedCommentList[subTab] = prevCommentList[subTab].map((c) =>
          c.id === commentId ? { ...c, body: newBody } : c
        );
        return updatedCommentList;
      });

      setOpenMessageContent("Comment has been updated successfully.");
    } catch (e) {
      logException(e, "Cannot update comment");
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await deleteWorkticketComment(workticket.id, commentId);

      setCommentList((prevCommentList) => {
        const updatedCommentList = { ...prevCommentList };
        updatedCommentList[subTab] = prevCommentList[subTab].filter(
          (c) => c.id !== commentId
        );
        return updatedCommentList;
      });

      setOpenConfirm(false);
    } catch (e) {
      logException(e, "Cannot delete comment");
    }
  };

  const ActivityItem = (props) => (
    <ListItem className={classes.listContainer}>
      <ListItemIcon>
        <TimelineIcon />
      </ListItemIcon>
      <ListItemText
        className={classes.listText}
        secondary={props.activity.activity_message}
      />
      <ListItemSecondaryAction className={classes.listAction}>
        {convertDateFormatField(
          props.activity.created_at,
          workticket?.job?.timezone
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );

  const renderComments = () => {
    const comments = commentList[subTab] || [];
    return comments.map((comment) => (
      <WorkticketComment
        key={comment.id}
        id={comment.id}
        externalId={comment.external_id}
        user={{
          id: comment.user_id,
          name: comment.user_name,
          avatar: comment.profile_url,
        }}
        time={comment.video_metadata}
        date={comment.created_at}
        message={comment.body}
        fileId={comment.files_id}
        fileUrl={comment.file_url}
        fileName={comment.display_name}
        fileOwner={comment.user_name}
        fileType={comment.mime_type}
        fileSize={comment.file_size}
        fileCreateAt={comment.files_created_at}
        workticketId={workticket.id}
        workticketNumber={workticket.number}
        displayFileDetails={true}
        onUpdateComment={handleUpdateComment}
        onDeleteComment={() => handleDeleteConfirmation(comment.id)}
      />
    ));
  };

  if (isLoading || workticketComments === null) {
    return <Skeleton animation="wave" variant="rect" height={100} />;
  }

  return (
    <Box className={classes.containerBodyActivity}>
      <Box className={classes.containerTabs}>
        {[
          {
            label: "Comments",
            count: [INTERNAL, PARTNER, CUSTOMER].reduce(
              (total, type) => total + (commentList[type]?.length || 0),
              0
            ),
            index: 0,
          },
          { label: "Activity", count: activityList.length, index: 1 },
        ].map((tabInfo) => (
          <Box
            key={tabInfo.label}
            className={classNames(classes.buttonTab, {
              [classes.buttonTabActive]: tab === tabInfo.index,
            })}
            onClick={() => handleTabButtonChange(tabInfo.index)}
          >
            {tabInfo.label === "Comments" && (
              <CommentsIcon
                strokeColor={tab === tabInfo.index ? "#FFFFFF" : "#4F98BC"}
              />
            )}
            {tabInfo.label === "Activity" && <TimelineIcon fontSize="small" />}
            <Typography variant="body2" style={{ paddingLeft: 2 }}>
              {`${tabInfo.label} (${tabInfo.count})`}
            </Typography>
          </Box>
        ))}
      </Box>

      {tab === 0 && (
        <Box mt={3}>
          {role?.category !== "subcontractor" && (
            <Box className={classes.containerSubTabs}>
              <Tabs
                value={subTab}
                onChange={handleSubTabChange}
                className={classes.activityTabRoot}
              >
                {hasPermission(
                  permissionWorkticket.COMMENTS_INTERNAL,
                  permissions
                ) && (
                  <Tab
                    label={`Internal`}
                    value={INTERNAL}
                    className={classes.activityTab}
                  />
                )}
                {hasPermission(
                  permissionWorkticket.COMMENTS_PARTNER,
                  permissions
                ) && (
                  <Tab
                    label={`Partners`}
                    value={PARTNER}
                    className={classes.activityTab}
                  />
                )}
                {hasPermission(
                  permissionWorkticket.COMMENTS_CUSTOMER,
                  permissions
                ) && (
                  <Tab
                    label={`Customers`}
                    value={CUSTOMER}
                    className={classes.activityTab}
                  />
                )}
              </Tabs>
            </Box>
          )}

          <Box className={classes.containerComments}>
            {renderComments()}

            <Box className={classes.containerCommentsForm}>
              <Input
                className={classes.inputNewComment}
                disableUnderline={true}
                rows={6}
                multiline={true}
                fullWidth={true}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                placeholder="Add a comment"
              />
              <IconButton
                size="small"
                style={{ color: comment.trim() !== "" ? "#4F98BC" : "#D9D9D9" }}
                onClick={handleAddComment}
              >
                <SendIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}

      {tab === 1 && (
        <Box className={classes.containerActivity}>
          <List className={classes.containerActivityList}>
            {activityList.map((activity) => (
              <ActivityItem key={activity.id} activity={activity} />
            ))}
          </List>
        </Box>
      )}

      <ConfirmDialog
        open={openConfirm}
        handleConfirm={() => handleDeleteComment(selectedComment)}
        handleClose={closeConfirm}
        message={"Are you sure you want to delete this comment?"}
        title={`Workticket ${workticket.number}`}
      />
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
      />
    </Box>
  );
};

export default WorkticketActivity;
