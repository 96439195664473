import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import VerticalTabs from "./Tabs";
import Grid from "@material-ui/core/Grid";
import { PartnerHeader } from "./Profile/Header";
import { RatingMain } from "./Ratings/RatingMain";
import { AllRatingsModalProvider } from "./context/AllRatingsDialogueContext";
import { RateActionProvider } from "./context/PartnerRateContext";
import { PartnerBreadcrumb } from "./Profile/Breadcrumb";
import { usePartnerState } from "contexts/partnerContext";

const PartnerLayout = (props) => {
  const { partnerDetails } = usePartnerState();
  const classes = useStyles();

  const { id, mode } = props;
  if (!partnerDetails) {
    return <PartnerHeader mode={mode} />;
  }

  return (
    <>
      <PartnerBreadcrumb />
      <Box className={classes.pageWrapper}>
        <Grid className={classes.removeMarginGrid} container spacing={3}>
          <Grid sm={12} md={12} item lg={8}>
            <Box className={classes.pageBodyWrapper}>
              <PartnerHeader id={id} mode={mode} />
              {mode !== "edit" ? (
                <Box className={classes.verticalTabsProgressContainer}>
                  <VerticalTabs id={id} />
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid sm={12} md={12} lg={4} item={4}>
            <AllRatingsModalProvider>
              <RateActionProvider>
                <RatingMain />
              </RateActionProvider>
            </AllRatingsModalProvider>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default PartnerLayout;
