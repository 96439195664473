export const zeroStateHistory = (width = 108, height = 116) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 108 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Illustration" clipPath="url(#clip0_243_7843)">
      <g id="Group">
        <g id="Group_2">
          <path
            id="Vector"
            d="M20.1683 13.1774L82.5824 14.2189C86.0257 14.2763 88.82 17.0203 88.9375 20.4596L92.0338 110.966C92.1256 113.657 89.9698 115.89 87.2746 115.894L15.7184 116C13.1863 116.003 11.1432 113.93 11.1857 111.4L12.7066 20.3889C12.7744 16.3381 16.1152 13.1093 20.1687 13.177L20.1683 13.1774Z"
            fill="#D6ECF0"
          />
          <path
            id="Vector_2"
            d="M13.8576 93.7341H13.855C13.7756 93.7328 13.7121 93.6672 13.7134 93.5879L13.746 91.6319C13.7473 91.5522 13.8177 91.4853 13.8923 91.4905C13.9721 91.4917 14.0352 91.5573 14.0339 91.6367L14.0013 93.5926C14 93.6715 13.9356 93.7341 13.8571 93.7341H13.8576Z"
            fill="white"
          />
          <g id="Group_3">
            <path
              id="Vector_3"
              d="M20.3726 12.4473C28.8768 13.7447 58.1262 17.8062 82.3894 16.3514C83.4959 16.285 84.441 17.1378 84.4814 18.2448L87.7943 109.158C87.8398 110.404 86.8702 111.454 85.623 111.506C75.4212 111.939 33.4095 113.569 18.4647 111.752C17.5552 111.641 16.8765 110.863 16.8863 109.947L17.919 14.5314C17.9332 13.2353 19.0903 12.2514 20.3726 12.4469V12.4473Z"
              fill="#EDF5F8"
            />
            <path
              id="Vector_4"
              d="M20.8463 14.8187C29.03 16.0672 57.1781 19.9756 80.5279 18.5758C81.5928 18.5119 82.5023 19.3325 82.5409 20.3979L85.729 107.887C85.7728 109.087 84.8396 110.097 83.6397 110.148C73.8222 110.564 33.3924 112.132 19.0105 110.384C18.1353 110.278 17.4819 109.529 17.4913 108.648L18.4849 16.8248C18.4982 15.5776 19.612 14.6305 20.8459 14.8187H20.8463Z"
              fill="white"
            />
          </g>
          <path
            id="Vector_5"
            d="M55.4522 1.43287C53.2238 -0.171916 51.2632 -0.173631 49.8761 0.210095C48.6109 0.560378 47.7752 1.76129 47.873 3.06939L48.3595 9.57085L55.4307 10.3169C55.4307 10.3169 59.8223 4.58028 55.4522 1.43287ZM52.2504 6.6181C51.2752 6.6018 50.4978 5.79877 50.5141 4.82423C50.5304 3.8497 51.334 3.07282 52.3096 3.08911C53.2848 3.1054 54.0621 3.90844 54.0458 4.8834C54.0295 5.85793 53.2255 6.63482 52.2504 6.61852V6.6181Z"
            fill="#4F98BC"
          />
          <path
            id="Vector_6"
            d="M38.6245 13.6049L38.679 10.3413C38.6979 9.21458 39.6473 8.32794 40.7735 8.38539L63.2773 9.53185C64.2761 9.58287 65.0719 10.3846 65.1153 11.3827L65.2513 14.522L38.6249 13.6049H38.6245Z"
            fill="#4F98BC"
          />
          <path
            id="Vector_7"
            d="M31.5893 12.2925L70.9638 12.9485C72.3796 12.9721 73.5731 14.0105 73.7911 15.4086L73.8211 15.6016C74.0601 17.1331 72.8605 18.5102 71.3096 18.484L30.9136 17.81C29.9856 17.7946 29.2588 17.007 29.3189 16.0813L29.436 14.2759C29.5094 13.1466 30.4567 12.2737 31.5889 12.2925H31.5893Z"
            fill="#4F98BC"
          />
        </g>
        <g id="Group_4">
          <path
            id="Vector_8"
            d="M43.3469 52.0936C44.4615 49.1709 46.8919 46.9269 49.929 46.2353L48.6514 32.9044C48.6406 32.7903 48.5377 32.7071 48.424 32.7213C39.7589 33.7842 32.707 40.0352 30.0281 48.4266C29.9891 48.5488 30.062 48.6787 30.186 48.7104L43.3469 52.0941V52.0936Z"
            fill="#EDF5F8"
          />
          <path
            id="Vector_9"
            d="M61.2033 56.5547C61.0249 56.5697 60.8781 56.7009 60.8438 56.8767C60.7481 57.3693 60.5778 58.3126 60.5461 58.4331C60.392 59.0187 60.199 59.5949 59.96 60.1519C59.5168 61.1843 58.9106 62.1533 58.1186 62.955C56.1116 64.9873 52.9763 65.6732 50.2314 65.085C50.1533 65.0683 50.0752 65.0494 49.998 65.0301C49.8543 64.9941 49.7063 65.0683 49.6496 65.2055L44.3374 77.1121C47.7589 78.3739 50.8552 79.4561 54.6529 78.7838C65.6867 76.8309 73.4049 66.839 73.123 55.548L61.2033 56.5552V56.5547Z"
            fill="#EDF5F8"
          />
          <path
            id="Vector_10"
            d="M61.203 56.5547C60.9761 61.0728 58.0819 64.9066 53.6397 65.5558C52.3899 65.7384 50.8605 65.6145 49.7102 65.2917C49.5901 65.2582 49.464 65.3195 49.4138 65.4336L43.999 77.7064C47.4205 78.9681 51.0132 79.438 54.8406 78.9651C67.7067 77.3754 76.3846 65.1605 75.6308 54.7626L61.203 56.5547Z"
            fill="#EDF5F8"
          />
          <path
            id="Vector_11"
            d="M42.2373 76.2881L47.5465 63.6445C47.5842 63.5549 47.5525 63.452 47.4714 63.3979C45.2349 61.9059 43.3424 59.7815 43.0078 56.7906C42.9254 56.0527 42.4779 55.0156 42.5504 54.3069L29.5638 51.2392C29.1227 53.5698 29.0163 56.0103 29.2943 58.4953C30.1927 66.5286 35.254 73.1171 41.5731 76.5235C41.8202 76.6568 42.1283 76.5471 42.2368 76.2885L42.2373 76.2881Z"
            fill="#78C1CE"
          />
          <path
            id="Vector_12"
            d="M41.5353 76.024L47.1019 63.5008C47.1727 63.3417 47.1212 63.1548 46.9783 63.0549C44.8105 61.5402 43.263 59.0727 42.9339 56.1298C42.8515 55.392 42.8507 54.664 42.9232 53.9548L29.6847 50.6269C29.5791 50.6003 29.4727 50.6685 29.4534 50.7753C29.0433 53.0463 28.9506 55.419 29.2204 57.8337C30.1407 66.0625 35.0016 72.6939 41.5348 76.0235L41.5353 76.024Z"
            fill="#EDF5F8"
          />
          <path
            id="Vector_13"
            d="M52.3706 46.0265C56.3828 46.2452 59.8082 49.2185 60.7885 53.3023C60.8409 53.5205 61.0429 53.6697 61.2669 53.6521L80.3891 52.1627C80.4418 52.0988 80.4697 52.0649 80.5225 52.0011C78.6833 36.0809 65.7301 23.7126 50.2207 23.6161L52.3706 46.0265Z"
            fill="#D6ECF0"
          />
        </g>
        <path
          id="Vector_14"
          d="M25.5099 102.838H78.373C78.5111 102.838 78.6231 102.95 78.6231 103.088V105.431C78.6231 105.569 78.5111 105.681 78.373 105.681H25.5099C25.3717 105.681 25.2598 105.569 25.2598 105.431V103.088C25.2598 102.95 25.3717 102.838 25.5099 102.838Z"
          fill="#EDF5F8"
        />
        <path
          id="Vector_15"
          d="M25.5099 97.3077H78.373C78.5111 97.3077 78.6231 97.4196 78.6231 97.5576V99.9007C78.6231 100.039 78.5111 100.151 78.373 100.151H25.5099C25.3717 100.151 25.2598 100.039 25.2598 99.9007V97.5576C25.2598 97.4196 25.3717 97.3077 25.5099 97.3077Z"
          fill="#EDF5F8"
        />
        <path
          id="Vector_16"
          d="M78.3734 91.8412H25.5099C25.3718 91.8412 25.2598 91.9531 25.2598 92.0911V94.4347C25.2598 94.5727 25.3718 94.6846 25.5099 94.6846H78.3734C78.5115 94.6846 78.6235 94.5727 78.6235 94.4347V92.0911C78.6235 91.9531 78.5115 91.8412 78.3734 91.8412Z"
          fill="#EDF5F8"
        />
      </g>
      <g id="Group_5">
        <path
          id="Vector_17"
          d="M31.4702 76.8677L1.03328 101.933C-0.243505 102.984 -0.353336 104.899 0.794742 106.09L0.983085 106.285C1.99859 107.338 3.67824 107.363 4.72506 106.342L33.0945 78.6598L31.4698 76.8681L31.4702 76.8677Z"
          fill="#4F98BC"
        />
        <path
          id="Vector_18"
          opacity="0.21"
          d="M50.8146 80.4699C60.3399 77.1009 65.3781 66.7925 62.0678 57.4454C58.7574 48.0982 48.352 43.252 38.8267 46.621C29.3014 49.99 24.2632 60.2984 27.5735 69.6456C30.8839 78.9927 41.2892 83.8389 50.8146 80.4699Z"
          fill="white"
        />
        <path
          id="Vector_19"
          d="M38.1518 44.7115C27.5526 48.4604 21.947 59.931 25.6306 70.3319C29.3142 80.7328 40.8928 86.1251 51.4915 82.3762C62.0907 78.6273 67.6963 67.1567 64.0127 56.7558C60.3291 46.3549 48.7505 40.9626 38.1518 44.7115ZM50.5116 79.6099C41.4699 82.8079 31.5919 78.208 28.4497 69.3347C25.3071 60.4618 30.0895 50.6762 39.1317 47.4778C48.1739 44.2798 58.0514 48.8797 61.194 57.753C64.3366 66.6263 59.5542 76.4115 50.5121 79.6099H50.5116Z"
          fill="#4F98BC"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_243_7843">
        <rect width="108" height="116" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const noPhotos = (width = 24, height = 24, color = "#78C1CE") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="No photography" clipPath="url(#clip0_231_7009)">
      <g id="Group">
        <path
          id="Vector"
          d="M10.9399 8.11998L7.47993 4.65998L8.99993 2.99998H14.9999L16.8299 4.99998H19.9999C21.0999 4.99998 21.9999 5.89998 21.9999 6.99998V19C21.9999 19.05 21.9899 19.1 21.9799 19.16L16.8799 14.06C16.9599 13.71 16.9999 13.36 16.9999 13C16.9999 10.24 14.7599 7.99998 11.9999 7.99998C11.6399 7.99998 11.2899 8.03998 10.9399 8.11998ZM20.4899 23.31L18.1699 21H3.99993C2.89993 21 1.99993 20.1 1.99993 19V6.99998C1.99993 6.40998 2.26993 5.87998 2.67993 5.50998L0.679932 3.50998L2.09993 2.09998L21.8999 21.9L20.4899 23.31ZM14.4899 17.32L12.9899 15.82C12.6699 15.92 12.3499 16 11.9999 16C10.3399 16 8.99993 14.66 8.99993 13C8.99993 12.65 9.07993 12.33 9.18993 12.02L7.68993 10.52C7.24993 11.24 6.99993 12.09 6.99993 13C6.99993 15.76 9.23993 18 11.9999 18C12.9099 18 13.7599 17.75 14.4899 17.32Z"
          fill={color}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_231_7009">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const checkBadge = (width = 24, height = 24, color = "#E3B100") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.16907 3.16563C7.85588 2.37578 8.86943 1.875 10 1.875C11.1305 1.875 12.144 2.37573 12.8308 3.16551C13.8751 3.09255 14.946 3.45513 15.7455 4.25464C16.545 5.05415 16.9076 6.12507 16.8347 7.16933C17.6243 7.85613 18.125 8.86957 18.125 10C18.125 11.1306 17.6242 12.1443 16.8342 12.8311C16.907 13.8751 16.5444 14.9458 15.7451 15.7452C14.9457 16.5446 13.875 16.9072 12.8309 16.8344C12.1441 17.6242 11.1306 18.125 10 18.125C8.8695 18.125 7.856 17.6243 7.1692 16.8345C6.12493 16.9075 5.054 16.5449 4.25448 15.7454C3.45496 14.9459 3.09238 13.8749 3.16534 12.8307C2.37565 12.1439 1.875 11.1304 1.875 10C1.875 8.86951 2.37571 7.85602 3.16546 7.16921C3.09258 6.12504 3.45517 5.05423 4.25462 4.25478C5.05406 3.45533 6.12489 3.09274 7.16907 3.16563ZM13.0086 8.48827C13.2092 8.20739 13.1442 7.81705 12.8633 7.61642C12.5824 7.41579 12.192 7.48084 11.9914 7.76173L9.29525 11.5364L7.94194 10.1831C7.69786 9.93898 7.30214 9.93898 7.05806 10.1831C6.81398 10.4271 6.81398 10.8229 7.05806 11.0669L8.93306 12.9419C9.06297 13.0719 9.24346 13.138 9.42655 13.1229C9.60964 13.1077 9.7768 13.0128 9.88358 12.8633L13.0086 8.48827Z"
      fill={color}
    />
    <rect
      x="5.83337"
      y="5.83337"
      width="8.33333"
      height="8.33333"
      fill={color}
    />
    <path
      d="M10 7.5V10.625M10 13.125V13.1313H10.0063"
      stroke="#FFF5D2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const clipboardDocumentCheck = (
  width = 22,
  height = 22,
  color = "#17457A"
) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.31418 5.26785H8.69585L8.69796 5.26786H11.7492C13.4299 5.27025 14.7917 6.61847 14.7917 8.28125V16.6518C16.2874 16.6518 17.5 15.4525 17.5 13.9732V5.36448C17.5 4.02037 16.4846 2.85411 15.0949 2.74004C14.8929 2.72345 14.6905 2.70822 14.4877 2.69435C14.0364 1.83614 13.1291 1.25 12.0834 1.25H10.7292C9.68351 1.25 8.77622 1.83613 8.3249 2.69433C8.12205 2.70821 7.91958 2.72345 7.71748 2.74004C6.36125 2.85136 5.36147 3.96491 5.31418 5.26785ZM10.7292 2.58929C9.98133 2.58929 9.37505 3.1889 9.37505 3.92857H13.4376C13.4376 3.1889 12.8313 2.58929 12.0834 2.58929H10.7292Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 8.28125C1.25 7.35667 2.00786 6.60714 2.94272 6.60714H11.7449C12.6797 6.60714 13.4376 7.35667 13.4376 8.28125V18.3259C13.4376 19.2505 12.6797 20 11.7449 20H2.94272C2.00786 20 1.25 19.2505 1.25 18.3259V8.28125ZM9.90377 12.3826C10.1374 12.0938 10.09 11.6724 9.79802 11.4414C9.50602 11.2103 9.07993 11.2572 8.84633 11.546L6.61056 14.31L5.7913 13.4997C5.52688 13.2382 5.09817 13.2382 4.83375 13.4997C4.56933 13.7612 4.56933 14.1852 4.83375 14.4467L6.18793 15.786C6.32411 15.9207 6.51184 15.9917 6.70414 15.9811C6.89644 15.9706 7.0751 15.8796 7.19542 15.7308L9.90377 12.3826Z"
      fill={color}
    />
    <rect x="3.75" y="8.75" width="7.5" height="8.75" fill={color} />
    <path
      d="M7.5 11.25V13.331M7.5 14.9958V15V15"
      stroke="#EDF5F8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const documentPlus = (width = 22, height = 22, color = "#9A1010") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6875 1.25H7.5C9.22589 1.25 10.625 2.64911 10.625 4.375V5.9375C10.625 6.80044 11.3246 7.5 12.1875 7.5H13.75C15.4759 7.5 16.875 8.89911 16.875 10.625V17.1875C16.875 18.0504 16.1754 18.75 15.3125 18.75H4.6875C3.82455 18.75 3.125 18.0504 3.125 17.1875V2.8125C3.125 1.94955 3.82456 1.25 4.6875 1.25ZM10.625 10C10.625 9.65482 10.3452 9.375 10 9.375C9.65482 9.375 9.375 9.65482 9.375 10V11.875H7.5C7.15482 11.875 6.875 12.1548 6.875 12.5C6.875 12.8452 7.15482 13.125 7.5 13.125H9.375V15C9.375 15.3452 9.65482 15.625 10 15.625C10.3452 15.625 10.625 15.3452 10.625 15V13.125H12.5C12.8452 13.125 13.125 12.8452 13.125 12.5C13.125 12.1548 12.8452 11.875 12.5 11.875H10.625V10Z"
      fill={color}
    />
    <path
      d="M11.875 4.375C11.875 3.28079 11.4733 2.2804 10.8093 1.51325C13.6418 2.25315 15.8719 4.48323 16.6117 7.31567C15.8446 6.6517 14.8442 6.25 13.75 6.25H12.1875C12.0149 6.25 11.875 6.11009 11.875 5.9375V4.375Z"
      fill={color}
    />
  </svg>
);

export const exclamationTriangle = (
  width = 22,
  height = 22,
  color = "#DB6F00"
) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.8343 2.50289C8.79637 0.8353 11.2032 0.835299 12.1652 2.50289L18.2938 13.1258C19.2554 14.7925 18.0525 16.8751 16.1284 16.8751H3.87116C1.94701 16.8751 0.744157 14.7925 1.70569 13.1258L7.8343 2.50289ZM9.99994 6.87506C10.3451 6.87506 10.6249 7.15488 10.6249 7.50006V10.6251C10.6249 10.9702 10.3451 11.2501 9.99994 11.2501C9.65476 11.2501 9.37494 10.9702 9.37494 10.6251V7.50006C9.37494 7.15488 9.65476 6.87506 9.99994 6.87506ZM9.99994 13.7501C10.3451 13.7501 10.6249 13.4702 10.6249 13.1251C10.6249 12.7799 10.3451 12.5001 9.99994 12.5001C9.65476 12.5001 9.37494 12.7799 9.37494 13.1251C9.37494 13.4702 9.65476 13.7501 9.99994 13.7501Z"
      fill={color}
    />
  </svg>
);

export const flag = (width = 22, height = 22, color = "#844CB4") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 1.875C2.84518 1.875 3.125 2.15482 3.125 2.5V2.94951L4.65682 2.56656C6.53844 2.09615 8.52627 2.31436 10.261 3.18175L10.3508 3.22663C11.7893 3.94589 13.4345 4.13805 15 3.76969L17.5914 3.15996C17.7888 3.11351 17.9965 3.16574 18.1484 3.30004C18.3004 3.43434 18.3777 3.63403 18.3559 3.83565C18.2033 5.24428 18.125 6.67538 18.125 8.125C18.125 9.5867 18.2046 11.0296 18.3597 12.4496C18.394 12.7634 18.1889 13.0535 17.8816 13.1258L15.2863 13.7365C13.4361 14.1718 11.4919 13.9447 9.79179 13.0947L9.70202 13.0498C8.23414 12.3158 6.55213 12.1312 4.95999 12.5292L3.125 12.988V17.5C3.125 17.8452 2.84518 18.125 2.5 18.125C2.15482 18.125 1.875 17.8452 1.875 17.5V2.5C1.875 2.15482 2.15482 1.875 2.5 1.875Z"
      fill={color}
    />
  </svg>
);

export const shieldExclamation = (
  width = 22,
  height = 22,
  color = "#EA5168"
) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.57024 1.80805C9.81128 1.57977 10.1887 1.57977 10.4298 1.80805C12.1104 3.39969 14.3781 4.37497 16.875 4.37497C16.9147 4.37497 16.9544 4.37472 16.994 4.37423C17.2668 4.37084 17.5103 4.5448 17.5955 4.80397C17.9393 5.84961 18.125 6.96624 18.125 8.125C18.125 13.0767 14.7383 17.2358 10.1558 18.4152C10.0536 18.4415 9.94641 18.4415 9.84421 18.4152C5.26171 17.2358 1.875 13.0767 1.875 8.125C1.875 6.96624 2.06073 5.84961 2.40451 4.80397C2.48972 4.5448 2.7332 4.37084 3.00601 4.37423C3.04561 4.37472 3.08527 4.37497 3.125 4.37497C5.62187 4.37497 7.8896 3.39969 9.57024 1.80805ZM10 6.87495C10.3452 6.87495 10.625 7.15478 10.625 7.49995V10.625C10.625 10.9701 10.3452 11.25 10 11.25C9.65482 11.25 9.375 10.9701 9.375 10.625V7.49995C9.375 7.15478 9.65482 6.87495 10 6.87495ZM10 12.5C9.65482 12.5 9.375 12.7798 9.375 13.125V13.1312C9.375 13.4764 9.65482 13.7562 10 13.7562H10.0063C10.3514 13.7562 10.6313 13.4764 10.6313 13.1312V13.125C10.6313 12.7798 10.3514 12.5 10.0063 12.5H10Z"
      fill={color}
    />
  </svg>
);

export const image360 = (width = 30, height = 30, color = "#D9D9D9") => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_231_6335)">
      <path
        d="M8.99988 5.625C4.85988 5.625 1.49988 7.305 1.49988 9.375C1.49988 11.055 3.70488 12.4725 6.74988 12.9525V14.4675C6.74988 14.805 7.15488 14.97 7.38738 14.73L9.47988 12.6375C9.62988 12.4875 9.62988 12.255 9.47988 12.105L7.38738 10.0125C7.15488 9.78 6.74988 9.945 6.74988 10.2825V11.4225C4.38738 11.0025 2.99988 9.9975 2.99988 9.375C2.99988 8.58 5.27988 7.125 8.99988 7.125C12.7199 7.125 14.9999 8.58 14.9999 9.375C14.9999 9.87 14.0999 10.635 12.5099 11.13C12.2024 11.2275 11.9999 11.5125 11.9999 11.835C11.9999 12.3375 12.4874 12.705 12.9599 12.555C15.0824 11.895 16.4999 10.7175 16.4999 9.375C16.4999 7.305 13.1399 5.625 8.99988 5.625Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_231_6335">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(0 0.375)"
        />
      </clipPath>
    </defs>
  </svg>
);

export function ThumbUp({ width = 24, height = 24, color = "#4F98BC" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.14536 6.90625C4.64933 6.90625 5.10405 6.6276 5.41506 6.23104C5.89829 5.6149 6.50731 5.10212 7.20346 4.73138C7.65479 4.49102 8.04643 4.13393 8.23634 3.65915C8.36923 3.32693 8.4375 2.97242 8.4375 2.61461V2.21875C8.4375 1.95987 8.64737 1.75 8.90625 1.75C9.6829 1.75 10.3125 2.3796 10.3125 3.15625C10.3125 3.87602 10.1503 4.5579 9.86039 5.16732C9.6944 5.51628 9.92726 5.96875 10.3137 5.96875M10.3137 5.96875H12.2673C12.909 5.96875 13.4833 6.4025 13.5512 7.04054C13.5793 7.30443 13.5938 7.57241 13.5938 7.84375C13.5938 9.62351 12.9738 11.2585 11.9381 12.5445C11.6959 12.8453 11.3214 13 10.9351 13H8.42516C8.12286 13 7.82255 12.9513 7.53577 12.8557L5.58923 12.2068C5.30245 12.1112 5.00214 12.0625 4.69984 12.0625H3.69013M10.3137 5.96875H8.90625M3.69013 12.0625C3.74189 12.1904 3.79821 12.3159 3.85889 12.4389C3.98211 12.6886 3.81012 13 3.53167 13H2.9643C2.40886 13 1.8937 12.6762 1.7316 12.145C1.52005 11.4516 1.40625 10.7157 1.40625 9.95312C1.40625 8.98284 1.5905 8.05558 1.92593 7.20445C2.11659 6.72065 2.60458 6.4375 3.1246 6.4375H3.78257C4.07752 6.4375 4.24831 6.78494 4.09519 7.03703C3.57868 7.88737 3.28125 8.88551 3.28125 9.95312C3.28125 10.6991 3.42645 11.4111 3.69013 12.0625Z"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ThumbDown({ width = 24, height = 24, color = "#4F98BC" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.68631 10.0313L2.73267 10.0313C2.09102 10.0312 1.51669 9.5975 1.44876 8.95946C1.42066 8.69557 1.40625 8.42759 1.40625 8.15625C1.40625 6.37649 2.02618 4.74151 3.06187 3.45549C3.30413 3.15468 3.67863 3 4.06486 3L6.57484 3C6.87714 3 7.17745 3.04873 7.46423 3.14433L9.41077 3.79317C9.69755 3.88877 9.99786 3.9375 10.3002 3.9375L11.1091 3.9375M4.68631 10.0313C5.07274 10.0313 5.3056 10.4837 5.13961 10.8327C4.84973 11.4421 4.6875 12.124 4.6875 12.8438C4.6875 13.6204 5.3171 14.25 6.09375 14.25C6.35263 14.25 6.5625 14.0401 6.5625 13.7813L6.5625 13.3854C6.5625 13.0276 6.63077 12.6731 6.76366 12.3409C6.95357 11.8661 7.3452 11.509 7.79654 11.2686C8.49269 10.8979 9.10171 10.3851 9.58494 9.76896C9.89595 9.3724 10.3507 9.09375 10.8546 9.09375L11.0945 9.09375M4.68631 10.0313L6.0625 10.0313M11.1091 3.9375C11.1157 3.96875 11.1262 3.99976 11.1411 4.02987C11.511 4.77943 11.7188 5.62326 11.7188 6.51563C11.7187 7.44484 11.4934 8.32144 11.0945 9.09375M11.1091 3.9375C11.0609 3.70959 11.2235 3.46875 11.4683 3.46875L12.0357 3.46875C12.5911 3.46875 13.1063 3.79251 13.2684 4.32378C13.4799 5.01711 13.5938 5.75308 13.5938 6.51563C13.5937 7.48591 13.4095 8.41317 13.0741 9.2643C12.8834 9.7481 12.3954 10.0313 11.8754 10.0313L11.2174 10.0313C10.9225 10.0313 10.7517 9.68381 10.9048 9.43172C10.9718 9.32149 11.035 9.20878 11.0945 9.09375"
        stroke={color}
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ThumbUpFill({ width = 20, height = 20, color = "white" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heroicons-solid/hand-thumb-down">
        <g id="Union">
          <path
            d="M5.16859 12.0625L4.52204 12.0625C4.03404 11.3229 3.75 10.4368 3.75 9.48437C3.75 8.53196 4.03405 7.64589 4.52204 6.90625L4.61411 6.90625C5.11808 6.90625 5.5728 6.6276 5.88381 6.23104C6.36704 5.6149 6.97606 5.10212 7.67221 4.73138C8.12355 4.49102 8.51518 4.13392 8.70509 3.65915C8.83798 3.32693 8.90625 2.97241 8.90625 2.61461L8.90625 2.21875C8.90625 1.95987 9.11612 1.75 9.375 1.75C10.1517 1.75 10.7813 2.3796 10.7813 3.15625C10.7813 3.87602 10.619 4.5579 10.3291 5.16732C10.1632 5.51628 10.396 5.96875 10.7824 5.96875L12.7361 5.96875C13.3777 5.96875 13.9521 6.4025 14.02 7.04054C14.0481 7.30443 14.0625 7.57241 14.0625 7.84375C14.0625 9.62351 13.4426 11.2585 12.4069 12.5445C12.1646 12.8453 11.7901 13 11.4039 13L8.8939 13C8.59161 13 8.29129 12.9513 8.00451 12.8557L6.05798 12.2068C5.7712 12.1112 5.47089 12.0625 5.16859 12.0625Z"
            fill={color}
          />
          <path
            d="M1.45718 6.73569C1.12175 7.58683 0.9375 8.51408 0.9375 9.48437C0.9375 10.2469 1.0513 10.9829 1.26285 11.6762C1.42494 12.2075 1.94011 12.5312 2.49555 12.5312L3.06292 12.5312C3.34137 12.5312 3.51335 12.2198 3.39014 11.9701C3.0203 11.2206 2.8125 10.3767 2.8125 9.48437C2.8125 8.41676 3.10993 7.41862 3.62644 6.56828C3.77957 6.31619 3.60877 5.96875 3.31382 5.96875L2.65585 5.96875C2.13583 5.96875 1.64784 6.25189 1.45718 6.73569Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export function ThumbDownFill({ width = 24, height = 24, color = "white" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heroicons-solid/hand-thumb-down">
        <g id="Union">
          <path
            d="M9.83141 3.9375L10.478 3.9375C10.966 4.67714 11.25 5.56321 11.25 6.51563C11.25 7.46804 10.966 8.35411 10.478 9.09375L10.3859 9.09375C9.88192 9.09375 9.4272 9.3724 9.11619 9.76896C8.63296 10.3851 8.02394 10.8979 7.32779 11.2686C6.87645 11.509 6.48482 11.8661 6.29491 12.3409C6.16202 12.6731 6.09375 13.0276 6.09375 13.3854V13.7813C6.09375 14.0401 5.88388 14.25 5.625 14.25C4.84835 14.25 4.21875 13.6204 4.21875 12.8438C4.21875 12.124 4.38097 11.4421 4.67086 10.8327C4.83685 10.4837 4.60399 10.0313 4.21756 10.0313L2.26392 10.0312C1.62227 10.0312 1.04794 9.5975 0.980006 8.95946C0.95191 8.69557 0.9375 8.42759 0.9375 8.15625C0.9375 6.37649 1.55743 4.74151 2.59312 3.45549C2.83538 3.15468 3.20988 3 3.59611 3L6.1061 3C6.40839 3 6.70871 3.04874 6.99549 3.14433L8.94202 3.79317C9.2288 3.88877 9.52911 3.9375 9.83141 3.9375Z"
            fill={color}
          />
          <path
            d="M13.5428 9.26431C13.8782 8.41317 14.0625 7.48592 14.0625 6.51563C14.0625 5.75308 13.9487 5.01711 13.7372 4.32378C13.5751 3.79251 13.0599 3.46875 12.5044 3.46875L11.9371 3.46875C11.6586 3.46875 11.4866 3.78017 11.6099 4.02988C11.9797 4.77943 12.1875 5.62326 12.1875 6.51563C12.1875 7.58324 11.8901 8.58138 11.3736 9.43172C11.2204 9.68381 11.3912 10.0313 11.6862 10.0313L12.3442 10.0313C12.8642 10.0313 13.3522 9.74811 13.5428 9.26431Z"
            fill={color}
          />
        </g>
      </g>
    </svg>
  );
}

export function LayersIcon({
  width = 38,
  height = 38,
  color = "#4F98BC",
  background = "#EDF5F8",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 96">
        <rect
          id="Rectangle 47"
          x="0.652344"
          y="0.636475"
          width="24"
          height="24"
          rx="4"
          fill={background}
        />
        <path
          id="Vector"
          d="M8.78571 11L6 12.5L8.78571 14M8.78571 11L12.5 13L16.2143 11M8.78571 11L6 9.5L12.5 6L19 9.5L16.2143 11M16.2143 11L19 12.5L16.2143 14M16.2143 14L19 15.5L12.5 19L6 15.5L8.78571 14M16.2143 14L12.5 16L8.78571 14"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function Plus({
  width = 26,
  height = 26,
  color = "#4F98BC",
  background = "#EDF5F8",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill={background}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heroicons-mini/plus">
        <path
          id="Union"
          d="M9.6751 4.27498C9.6751 3.90218 9.37289 3.59998 9.0001 3.59998C8.62731 3.59998 8.3251 3.90218 8.3251 4.27498V8.32498H4.2751C3.90231 8.32498 3.6001 8.62718 3.6001 8.99998C3.6001 9.37277 3.90231 9.67497 4.2751 9.67497L8.3251 9.67498V13.725C8.3251 14.0978 8.62731 14.4 9.0001 14.4C9.37289 14.4 9.6751 14.0978 9.6751 13.725V9.67498L13.7251 9.67498C14.0979 9.67498 14.4001 9.37277 14.4001 8.99998C14.4001 8.62718 14.0979 8.32498 13.7251 8.32498H9.6751V4.27498Z"
          fill={color}
        />
      </g>
    </svg>
  );
}

export function TrashIcon({
  width = 38,
  height = 38,
  color = "#4F98BC",
  background = "#EDF5F8",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 96">
        <rect
          id="Rectangle 47"
          x="0.652344"
          y="0.636475"
          width="24"
          height="24"
          rx="4"
          fill={background}
        />
        <path
          id="Vector"
          d="M14.3269 10.5L14.0962 16.5M10.9038 16.5L10.6731 10.5M17.3184 8.36038C17.5464 8.39481 17.7736 8.43165 18 8.47086M17.3184 8.36038L16.6065 17.615C16.5464 18.3965 15.8948 19 15.111 19H9.88905C9.10524 19 8.45358 18.3965 8.39346 17.615L7.68157 8.36038M17.3184 8.36038C16.5542 8.24496 15.781 8.15657 15 8.09622M7 8.47086C7.22638 8.43165 7.45358 8.39481 7.68157 8.36038M7.68157 8.36038C8.44585 8.24496 9.219 8.15657 10 8.09622M15 8.09622V7.48546C15 6.69922 14.3929 6.04282 13.6071 6.01768C13.2395 6.00592 12.8704 6 12.5 6C12.1296 6 11.7605 6.00592 11.3929 6.01768C10.6071 6.04282 10 6.69922 10 7.48546V8.09622M15 8.09622C14.175 8.03247 13.3413 8 12.5 8C11.6587 8 10.825 8.03247 10 8.09622"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function LunaLensFeedback({ width = 33, height = 32 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 188">
        <g id="Group 186">
          <path
            id="Vector 1"
            d="M3.49967 15.6388V23.9444L2.95801 26.111L5.48579 27.9166H12.1663H19.9302L24.8052 25.5694L28.4163 21.7777L29.4997 16.5416L27.6941 9.31938L22.458 4.98604L14.5136 3.90271L8.01356 6.43049L4.40245 10.7638L3.49967 15.6388Z"
            fill="url(#paint0_linear_243_1405)"
          />
          <g id="Mark">
            <path
              id="Vector"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M17.577 22.6343C14.964 23.9943 12.1897 23.9619 10.5445 22.3428C8.5122 20.3028 8.99608 16.4817 11.48 13.3731C11.7381 13.0169 12.0284 12.693 12.351 12.4016C12.1252 12.6283 11.9317 12.9521 11.8349 13.3083C11.6736 14.1826 12.222 15.0245 13.093 15.1864C13.4156 15.2512 13.7059 15.2188 13.9962 15.0893C14.1253 15.0245 14.2543 14.9598 14.3833 14.8626C14.1898 15.0569 13.9962 15.2512 13.8349 15.4779C11.8349 17.7446 11.4155 20.6914 12.9317 22.2133C13.9962 23.3143 15.8027 23.4114 17.577 22.6343Z"
              fill="#64BFE9"
            />
            <path
              id="Vector_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.3517 18.3599C20.7388 18.7485 20.9001 19.2666 20.8033 19.7847C20.7065 20.1409 20.513 20.4647 20.2549 20.7238C20.0291 20.9505 19.771 21.1771 19.513 21.3714C18.9323 21.8572 18.2871 22.2781 17.6097 22.6343C15.8354 23.4115 14.0289 23.3143 12.9321 22.2134C12.0289 21.3067 11.8031 19.9466 12.1902 18.4895C11.9966 19.299 12.0934 19.9466 12.5128 20.3676C13.3838 21.2419 15.4161 20.659 17.3516 19.0723C17.6419 18.8133 17.9323 18.4571 18.2871 18.198C18.4162 18.1009 18.5452 18.0361 18.6742 17.9713C19.2549 17.7771 19.9001 17.9066 20.3517 18.3599Z"
              fill="#EDF5F8"
            />
            <path
              id="Vector_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.3513 17.971C12.4158 17.8415 12.4481 17.6796 12.5126 17.55C12.4481 17.712 12.3836 17.8415 12.3513 17.971Z"
              fill="#78C1CE"
            />
            <path
              id="Vector_4"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M13.8028 15.4777C13.2867 16.0929 12.835 16.8053 12.5125 17.5501C12.835 16.8053 13.2867 16.0929 13.8028 15.4777Z"
              fill="#78C1CE"
            />
            <path
              id="Vector_5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M22.0932 10.7824C24.1256 12.8224 23.6417 16.6435 21.1577 19.7522C20.8997 20.1084 20.6093 20.4322 20.2545 20.7236C20.5126 20.4646 20.7061 20.1407 20.8029 19.7845C20.9642 18.9102 20.4158 18.0683 19.5448 17.9064C19.2222 17.8416 18.8996 17.874 18.6415 18.0035C18.5125 18.0683 18.3835 18.1331 18.2544 18.2302C18.448 18.0359 18.6415 17.8416 18.8351 17.615C20.8351 15.3482 21.2545 12.4015 19.7383 10.8795C18.6415 9.77855 16.835 9.68141 15.0608 10.4586C17.6738 9.13092 20.448 9.13092 22.0932 10.7824Z"
              fill="#64BFE9"
            />
            <path
              id="Vector_6"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.3512 14.8949C20.6415 13.9558 20.577 13.1787 20.1254 12.6929C19.2544 11.8186 17.2221 12.4015 15.2865 13.9882C14.964 14.2473 14.6736 14.6035 14.351 14.8625C14.222 14.9597 14.1252 15.0244 13.9639 15.0892C13.1575 15.413 12.2219 15.0244 11.8994 14.2149C11.8026 13.9558 11.7703 13.632 11.8348 13.3082C11.9316 12.952 12.1252 12.6282 12.3832 12.3691C12.6091 12.1424 12.8671 11.9158 13.1252 11.6891C13.7059 11.2034 14.351 10.7824 15.0285 10.4262C16.8027 9.64905 18.6092 9.74619 19.706 10.8472C20.6738 11.851 20.8351 13.3406 20.3512 14.8949Z"
              fill="#EDF5F8"
            />
          </g>
          <path
            id="Vector_7"
            d="M17.6828 29H5.99367C4.022 29 2.4165 27.3825 2.4165 25.3962V15.9965C2.4165 8.83138 8.20475 3 15.3168 3H17.6758C24.7949 3 30.5832 8.83138 30.5832 15.9965C30.5832 23.1615 24.7949 29 17.6828 29ZM15.3168 5.12824C9.36663 5.12824 4.529 10.0019 4.529 15.9965V25.3891C4.529 26.2049 5.18388 26.8647 5.99367 26.8647H17.6758C23.626 26.8647 28.4636 21.991 28.4636 15.9965C28.4636 10.0019 23.633 5.12824 17.6828 5.12824H15.3168Z"
            fill="#EDF5F8"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_243_1405"
          x1="18.1642"
          y1="26.916"
          x2="17.1417"
          y2="5.88597"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C2A6B" />
          <stop offset="0.1" stopColor="#214082" />
          <stop offset="0.34" stopColor="#0972B5" />
          <stop offset="0.46" stopColor="#0085C9" />
          <stop offset="1" stopColor="#8ED7F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function SuccessIcon({ width = 33, height = 33 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Check circle" clipPath="url(#clip0_207_14008)">
        <path
          id="Vector"
          d="M16.4998 2.66663C9.13984 2.66663 3.1665 8.63996 3.1665 16C3.1665 23.36 9.13984 29.3333 16.4998 29.3333C23.8598 29.3333 29.8332 23.36 29.8332 16C29.8332 8.63996 23.8598 2.66663 16.4998 2.66663ZM13.8332 22.6666L7.1665 16L9.0465 14.12L13.8332 18.8933L23.9532 8.77329L25.8332 10.6666L13.8332 22.6666Z"
          fill="url(#paint0_linear_207_14008)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_207_14008"
          x1="15.0963"
          y1="2.66663"
          x2="28.4297"
          y2="31.4386"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B1CF33" />
          <stop offset="1" stopColor="#1A6930" />
        </linearGradient>
        <clipPath id="clip0_207_14008">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function AnalysisIcon({ width = 29, height = 28 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Vector">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 4C10.9465 4 11.3389 4.29598 11.4615 4.72528L12.5458 8.5202C13.0204 10.1812 14.3188 11.4796 15.9798 11.9542L19.7747 13.0385C20.204 13.1611 20.5 13.5535 20.5 14C20.5 14.4465 20.204 14.8389 19.7747 14.9615L15.9798 16.0458C14.3188 16.5204 13.0204 17.8188 12.5458 19.4798L11.4615 23.2747C11.3389 23.704 10.9465 24 10.5 24C10.0535 24 9.66113 23.704 9.53848 23.2747L8.45421 19.4798C7.97963 17.8188 6.68124 16.5204 5.0202 16.0458L1.22528 14.9615C0.795977 14.8389 0.5 14.4465 0.5 14C0.5 13.5535 0.795977 13.1611 1.22528 13.0385L5.0202 11.9542C6.68124 11.4796 7.97963 10.1812 8.45421 8.5202L9.53848 4.72528C9.66113 4.29598 10.0535 4 10.5 4Z"
          fill="#17457A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.5 0C22.9589 0 23.3589 0.312297 23.4701 0.757464L23.8153 2.13808C24.1288 3.39208 25.1079 4.3712 26.3619 4.6847L27.7425 5.02986C28.1877 5.14115 28.5 5.54113 28.5 6C28.5 6.45887 28.1877 6.85885 27.7425 6.97014L26.3619 7.3153C25.1079 7.6288 24.1288 8.60792 23.8153 9.86192L23.4701 11.2425C23.3589 11.6877 22.9589 12 22.5 12C22.0411 12 21.6411 11.6877 21.5299 11.2425L21.1847 9.86192C20.8712 8.60792 19.8921 7.6288 18.6381 7.3153L17.2575 6.97014C16.8123 6.85885 16.5 6.45887 16.5 6C16.5 5.54113 16.8123 5.14115 17.2575 5.02986L18.6381 4.6847C19.8921 4.3712 20.8712 3.39208 21.1847 2.13808L21.5299 0.757464C21.6411 0.312297 22.0411 0 22.5 0Z"
          fill="#17457A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5 18C20.9304 18 21.3126 18.2754 21.4487 18.6838L21.9743 20.2607C22.1734 20.858 22.642 21.3266 23.2393 21.5257L24.8162 22.0513C25.2246 22.1874 25.5 22.5696 25.5 23C25.5 23.4304 25.2246 23.8126 24.8162 23.9487L23.2393 24.4743C22.642 24.6734 22.1734 25.142 21.9743 25.7393L21.4487 27.3162C21.3126 27.7246 20.9304 28 20.5 28C20.0696 28 19.6874 27.7246 19.5513 27.3162L19.0257 25.7393C18.8266 25.142 18.358 24.6734 17.7607 24.4743L16.1838 23.9487C15.7754 23.8126 15.5 23.4304 15.5 23C15.5 22.5696 15.7754 22.1874 16.1838 22.0513L17.7607 21.5257C18.358 21.3266 18.8266 20.858 19.0257 20.2607L19.5513 18.6838C19.6874 18.2754 20.0696 18 20.5 18Z"
          fill="#64BFE9"
        />
      </g>
    </svg>
  );
}

export function ChatIconFilled({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heroicons-solid/chat-bubble-left-right">
        <g id="Union">
          <path
            d="M4.09423 2.21519C5.82308 1.99073 7.5858 1.875 9.37524 1.875C11.1645 1.875 12.927 1.99071 14.6557 2.21513C16.2576 2.42308 17.3986 3.76614 17.4936 5.32079C17.2221 5.23012 16.9343 5.16997 16.6334 5.14499C15.4762 5.04895 14.3061 5 13.125 5C11.9439 5 10.7738 5.04895 9.61665 5.14498C7.65142 5.30809 6.25 6.97056 6.25 8.84018V12.4114C6.25 13.8203 7.04557 15.1105 8.27734 15.7315L6.06694 17.9419C5.88819 18.1207 5.61937 18.1742 5.38582 18.0774C5.15228 17.9807 5 17.7528 5 17.5V14.1421C4.69708 14.1097 4.39514 14.0739 4.09424 14.0348C2.42055 13.8175 1.25 12.3611 1.25 10.7189V5.53115C1.25 3.88893 2.42055 2.43249 4.09423 2.21519Z"
            fill="#747474"
          />
          <path
            d="M13.125 6.25C11.9784 6.25 10.8428 6.29751 9.72004 6.3907C8.4373 6.49717 7.5 7.58568 7.5 8.84018V12.4114C7.5 13.6678 8.43997 14.7569 9.72434 14.8618C10.7609 14.9464 11.8084 14.9927 12.8653 14.9992L15.1831 17.3169C15.3618 17.4957 15.6306 17.5492 15.8642 17.4524C16.0977 17.3557 16.25 17.1278 16.25 16.875V14.8834C16.342 14.8765 16.4338 14.8693 16.5256 14.8619C17.81 14.757 18.75 13.6679 18.75 12.4115V8.84018C18.75 7.58569 17.8127 6.49717 16.53 6.39071C15.4072 6.29752 14.2716 6.25 13.125 6.25Z"
            fill="#747474"
          />
        </g>
      </g>
    </svg>
  );
}

export function ChatIconOutline({
  width = 20,
  height = 20,
  color = "#747474",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heroicons-outline/chat-bubble-left-right">
        <path
          id="Vector"
          d="M16.875 7.09253C17.6117 7.32957 18.125 8.03267 18.125 8.84018V12.4115C18.125 13.3587 17.4188 14.1619 16.4748 14.2389C16.1923 14.262 15.9091 14.2821 15.625 14.2994V16.875L13.125 14.375C11.9969 14.375 10.8797 14.329 9.77519 14.2388C9.5266 14.2186 9.2945 14.1479 9.08745 14.0375M16.875 7.09253C16.7488 7.05193 16.6161 7.025 16.4783 7.01356C15.3727 6.9218 14.2543 6.875 13.125 6.875C11.9957 6.875 10.8773 6.9218 9.77174 7.01356C8.82916 7.09179 8.125 7.89436 8.125 8.84018V12.4114C8.125 13.1092 8.50822 13.7288 9.08745 14.0375M16.875 7.09253V5.53109C16.875 4.17991 15.9152 3.00887 14.5753 2.83492C12.8732 2.61396 11.1375 2.5 9.37524 2.5C7.61278 2.5 5.87694 2.61399 4.1747 2.83499C2.83477 3.00895 1.875 4.17998 1.875 5.53115V10.7189C1.875 12.07 2.83478 13.2411 4.1747 13.415C4.65551 13.4774 5.13899 13.5313 5.625 13.5765V17.5L9.08745 14.0375"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function EditIcon({ width = 20, height = 20, color = "#4F98BC" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heroicons-solid/pencil" clipPath="url(#clip0_231_890)">
        <g id="Subtract">
          <path
            d="M10.8656 1.13442C10.353 0.621859 9.522 0.621859 9.00943 1.13442L8.43085 1.713L10.287 3.56916L10.8656 2.99058C11.3781 2.47801 11.3781 1.64699 10.8656 1.13442Z"
            fill={color}
          />
          <path
            d="M9.75668 4.09949L7.90052 2.24333L1.82511 8.31875C1.51671 8.62714 1.29002 9.00752 1.16551 9.4255L0.765616 10.7679C0.726306 10.8999 0.76248 11.0428 0.859844 11.1402C0.957209 11.2375 1.1001 11.2737 1.23207 11.2344L2.57451 10.8345C2.9925 10.71 3.37287 10.4833 3.68127 10.1749L9.75668 4.09949Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_231_890">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function LunaAnalysisIcon({ width = 24, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame 1354">
        <rect
          width="24"
          height="24"
          rx="12"
          fill="url(#paint0_linear_243_1469)"
        />
        <g id="Vector">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.64286 6.85714C9.85015 6.85714 10.0323 6.99456 10.0893 7.19388L10.5927 8.95581C10.813 9.727 11.4159 10.3298 12.1871 10.5502L13.949 11.0536C14.1483 11.1105 14.2857 11.2927 14.2857 11.5C14.2857 11.7073 14.1483 11.8895 13.949 11.9464L12.1871 12.4498C11.4159 12.6702 10.813 13.273 10.5927 14.0442L10.0893 15.8061C10.0323 16.0054 9.85015 16.1429 9.64286 16.1429C9.43556 16.1429 9.25338 16.0054 9.19643 15.8061L8.69303 14.0442C8.47269 13.273 7.86986 12.6702 7.09866 12.4498L5.33674 11.9464C5.13742 11.8895 5 11.7073 5 11.5C5 11.2927 5.13742 11.1105 5.33674 11.0536L7.09866 10.5502C7.86986 10.3298 8.47269 9.727 8.69303 8.95581L9.19643 7.19388C9.25338 6.99456 9.43556 6.85714 9.64286 6.85714Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.2143 5C15.4273 5 15.613 5.145 15.6647 5.35168L15.825 5.99268C15.9705 6.57489 16.4251 7.02949 17.0073 7.17504L17.6483 7.33529C17.855 7.38696 18 7.57267 18 7.78571C18 7.99876 17.855 8.18447 17.6483 8.23614L17.0073 8.39639C16.4251 8.54194 15.9705 8.99653 15.825 9.57875L15.6647 10.2197C15.613 10.4264 15.4273 10.5714 15.2143 10.5714C15.0012 10.5714 14.8155 10.4264 14.7639 10.2197L14.6036 9.57875C14.4581 8.99654 14.0035 8.54194 13.4213 8.39639L12.7803 8.23614C12.5736 8.18447 12.4286 7.99876 12.4286 7.78571C12.4286 7.57267 12.5736 7.38696 12.7803 7.33529L13.4213 7.17504C14.0035 7.02949 14.4581 6.57489 14.6036 5.99268L14.7639 5.35168C14.8155 5.145 15.0012 5 15.2143 5Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.2857 13.3571C14.4856 13.3571 14.663 13.485 14.7262 13.6746L14.9702 14.4068C15.0627 14.6841 15.2802 14.9016 15.5575 14.9941L16.2897 15.2381C16.4793 15.3013 16.6071 15.4787 16.6071 15.6786C16.6071 15.8784 16.4793 16.0558 16.2897 16.119L15.5575 16.3631C15.2802 16.4555 15.0627 16.6731 14.9702 16.9504L14.7262 17.6825C14.663 17.8721 14.4856 18 14.2857 18C14.0859 18 13.9085 17.8721 13.8453 17.6825L13.6012 16.9504C13.5088 16.6731 13.2912 16.4555 13.0139 16.3631L12.2818 16.119C12.0922 16.0558 11.9643 15.8784 11.9643 15.6786C11.9643 15.4787 12.0922 15.3013 12.2818 15.2381L13.0139 14.9941C13.2912 14.9016 13.5088 14.6841 13.6012 14.4068L13.8453 13.6746C13.9085 13.485 14.0859 13.3571 14.2857 13.3571Z"
            fill="#64BFE9"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_243_1469"
          x1="13.75"
          y1="23"
          x2="9.28192"
          y2="2.12703"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2C2A6B" />
          <stop offset="0.1" stopColor="#214082" />
          <stop offset="0.34" stopColor="#0972B5" />
          <stop offset="0.46" stopColor="#0085C9" />
          <stop offset="1" stopColor="#8ED7F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function Minimize() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Minimize" clipPath="url(#clip0_959_4640)">
        <path id="Vector" d="M6 11H18V13H6V11Z" fill="#747474" />
      </g>
      <defs>
        <clipPath id="clip0_959_4640">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function MapPinIcon({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 8.75C12.5 10.1307 11.3807 11.25 10 11.25C8.61929 11.25 7.5 10.1307 7.5 8.75C7.5 7.36929 8.61929 6.25 10 6.25C11.3807 6.25 12.5 7.36929 12.5 8.75Z"
        stroke="#D9D9D9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 8.75C16.25 14.7018 10 18.125 10 18.125C10 18.125 3.75 14.7018 3.75 8.75C3.75 5.29822 6.54822 2.5 10 2.5C13.4518 2.5 16.25 5.29822 16.25 8.75Z"
        stroke="#D9D9D9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ListAnalysisIcon({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_993_9320)">
        <path
          d="M10 7.5H13.3333C13.7917 7.5 14.1667 7.125 14.1667 6.66667C14.1667 6.20833 13.7917 5.83333 13.3333 5.83333H10C9.54167 5.83333 9.16667 6.20833 9.16667 6.66667C9.16667 7.125 9.54167 7.5 10 7.5ZM10 10.8333H13.3333C13.7917 10.8333 14.1667 10.4583 14.1667 10C14.1667 9.54167 13.7917 9.16667 13.3333 9.16667H10C9.54167 9.16667 9.16667 9.54167 9.16667 10C9.16667 10.4583 9.54167 10.8333 10 10.8333ZM10 14.1667H13.3333C13.7917 14.1667 14.1667 13.7917 14.1667 13.3333C14.1667 12.875 13.7917 12.5 13.3333 12.5H10C9.54167 12.5 9.16667 12.875 9.16667 13.3333C9.16667 13.7917 9.54167 14.1667 10 14.1667ZM5.83333 5.83333H7.5V7.5H5.83333V5.83333ZM5.83333 9.16667H7.5V10.8333H5.83333V9.16667ZM5.83333 12.5H7.5V14.1667H5.83333V12.5ZM16.6667 2.5H3.33333C2.875 2.5 2.5 2.875 2.5 3.33333V16.6667C2.5 17.125 2.875 17.5 3.33333 17.5H16.6667C17.125 17.5 17.5 17.125 17.5 16.6667V3.33333C17.5 2.875 17.125 2.5 16.6667 2.5ZM15.8333 15.8333H4.16667V4.16667H15.8333V15.8333Z"
          fill="#D9D9D9"
        />
      </g>
      <defs>
        <clipPath id="clip0_993_9320">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DocumentTextIcon({ width = 20, height = 20 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.25 11.875V9.6875C16.25 8.1342 14.9908 6.875 13.4375 6.875H12.1875C11.6697 6.875 11.25 6.45527 11.25 5.9375V4.6875C11.25 3.1342 9.9908 1.875 8.4375 1.875H6.875M6.875 12.5H13.125M6.875 15H10M8.75 1.875H4.6875C4.16973 1.875 3.75 2.29473 3.75 2.8125V17.1875C3.75 17.7053 4.16973 18.125 4.6875 18.125H15.3125C15.8303 18.125 16.25 17.7053 16.25 17.1875V9.375C16.25 5.23286 12.8921 1.875 8.75 1.875Z"
        stroke="#D9D9D9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function InspectionIcon({ width = 13, height = 16 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1122_8261)">
        <path
          d="M12.7648 14.9653L12.7632 14.9691C12.7593 14.9797 12.7554 14.9895 12.7515 15.0001C12.7483 15.0077 12.746 15.0145 12.7429 15.022C12.7319 15.0492 12.7201 15.0764 12.7075 15.1029C12.6997 15.1195 12.6911 15.1361 12.6824 15.1528C12.6738 15.1694 12.6652 15.186 12.6558 15.2019C12.6495 15.2125 12.6432 15.2238 12.6369 15.2344C12.6307 15.2449 12.6236 15.2555 12.6173 15.2661C12.6111 15.2767 12.604 15.2873 12.5969 15.2978C12.5867 15.3137 12.5758 15.3288 12.5648 15.3447C12.5538 15.3598 12.5428 15.3749 12.531 15.39C12.531 15.39 12.5303 15.3915 12.5295 15.3923C12.5067 15.4218 12.4824 15.4497 12.4573 15.4777C12.4314 15.5064 12.4039 15.5344 12.3757 15.5608C12.3577 15.5782 12.3388 15.5948 12.32 15.6115C12.32 15.6115 12.3192 15.6115 12.3184 15.6122C12.2988 15.6288 12.2792 15.6447 12.2596 15.6606C12.2557 15.6636 12.2525 15.6666 12.2486 15.6689C12.2321 15.6817 12.2149 15.6946 12.1968 15.7067C12.1215 15.7596 12.0399 15.8072 11.9529 15.848C11.1715 16.213 10.2333 15.9099 9.8363 15.1709L9.80021 15.0991L9.25735 14.0215L9.20714 13.921C9.01807 13.5454 8.93727 13.1457 8.95375 12.7565C8.95375 12.7436 8.95453 12.7316 8.95531 12.7187C8.95688 12.6923 8.95924 12.6651 8.96159 12.6386C8.96394 12.6099 8.96708 12.5819 8.971 12.5532C8.97257 12.5389 8.97493 12.5237 8.97728 12.5094C8.98042 12.4897 8.98356 12.4708 8.98669 12.4512C8.98905 12.4361 8.99219 12.4202 8.99532 12.4051C8.99689 12.3953 8.99925 12.3862 9.00082 12.3764C9.00238 12.3688 9.00395 12.362 9.00552 12.3552C9.00552 12.353 9.00552 12.3515 9.00631 12.3499C9.01023 12.3326 9.01415 12.3159 9.01807 12.2986C9.11849 11.886 9.32873 11.4998 9.6339 11.1854C9.73902 11.0766 9.85591 10.9769 9.983 10.8869C10.0395 10.8469 10.0991 10.8084 10.1603 10.7721C10.2097 10.7426 10.2599 10.7154 10.3125 10.689C10.5188 10.556 10.7173 10.4101 10.9064 10.2529L11.0083 10.4547L11.0107 10.4592L11.7944 12.0144L12.6707 13.7532L12.7107 13.8326C12.8848 14.2044 12.8927 14.6087 12.764 14.9676L12.7648 14.9653Z"
          fill="url(#paint0_linear_1122_8261)"
        />
        <path
          d="M12.6646 7.7488C12.5383 8.09944 12.3806 8.43194 12.1962 8.74252C11.8526 9.32137 11.4141 9.82843 10.9073 10.2501C10.7182 10.4073 10.5198 10.5531 10.3134 10.6861C10.2609 10.7126 10.2107 10.7398 10.1613 10.7693C10.1001 10.8055 10.0412 10.8433 9.98396 10.8841C9.85687 10.974 9.74077 11.0745 9.63486 11.1826C9.32969 11.4977 9.11866 11.8831 9.01903 12.2957C9.01511 12.3123 9.0104 12.3297 9.00726 12.3471C9.00726 12.3486 9.00648 12.3509 9.0057 12.3524C9.00413 12.3592 9.00256 12.3668 9.00177 12.3743C8.99942 12.3841 8.99785 12.3932 8.99628 12.403C8.99314 12.4181 8.99001 12.434 8.98765 12.4491C8.98451 12.468 8.98138 12.4877 8.97824 12.5073C8.97588 12.5217 8.97353 12.5368 8.97196 12.5511C8.96804 12.5799 8.9649 12.6078 8.96255 12.6365C8.96019 12.663 8.95784 12.6902 8.95627 12.7166C8.95549 12.7295 8.9547 12.7416 8.9547 12.7544C8.93744 13.1262 9.01119 13.492 9.16024 13.8245L8.029 11.5801L7.88152 11.2876C7.88152 11.2876 7.87995 11.2846 7.87916 11.2839L7.82582 11.1781C7.82582 11.1781 7.82425 11.1751 7.82425 11.1735C7.56458 10.6234 7.51516 9.98032 7.73717 9.36369C7.90897 8.88534 8.21963 8.49163 8.61109 8.20901C8.61266 8.2075 8.61423 8.20674 8.6158 8.20523C8.64797 8.18407 8.67935 8.16291 8.71073 8.14024C8.71073 8.14024 8.71229 8.13949 8.71308 8.13873C9.00413 7.93092 9.25752 7.67097 9.45678 7.36718C9.57602 7.18506 9.67565 6.98708 9.75175 6.77473C10.2899 5.27924 9.46698 3.64697 7.91446 3.12858C6.36195 2.61018 4.66745 3.40289 4.12928 4.89838C3.65937 6.20419 4.22734 7.61429 5.41193 8.29591C5.45037 8.31783 5.48959 8.33899 5.52882 8.35939C6.23016 8.77955 6.46629 9.66294 6.05286 10.3597C5.64728 11.0436 4.76315 11.2997 4.03828 10.9604C3.97944 10.9295 3.92061 10.8977 3.86334 10.8645C1.42513 9.48611 0.253884 6.59941 1.21489 3.92733C2.31004 0.881942 5.76103 -0.73219 8.92332 0.323495C12.0848 1.37842 13.7605 4.70266 12.6654 7.7488H12.6646Z"
          fill="url(#paint1_linear_1122_8261)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1122_8261"
          x1="12.3596"
          y1="15.9186"
          x2="8.80806"
          y2="9.75063"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.12" stopColor="#D5DD28" />
          <stop offset="0.23" stopColor="#B8C828" />
          <stop offset="0.46" stopColor="#6E942A" />
          <stop offset="0.72" stopColor="#17562C" />
          <stop offset="0.75" stopColor="#174925" />
          <stop offset="0.82" stopColor="#173A1D" />
          <stop offset="0.89" stopColor="#173118" />
          <stop offset="1" stopColor="#172F17" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1122_8261"
          x1="13.4735"
          y1="6.38163"
          x2="-1.67927"
          y2="7.94197"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1" stopColor="#6EC4EB" />
          <stop offset="0.2" stopColor="#65BEE8" />
          <stop offset="0.36" stopColor="#4CB1E1" />
          <stop offset="0.55" stopColor="#299DD7" />
          <stop offset="0.62" stopColor="#2691CB" />
          <stop offset="0.74" stopColor="#2072AC" />
          <stop offset="0.86" stopColor="#194E88" />
          <stop offset="1" stopColor="#0D152E" />
        </linearGradient>
        <clipPath id="clip0_1122_8261">
          <rect
            width="12.1212"
            height="16"
            fill="white"
            transform="translate(0.878906)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function AnalysisReportActive({ width = 30, height = 29 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 30 29"
      fill="none"
    >
      <path
        d="M8.28125 20.8906V8.06693C8.28125 5.46499 10.4611 3.35571 13.1502 3.35571H10.1855C7.48882 3.35571 5.30273 5.46584 5.30273 8.06887V20.8886C5.30273 23.4916 7.48882 25.6018 10.1855 25.6018H13.1502C10.4611 25.6018 8.28125 23.4925 8.28125 20.8906Z"
        fill="#17457A"
        fillOpacity="0.1"
      />
      <path
        d="M19.2188 26.5444H10.2344C7.00352 26.5444 4.375 24.0072 4.375 20.8886V8.06887C4.375 4.95027 7.00352 2.41309 10.2344 2.41309H19.2188C22.4496 2.41309 25.0781 4.95027 25.0781 8.06887V20.8886C25.0781 24.0072 22.4496 26.5444 19.2188 26.5444ZM10.2344 4.29835C8.08047 4.29835 6.32812 5.9898 6.32812 8.06887V20.8886C6.32812 22.9677 8.08047 24.6592 10.2344 24.6592H19.2188C21.3727 24.6592 23.125 22.9677 23.125 20.8886V8.06887C23.125 5.9898 21.3727 4.29835 19.2188 4.29835H10.2344ZM20 7.03198C20 6.51136 19.5628 6.08935 19.0234 6.08935C18.4841 6.08935 18.0469 6.51136 18.0469 7.03198C18.0469 7.81163 17.3897 8.44592 16.582 8.44592H12.8711C12.0634 8.44592 11.4062 7.81163 11.4062 7.03198C11.4062 6.51136 10.969 6.08935 10.4297 6.08935C9.89033 6.08935 9.45312 6.51136 9.45312 7.03198C9.45312 8.85116 10.9864 10.3312 12.8711 10.3312H16.582C18.4667 10.3312 20 8.85116 20 7.03198Z"
        fill="#17457A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5714 13.4286C13.7309 13.4286 13.871 13.5343 13.9148 13.6876L14.3021 15.0429C14.4716 15.6362 14.9353 16.0999 15.5285 16.2694L16.8838 16.6566C17.0372 16.7004 17.1429 16.8405 17.1429 17C17.1429 17.1595 17.0372 17.2996 16.8838 17.3434L15.5285 17.7306C14.9353 17.9001 14.4716 18.3638 14.3021 18.9571L13.9148 20.3124C13.871 20.4657 13.7309 20.5714 13.5714 20.5714C13.412 20.5714 13.2718 20.4657 13.228 20.3124L12.8408 18.9571C12.6713 18.3638 12.2076 17.9001 11.6144 17.7306L10.259 17.3434C10.1057 17.2996 10 17.1595 10 17C10 16.8405 10.1057 16.7004 10.259 16.6566L11.6144 16.2694C12.2076 16.0999 12.6713 15.6362 12.8408 15.0429L13.228 13.6876C13.2718 13.5343 13.412 13.4286 13.5714 13.4286Z"
        fill="#78C1CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8571 12C18.021 12 18.1639 12.1115 18.2036 12.2705L18.3269 12.7636C18.4389 13.2115 18.7885 13.5611 19.2364 13.6731L19.7295 13.7964C19.8885 13.8361 20 13.979 20 14.1429C20 14.3067 19.8885 14.4496 19.7295 14.4893L19.2364 14.6126C18.7885 14.7246 18.4389 15.0743 18.3269 15.5221L18.2036 16.0152C18.1639 16.1742 18.021 16.2857 17.8571 16.2857C17.6933 16.2857 17.5504 16.1742 17.5107 16.0152L17.3874 15.5221C17.2754 15.0743 16.9257 14.7246 16.4779 14.6126L15.9848 14.4893C15.8258 14.4496 15.7143 14.3067 15.7143 14.1429C15.7143 13.979 15.8258 13.8361 15.9848 13.7964L16.4779 13.6731C16.9257 13.5611 17.2754 13.2115 17.3874 12.7636L17.5107 12.2705C17.5504 12.1115 17.6933 12 17.8571 12Z"
        fill="#78C1CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1429 18.4286C17.2966 18.4286 17.4331 18.5269 17.4817 18.6728L17.6694 19.236C17.7405 19.4493 17.9079 19.6166 18.1212 19.6877L18.6844 19.8755C18.8302 19.9241 18.9286 20.0606 18.9286 20.2143C18.9286 20.368 18.8302 20.5045 18.6844 20.5531L18.1212 20.7408C17.9079 20.8119 17.7405 20.9793 17.6694 21.1926L17.4817 21.7558C17.4331 21.9016 17.2966 22 17.1429 22C16.9891 22 16.8527 21.9016 16.804 21.7558L16.6163 21.1926C16.5452 20.9793 16.3778 20.8119 16.1646 20.7408L15.6013 20.5531C15.4555 20.5045 15.3571 20.368 15.3571 20.2143C15.3571 20.0606 15.4555 19.9241 15.6013 19.8755L16.1646 19.6877C16.3778 19.6166 16.5452 19.4493 16.6163 19.236L16.804 18.6728C16.8527 18.5269 16.9891 18.4286 17.1429 18.4286Z"
        fill="#78C1CE"
      />
    </svg>
  );
}

export function LunaIcon({ width = 34, height = 28 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1097_13709)">
        <path
          d="M13.5547 21.4599C13.5477 21.6586 13.5255 21.8559 13.4846 22.0508C13.3315 22.7799 12.8792 23.393 12.247 23.7298C11.9127 23.9074 11.5399 24 11.1659 24C10.1246 24 9.14405 23.2524 8.8624 22.1841C8.75371 21.7696 8.77708 21.3391 8.72098 20.9184C8.67657 20.589 8.61112 20.2621 8.52464 19.9426C8.35401 19.3172 8.10157 18.7164 7.77667 18.1625C7.13388 17.067 6.2106 16.1628 5.12605 15.5707C4.7357 15.3572 4.32665 15.1845 3.90475 15.0562C3.68737 14.9896 3.46649 14.9353 3.24443 14.8922C3.03757 14.8527 2.77578 14.87 2.57944 14.7997C2.5736 14.7972 2.56541 14.7972 2.55957 14.7972H2.55373C2.46023 14.7885 2.36557 14.7824 2.27207 14.7787C1.09401 14.6319 0.183594 13.5747 0.183594 12.2942C0.183594 11.0137 1.14544 9.89973 2.37024 9.7998C2.59346 9.80597 2.81318 9.82078 3.0329 9.84298C5.34459 10.0626 7.46346 10.9989 9.18379 12.441C10.2578 13.3415 11.1799 14.4394 11.894 15.6755C12.6245 16.94 13.1352 18.3451 13.39 19.802C13.4846 20.3435 13.577 20.9061 13.5571 21.4612L13.5547 21.4599Z"
          fill="url(#paint0_linear_1097_13709)"
        />
        <path
          d="M12.965 7.09577C12.9007 7.28205 12.8294 7.46585 12.7534 7.64966C12.7044 7.77426 12.6518 7.89762 12.5968 8.01975C11.7893 9.82946 10.6019 11.3221 9.18188 12.4398C7.46155 10.9977 5.34385 10.0614 3.03099 9.8418C2.92697 9.83193 2.82179 9.82083 2.71777 9.81466C3.67728 9.87141 4.79456 9.77025 5.6372 9.2509C6.79071 8.54033 7.73269 7.18212 8.39184 5.98552C8.76816 5.30209 9.00891 4.61373 9.73351 4.25105C10.3377 3.94882 11.0612 3.92538 11.7133 4.25105C12.7441 4.76177 13.2513 5.97195 12.965 7.09454V7.09577Z"
          fill="url(#paint1_linear_1097_13709)"
        />
        <path
          d="M13.8091 2.66091C13.8172 2.45366 13.8394 2.24641 13.8827 2.04163C14.0428 1.27679 14.5161 0.635312 15.1788 0.282498C15.5294 0.096222 15.9186 0 16.3113 0C17.4017 0 18.4301 0.783346 18.7246 1.90224C18.8392 2.33647 18.8146 2.78797 18.8731 3.22837C18.9186 3.57379 18.9876 3.9155 19.0788 4.25104C19.2576 4.90732 19.5217 5.53647 19.863 6.11627C20.5361 7.26353 21.5038 8.21095 22.6398 8.83146C23.0488 9.05474 23.4778 9.23608 23.9195 9.37055C24.1474 9.43963 24.3788 9.49761 24.6114 9.54202C24.8288 9.58396 25.1023 9.56546 25.3079 9.63824C25.3138 9.64071 25.322 9.64071 25.3278 9.64071H25.3337C25.4318 9.64934 25.5312 9.65551 25.6293 9.66045C26.8635 9.81465 27.8172 10.9212 27.8172 12.2621C27.8172 13.6031 26.8097 14.7713 25.5265 14.8749C25.2928 14.8688 25.0625 14.8527 24.8323 14.8293C22.4096 14.5998 20.1914 13.6179 18.3892 12.1079C17.2638 11.1655 16.2984 10.0145 15.5493 8.72043C14.7838 7.39553 14.2497 5.92382 13.982 4.39784C13.8827 3.83038 13.7857 3.24194 13.8079 2.65968L13.8091 2.66091Z"
          fill="url(#paint2_linear_1097_13709)"
        />
        <path
          d="M14.427 17.7085C14.4948 17.5136 14.5684 17.3211 14.6491 17.1275C14.7005 16.9979 14.7554 16.8672 14.8127 16.7401C15.6588 14.844 16.9023 13.2798 18.3901 12.1104C20.1922 13.6203 22.4116 14.6023 24.8332 14.8317C24.9418 14.8428 25.0529 14.8539 25.1616 14.8601C24.1565 14.7996 22.9866 14.9057 22.1031 15.4498C20.8946 16.1949 19.9082 17.6172 19.2175 18.8706C18.8225 19.5861 18.5712 20.3077 17.8116 20.6877C17.1781 21.0035 16.4208 21.0294 15.7371 20.6877C14.6572 20.1523 14.1255 18.8854 14.4258 17.7085H14.427Z"
          fill="url(#paint3_linear_1097_13709)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1097_13709"
          x1="13.8072"
          y1="25.3705"
          x2="3.36608"
          y2="12.0863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17447F" />
          <stop offset="0.07" stopColor="#195590" />
          <stop offset="0.3" stopColor="#2187C2" />
          <stop offset="0.41" stopColor="#259BD6" />
          <stop offset="0.59" stopColor="#38A5DB" />
          <stop offset="0.94" stopColor="#6AC1EA" />
          <stop offset="1" stopColor="#74C7ED" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1097_13709"
          x1="11.7039"
          y1="3.74404"
          x2="4.5154"
          y2="12.3875"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#D5DD28" />
          <stop offset="0.17" stopColor="#D0D928" />
          <stop offset="0.26" stopColor="#C2CF28" />
          <stop offset="0.36" stopColor="#AABE28" />
          <stop offset="0.46" stopColor="#8AA729" />
          <stop offset="0.57" stopColor="#60892A" />
          <stop offset="0.67" stopColor="#2D652B" />
          <stop offset="0.72" stopColor="#17562C" />
          <stop offset="0.87" stopColor="#172F17" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1097_13709"
          x1="12.6065"
          y1="-2.76453"
          x2="23.5455"
          y2="11.1526"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17447F" />
          <stop offset="0.16" stopColor="#1C639E" />
          <stop offset="0.41" stopColor="#259BD6" />
          <stop offset="0.59" stopColor="#38A5DB" />
          <stop offset="0.94" stopColor="#6AC1EA" />
          <stop offset="1" stopColor="#74C7ED" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1097_13709"
          x1="15.7476"
          y1="21.2206"
          x2="23.2792"
          y2="12.1659"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.09" stopColor="#D5DD28" />
          <stop offset="0.17" stopColor="#D0D928" />
          <stop offset="0.26" stopColor="#C2CF28" />
          <stop offset="0.36" stopColor="#AABE28" />
          <stop offset="0.46" stopColor="#8AA729" />
          <stop offset="0.57" stopColor="#60892A" />
          <stop offset="0.67" stopColor="#2D652B" />
          <stop offset="0.72" stopColor="#17562C" />
          <stop offset="0.87" stopColor="#172F17" />
        </linearGradient>
        <clipPath id="clip0_1097_13709">
          <rect
            width="27.6364"
            height="24"
            fill="white"
            transform="translate(0.181641)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function InsightsReport() {
  return (
    <svg
      width="30"
      height="29"
      viewBox="0 0 30 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.28125 20.8906V8.06693C8.28125 5.46499 10.4611 3.35571 13.1502 3.35571H10.1855C7.48882 3.35571 5.30273 5.46584 5.30273 8.06887V20.8886C5.30273 23.4916 7.48882 25.6018 10.1855 25.6018H13.1502C10.4611 25.6018 8.28125 23.4925 8.28125 20.8906Z"
        fill="#17457A"
        fill-opacity="0.1"
      />
      <path
        d="M19.2188 26.5444H10.2344C7.00352 26.5444 4.375 24.0072 4.375 20.8886V8.06887C4.375 4.95027 7.00352 2.41309 10.2344 2.41309H19.2188C22.4496 2.41309 25.0781 4.95027 25.0781 8.06887V20.8886C25.0781 24.0072 22.4496 26.5444 19.2188 26.5444ZM10.2344 4.29835C8.08047 4.29835 6.32812 5.9898 6.32812 8.06887V20.8886C6.32812 22.9677 8.08047 24.6592 10.2344 24.6592H19.2188C21.3727 24.6592 23.125 22.9677 23.125 20.8886V8.06887C23.125 5.9898 21.3727 4.29835 19.2188 4.29835H10.2344ZM20 7.03198C20 6.51136 19.5628 6.08935 19.0234 6.08935C18.4841 6.08935 18.0469 6.51136 18.0469 7.03198C18.0469 7.81163 17.3897 8.44592 16.582 8.44592H12.8711C12.0634 8.44592 11.4062 7.81163 11.4062 7.03198C11.4062 6.51136 10.969 6.08935 10.4297 6.08935C9.89033 6.08935 9.45312 6.51136 9.45312 7.03198C9.45312 8.85116 10.9864 10.3312 12.8711 10.3312H16.582C18.4667 10.3312 20 8.85116 20 7.03198Z"
        fill="#17457A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5714 13.4286C13.7309 13.4286 13.871 13.5343 13.9148 13.6876L14.3021 15.0429C14.4716 15.6362 14.9353 16.0999 15.5285 16.2694L16.8838 16.6566C17.0372 16.7004 17.1429 16.8405 17.1429 17C17.1429 17.1595 17.0372 17.2996 16.8838 17.3434L15.5285 17.7306C14.9353 17.9001 14.4716 18.3638 14.3021 18.9571L13.9148 20.3124C13.871 20.4657 13.7309 20.5714 13.5714 20.5714C13.412 20.5714 13.2718 20.4657 13.228 20.3124L12.8408 18.9571C12.6713 18.3638 12.2076 17.9001 11.6144 17.7306L10.259 17.3434C10.1057 17.2996 10 17.1595 10 17C10 16.8405 10.1057 16.7004 10.259 16.6566L11.6144 16.2694C12.2076 16.0999 12.6713 15.6362 12.8408 15.0429L13.228 13.6876C13.2718 13.5343 13.412 13.4286 13.5714 13.4286Z"
        fill="#78C1CE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.8571 12C18.021 12 18.1639 12.1115 18.2036 12.2705L18.3269 12.7636C18.4389 13.2115 18.7885 13.5611 19.2364 13.6731L19.7295 13.7964C19.8885 13.8361 20 13.979 20 14.1429C20 14.3067 19.8885 14.4496 19.7295 14.4893L19.2364 14.6126C18.7885 14.7246 18.4389 15.0743 18.3269 15.5221L18.2036 16.0152C18.1639 16.1742 18.021 16.2857 17.8571 16.2857C17.6933 16.2857 17.5504 16.1742 17.5107 16.0152L17.3874 15.5221C17.2754 15.0743 16.9257 14.7246 16.4779 14.6126L15.9848 14.4893C15.8258 14.4496 15.7143 14.3067 15.7143 14.1429C15.7143 13.979 15.8258 13.8361 15.9848 13.7964L16.4779 13.6731C16.9257 13.5611 17.2754 13.2115 17.3874 12.7636L17.5107 12.2705C17.5504 12.1115 17.6933 12 17.8571 12Z"
        fill="#78C1CE"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.1429 18.4286C17.2966 18.4286 17.4331 18.5269 17.4817 18.6728L17.6694 19.236C17.7405 19.4493 17.9079 19.6166 18.1212 19.6877L18.6844 19.8755C18.8302 19.9241 18.9286 20.0606 18.9286 20.2143C18.9286 20.368 18.8302 20.5045 18.6844 20.5531L18.1212 20.7408C17.9079 20.8119 17.7405 20.9793 17.6694 21.1926L17.4817 21.7558C17.4331 21.9016 17.2966 22 17.1429 22C16.9891 22 16.8527 21.9016 16.804 21.7558L16.6163 21.1926C16.5452 20.9793 16.3778 20.8119 16.1646 20.7408L15.6013 20.5531C15.4555 20.5045 15.3571 20.368 15.3571 20.2143C15.3571 20.0606 15.4555 19.9241 15.6013 19.8755L16.1646 19.6877C16.3778 19.6166 16.5452 19.4493 16.6163 19.236L16.804 18.6728C16.8527 18.5269 16.9891 18.4286 17.1429 18.4286Z"
        fill="#78C1CE"
      />
    </svg>
  );
}

export function ArrowUpGray() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM12.5303 7.71967C12.3897 7.57902 12.1989 7.5 12 7.5C11.8011 7.5 11.6103 7.57902 11.4697 7.71967L8.46967 10.7197C8.17678 11.0126 8.17678 11.4874 8.46967 11.7803C8.76256 12.0732 9.23744 12.0732 9.53033 11.7803L11.25 10.0607L11.25 15.75C11.25 16.1642 11.5858 16.5 12 16.5C12.4142 16.5 12.75 16.1642 12.75 15.75L12.75 10.0607L14.4697 11.7803C14.7626 12.0732 15.2374 12.0732 15.5303 11.7803C15.8232 11.4874 15.8232 11.0126 15.5303 10.7197L12.5303 7.71967Z"
        fill="#D9D9D9"
      />
    </svg>
  );
}

export function ArrowUpBlue() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 17.3848 6.61522 21.75 12 21.75C17.3848 21.75 21.75 17.3848 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25ZM12.5303 7.71967C12.3897 7.57902 12.1989 7.5 12 7.5C11.8011 7.5 11.6103 7.57902 11.4697 7.71967L8.46967 10.7197C8.17678 11.0126 8.17678 11.4874 8.46967 11.7803C8.76256 12.0732 9.23744 12.0732 9.53033 11.7803L11.25 10.0607L11.25 15.75C11.25 16.1642 11.5858 16.5 12 16.5C12.4142 16.5 12.75 16.1642 12.75 15.75L12.75 10.0607L14.4697 11.7803C14.7626 12.0732 15.2374 12.0732 15.5303 11.7803C15.8232 11.4874 15.8232 11.0126 15.5303 10.7197L12.5303 7.71967Z"
        fill="#4F98BC"
      />
    </svg>
  );
}

export function CheckGreen() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Check circle" clipPath="url(#clip0_2336_8793)">
        <path
          id="Vector"
          d="M10 1.66675C5.40002 1.66675 1.66669 5.40008 1.66669 10.0001C1.66669 14.6001 5.40002 18.3334 10 18.3334C14.6 18.3334 18.3334 14.6001 18.3334 10.0001C18.3334 5.40008 14.6 1.66675 10 1.66675ZM7.74169 13.5751L4.75002 10.5834C4.42502 10.2584 4.42502 9.73341 4.75002 9.40841C5.07502 9.08341 5.60002 9.08341 5.92502 9.40841L8.33335 11.8084L14.0667 6.07508C14.3917 5.75008 14.9167 5.75008 15.2417 6.07508C15.5667 6.40008 15.5667 6.92508 15.2417 7.25008L8.91669 13.5751C8.60002 13.9001 8.06669 13.9001 7.74169 13.5751Z"
          fill="#9CBB65"
        />
      </g>
      <defs>
        <clipPath id="clip0_2336_8793">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function NoComments({ width = 25, height = 24 }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Comments disabled" clipPath="url(#clip0_2405_2030)">
        <path
          id="Vector"
          d="M1.89016 2.81C1.50016 3.2 1.50016 3.83 1.89016 4.22L2.50016 4.83V16C2.50016 17.1 3.40016 18 4.50016 18H15.6702L20.2802 22.61C20.6702 23 21.3002 23 21.6902 22.61C22.0802 22.22 22.0802 21.59 21.6902 21.2L3.31016 2.81C2.92016 2.42 2.28016 2.42 1.89016 2.81ZM6.88016 9.21L8.67016 11H7.50016C6.95016 11 6.50016 10.55 6.50016 10C6.50016 9.68 6.65016 9.4 6.88016 9.21ZM7.50016 14C6.95016 14 6.50016 13.55 6.50016 13C6.50016 12.45 6.95016 12 7.50016 12H9.67016L11.6702 14H7.50016ZM15.3302 12L14.3302 11H17.5002C18.0502 11 18.5002 10.55 18.5002 10C18.5002 9.45 18.0502 9 17.5002 9H12.3302L11.3302 8H17.5002C18.0502 8 18.5002 7.55 18.5002 7C18.5002 6.45 18.0502 6 17.5002 6H9.33016L5.33016 2H20.5002C21.6002 2 22.5002 2.9 22.5002 4V19.17L17.3302 14H17.5002C18.0502 14 18.5002 13.55 18.5002 13C18.5002 12.45 18.0502 12 17.5002 12H15.3302Z"
          fill="#78C1CE"
        />
      </g>
      <defs>
        <clipPath id="clip0_2405_2030">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
