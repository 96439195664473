import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { AnalysisIcon, SuccessIcon } from "../images/images";

import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";
import { editReport } from "services/lunaLensService";

const DialogUpdateAnalysis = ({
  open,
  handleCancel,
  closeLensDialog,
  indexAnalysis,
  analysis,
}) => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const updateWorkticketAnalysisList = useLunaLensStore(
    (state) => state.updateWorkticketAnalysisList
  );
  const analysisHistory = useLunaLensStore((state) => state.analysisHistory);
  const setAnalysisHistory = useLunaLensStore(
    (state) => state.setAnalysisHistory
  );

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setIsLoading(false);
      setSuccess(false);
    }
  }, [open]);

  const handleSaveAnalysis = async () => {
    setIsLoading(true);
    const data = {
      analysis_id: analysis.analysisId,
      categories: analysisHistory.luna_lens_analysis_categories,
    };
    const response = await editReport(data, analysis.analysisId);
    updateWorkticketAnalysisList(response.data.analysis);
    setAnalysisHistory(response.data.analysis.luna_lens_workticket_analysis[0]);
    setIsLoading(false);
    setSuccess(true);
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {isLoading ? (
        <Box className={classes.loadingContainer}>
          <LoadingStateHorizontal isVisible={isLoading} />
        </Box>
      ) : (
        <>
          <MuiDialogTitle disableTypography className={classes.root}>
            {success ? (
              <Box className={classes.successContainer}>
                <SuccessIcon />
                <Typography variant="h6" className={classes.dialogTitle}>
                  Success!
                </Typography>
              </Box>
            ) : (
              <Box className={classes.titleContainerAnalysis}>
                <AnalysisIcon />
                <Typography variant="h6" className={classes.dialogTitle}>
                  Save Changes
                </Typography>
              </Box>
            )}
            {handleCancel ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleCancel}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
          <MuiDialogContent>
            {success ? (
              <Box>
                <Typography variant="body1" className={classes.successContent}>
                  Your analysis has been saved successfully.
                </Typography>
              </Box>
            ) : (
              <Box className={classes.filterGroupCenter}>
                <Typography
                  variant="body1"
                  className={classes.filterTitleAnalysis}
                >
                  Are you sure you want to update this analysis?
                </Typography>
              </Box>
            )}
          </MuiDialogContent>
          <MuiDialogActions>
            {success ? (
              <Button
                onClick={() => {
                  handleCancel();
                  closeLensDialog();
                }}
                className={classes.buttonConfirmSuccess}
                color="primary"
                variant="contained"
                size="large"
                fullWidth
              >
                Okay
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleCancel}
                  className={classes.buttonCancelVariantAnalysis}
                  variant="outlined"
                  size="large"
                  color="primary"
                  fullWidth
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleSaveAnalysis}
                  className={classes.buttonConfirmVariantAnalysis}
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Save Analysis
                </Button>
              </>
            )}
          </MuiDialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogUpdateAnalysis;
