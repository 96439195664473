import React from "react";
import * as worknovaService from "services/worknovaService";
import { merge } from "lodash";

const defaultState = {
  worknovaListLoading: true,
  worknovaSelected: null,
  worknovaList: [],
  worknovaCount: [],
  worknovaDetails: [],
  editMode: false,
  permissions: [],
};

const WorknovaStateContext = React.createContext();
const WorknovaDispatchContext = React.createContext();

function worknovaReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        worknovaListLoading: action.worknovaListLoading,
      };
    case "SET_WORKNOVA_LIST":
      return {
        ...state,
        worknovaList: action.worknovaList,
      };
    case "SET_WORKNOVA_COUNT":
      return {
        ...state,
        worknovaCount: action.worknovaCount,
      };
    case "SET_WORKNOVA_SELECTED":
      return {
        ...state,
        worknovaSelected: action.worknovaSelected,
      };
    case "SET_WORKNOVA_DETAILS":
      return {
        ...state,
        worknovaDetails: action.worknovaDetails,
      };
    case "SET_PERMISSIONS":
      return {
        ...state,
        permissions: action.permissions,
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function WorknovaProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = React.useReducer(worknovaReducer, mergedState);
  return (
    <WorknovaStateContext.Provider value={state}>
      <WorknovaDispatchContext.Provider value={dispatch}>
        {children}
      </WorknovaDispatchContext.Provider>
    </WorknovaStateContext.Provider>
  );
}

function useWorknovaState() {
  const context = React.useContext(WorknovaStateContext);
  if (context === undefined) {
    throw new Error("useWorknovaState must be used within a WorknovaProvider");
  }
  return context;
}

function useWorknovaDispatch() {
  const context = React.useContext(WorknovaDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useWorknovaDispatch must be used within a WorknovaProvider"
    );
  }
  return context;
}

async function getWorknovaList(dispatch, filterData) {
  try {
    const worknovaListResult = await worknovaService.getWorknovaList(
      filterData
    );
    //const resultAll = await quoteService.getQuotesTab("all");
    let worknovaList = [];
    worknovaList[-1] = worknovaListResult.data.data.upload_records;
    dispatch({
      type: "SET_WORKNOVA_LIST",
      worknovaList: worknovaList,
    });
    let worknovaCount = [];
    worknovaCount[-1] = worknovaListResult.data.data.count;
    dispatch({
      type: "SET_WORKNOVA_COUNT",
      worknovaCount: worknovaCount,
    });
    dispatch({
      type: "SET_LOADING",
      worknovaListLoading: false,
    });
  } catch (error) {
    console.log(error);
  }
}

async function getWorknovaListDataSet(
  worknovaList,
  dispatch,
  offset,
  filterData
) {
  try {
    const result = await worknovaService.getWorknovaTabOffset(
      offset,
      filterData
    );
    worknovaList[-1] = worknovaList[-1].concat(result.data.data.upload_records);
    dispatch({
      type: "SET_WORKNOVA_LIST",
      worknovaList: worknovaList,
    });
  } catch (error) {
    console.log(error);
  }
}

async function searchWorknova(search, filterData) {
  try {
    const worknovaListResult = await worknovaService.getWorknovaSearch(
      search,
      filterData
    );
    return worknovaListResult.data.data.upload_records;
  } catch (error) {
    console.log(error);
  }
}

export {
  WorknovaProvider,
  useWorknovaState,
  useWorknovaDispatch,
  getWorknovaList,
  getWorknovaListDataSet,
  searchWorknova,
};
