import React, { Fragment, useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "../../../styles";
import { useGeographicalCoverage } from "../../../context/GeographicalCoverageContext";
import { useGeoCoverage } from "./useGeoCoverage";
import {
  getStates,
  getCities,
  getZipCodes,
  createCity,
  createZipCode,
} from "services/partnerService";
import {
  mapData,
  fetchAndSetData,
  filterCitiesBySelectedStates,
  filterZipCodesBySelectedCities,
} from "components/util/filterGeolocationUtils";
import LoadingIndicator from "../../../../../components/common/LoadingIndicator/loadingIndicator";
import FilterModal from "pages/partnersList/dialog/partnerFiltersDialog";
import Joi from "joi-browser";
import * as classnames from "classnames";
import { mapBoxKey } from "lib/config";
import { SearchBox } from "@mapbox/search-js-react";

const validationSchema = Joi.object({
  states: Joi.array().min(1).required().label("States"),
  cities: Joi.array().min(1).required().label("Cities"),
});

const joiErrorToMessage = (error) => {
  const errorMessages = {};
  error.details.forEach((detail) => {
    errorMessages[detail.path[0]] = detail.message;
  });
  return errorMessages;
};

export function GeographicalCoverageDrawer() {
  const classes = useStyles();
  const [geoAction, dispatchGeoDrawer] = useGeographicalCoverage();
  const { isEditing } = geoAction;
  const {
    statesList,
    citiesList,
    zipCodesList,
    setFilterCities,
    setFilterStates,
    setFilterZipCodes,
    submitEditing,
    submitAdd,
    loading,
  } = useGeoCoverage();

  const [filterType, setFilterType] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [statesListData, setStatesListData] = useState([]);
  const [citiesListData, setCitiesListData] = useState([]);
  const [zipCodesListData, setZipCodesListData] = useState([]);
  const [selectedItems, setSelectedItems] = useState({
    trades: [],
    states: [],
    cities: [],
    zipCodes: [],
  });

  const dataLists = {
    states: statesListData,
    cities: citiesListData,
    zipCodes: zipCodesListData,
  };

  const fetchData = async () => {
    await fetchAndSetData(
      getStates,
      setStatesListData,
      "states",
      "abbreviation",
      "name",
      {},
      mapData,
      setIsLoading
    );
    await fetchAndSetData(
      getCities,
      setCitiesListData,
      "cities",
      "id",
      "city_name",
      { state_abbreviation: "state_abbreviation" },
      mapData,
      setIsLoading
    );
    await fetchAndSetData(
      getZipCodes,
      setZipCodesListData,
      "zip_codes",
      "id",
      "zip",
      { city: "city.city_name", state_abbreviation: "city.state_abbreviation" },
      mapData,
      setIsLoading
    );
  };

  useEffect(() => {
    if (
      statesListData.length === 0 ||
      citiesListData.length === 0 ||
      zipCodesListData.length === 0
    ) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEditing) {
      setSelectedItems((prevState) => ({
        ...prevState,
        states: statesList,
        cities: citiesList,
        zipCodes: zipCodesList,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, statesList, citiesList, zipCodesList]);

  useEffect(() => {
    setFilterStates(selectedItems.states.map((item) => item.value));
    setFilterCities(selectedItems.cities.map((item) => item.value));
    setFilterZipCodes(selectedItems.zipCodes.map((item) => item.value));
  }, [selectedItems, setFilterStates, setFilterCities, setFilterZipCodes]);

  useEffect(() => {
    if (selectedItems.states.length > 0) {
      const assignedCitiesToSelectedStates = filterCitiesBySelectedStates(
        selectedItems.cities,
        selectedItems.states
      );

      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        cities: assignedCitiesToSelectedStates,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.states]);

  useEffect(() => {
    if (selectedItems.cities.length > 0) {
      const assignedZipCodesToSelectedCities = filterZipCodesBySelectedCities(
        selectedItems.zipCodes,
        selectedItems.cities
      );

      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        zipCodes: assignedZipCodesToSelectedCities,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.cities]);

  const handleOpenModal = (type) => {
    setFilterType(type);
    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [type]: dataLists[type].filter((item) =>
        prevSelected[type].some((selected) => selected.value === item.value)
      ),
    }));
    setModalOpen(true);
  };

  const handleModalChange = (selected) => {
    setSelectedItems((prevSelected) => ({
      ...prevSelected,
      [filterType]: selected,
    }));

    setValidationErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (selected.length > 0) {
        delete updatedErrors[filterType];
      } else {
        updatedErrors[filterType] = `${
          filterType.charAt(0).toUpperCase() + filterType.slice(1)
        } is required`;
      }
      return updatedErrors;
    });

    setModalOpen(false);
  };

  const handleCloseModal = () => setModalOpen(false);

  const renderSelectedValues = (items) => {
    return items.map((item) => item.label).join(", ");
  };

  const handleSave = () => {
    const { error } = validationSchema.validate(
      {
        states: selectedItems.states,
        cities: selectedItems.cities,
      },
      { abortEarly: false }
    );

    if (error) {
      setValidationErrors(joiErrorToMessage(error));
      return;
    }

    setValidationErrors({});

    (isEditing ? submitEditing : submitAdd)();
  };

  const toggleDrawer = () => {
    dispatchGeoDrawer({ type: "TOGGLE_DRAWER", isDrawerOpen: false });
  };

  const handleSelect = async (response) => {
    const feature = response.features[0];
    if (!feature) return;

    const addressData = feature.properties;
    let city =
      addressData?.context?.locality?.name ||
      addressData?.context?.place?.name ||
      "";
    let state = addressData?.context?.region?.region_code || "";
    const zip = addressData?.context?.postcode?.name || "";

    const foundZip = zipCodesListData.find((z) => z.label === zip);
    if (foundZip) {
      city = foundZip.city;
      state = foundZip.state_abbreviation;
    }

    const foundState = statesListData.find(
      (st) => st.value === state.toUpperCase()
    );
    const foundCity = citiesListData.find(
      (c) => c.label === city && c.state_abbreviation === state.toUpperCase()
    );
    let newStates = [...selectedItems.states];
    let newCities = [...selectedItems.cities];
    let newZipCodes = [...selectedItems.zipCodes];
    console.log(foundState, foundCity, foundZip);
    if (foundState && !selectedItems.states.some((st) => st.value === state)) {
      newStates = [...selectedItems.states, foundState];
    }
    if (
      foundCity &&
      !selectedItems.cities.some(
        (c) => c.label === city && c.state_abbreviation === state.toUpperCase()
      )
    ) {
      newCities = [...selectedItems.cities, foundCity];
    }
    if (
      foundZip &&
      !selectedItems.zipCodes.some((z) => z.label === zip && z.city === city)
    ) {
      newZipCodes = [...selectedItems.zipCodes, foundZip];
    }
    if (!foundCity && city && state) {
      const dataCity = await createCity({
        city_name: city,
        state_abbreviation: state,
      });
      const newCity = dataCity.data;
      setCitiesListData([
        ...citiesListData,
        {
          label: newCity.city_name,
          value: newCity.id,
          state_abbreviation: newCity.state_abbreviation,
        },
      ]);
      newCities = [
        ...selectedItems.cities,
        {
          label: newCity.city_name,
          value: newCity.id,
          state_abbreviation: newCity.state_abbreviation,
        },
      ];
    }
    if (!foundZip && city && state && zip) {
      const dataZip = await createZipCode({
        city_name: city,
        state_abbreviation: state,
        zip: zip,
      });
      const newZip = dataZip.data;
      setZipCodesListData([
        ...zipCodesListData,
        {
          label: newZip.zip,
          value: newZip.id,
          city,
          state_abbreviation: state,
        },
      ]);
      newZipCodes = [
        ...selectedItems.zipCodes,
        {
          label: newZip.zip,
          value: newZip.id,
          city,
          state_abbreviation: state,
        },
      ];
    }
    setSelectedItems({
      states: newStates,
      cities: newCities,
      zipCodes: newZipCodes,
    });
  };

  return (
    <Fragment>
      <Drawer anchor={"right"} open={true} onClose={toggleDrawer}>
        <Box className={classes.drawerOuterContainer}>
          <Box className={classes.drawerContainer}>
            <Box>
              <Box className={classes.drawerTitleContainer}>
                <Box onClick={toggleDrawer} className={classes.drawerCloseIcon}>
                  <CloseIcon
                    style={{ fontSize: 16 }}
                    className={classes.drawerCloseIcon}
                  />
                </Box>
                <Typography className={classes.drawerTitle}>
                  Geographical Coverage
                </Typography>
                <Typography className={classes.drawerInfoText}>
                  Tell us more about the geographical coverage of this partner.
                </Typography>
              </Box>
              <Box className={classes.drawerFormContainer}>
                <Fragment>
                  {(isLoading || loading) && <LoadingIndicator />}
                  {!isLoading && !loading && (
                    <>
                      <Box className={classes.searchBoxContainer}>
                        <SearchBox
                          accessToken={mapBoxKey}
                          onRetrieve={handleSelect}
                          options={{
                            language: "en",
                            country: "US",
                          }}
                        />
                      </Box>
                      <Box>
                        <FormControl className={classes.formControl} fullWidth>
                          <FormLabel
                            className={
                              !isEditing
                                ? classes.filterLabel
                                : classes.inputLabel
                            }
                          >
                            States Serviced*
                          </FormLabel>
                          <Box
                            onClick={() => {
                              if (!isEditing) {
                                handleOpenModal("states");
                              }
                            }}
                            className={classes.box}
                          >
                            <TextField
                              required
                              fullWidth
                              id="states"
                              name="states"
                              variant="standard"
                              placeholder="Select state"
                              value={renderSelectedValues(selectedItems.states)}
                              InputProps={{
                                style: !isEditing
                                  ? {}
                                  : { color: "#a0a0a0", height: 48 },
                                disableUnderline: true,
                                readOnly: true,
                                endAdornment: !isEditing && (
                                  <IconButton>
                                    <ArrowRightIcon />
                                  </IconButton>
                                ),
                              }}
                              className={classnames(
                                classes.inputFieldFilters,
                                classes.readOnlyInput
                              )}
                            />
                          </Box>
                          {!!validationErrors.states && (
                            <FormHelperText className={classes.formHelperText}>
                              {validationErrors.states}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Box>

                      <Box>
                        <FormControl className={classes.formControl} fullWidth>
                          <FormLabel
                            className={
                              selectedItems.states.length > 0
                                ? classes.filterLabel
                                : classes.inputLabel
                            }
                          >
                            Cities Serviced*
                          </FormLabel>
                          <Box
                            onClick={() =>
                              selectedItems.states.length > 0 &&
                              handleOpenModal("cities")
                            }
                          >
                            <TextField
                              required
                              fullWidth
                              id="cities"
                              name="cities"
                              variant="standard"
                              placeholder="Select Cities"
                              value={renderSelectedValues(selectedItems.cities)}
                              InputProps={{
                                className: classes.inputHeight,
                                disableUnderline: true,
                                readOnly: true,
                                endAdornment: selectedItems.states.length >
                                  0 && (
                                  <IconButton>
                                    <ArrowRightIcon />
                                  </IconButton>
                                ),
                              }}
                              className={classnames(classes.inputFieldFilters, {
                                [classes.readOnlyInput]:
                                  selectedItems.states.length === 0,
                              })}
                            />
                          </Box>
                          {!!validationErrors.cities && (
                            <FormHelperText className={classes.formHelperText}>
                              {validationErrors.cities}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Box>

                      <Box>
                        <FormControl className={classes.formControl} fullWidth>
                          <FormLabel
                            className={
                              selectedItems.cities.length > 0
                                ? classes.filterLabel
                                : classes.inputLabel
                            }
                          >
                            Zip Codes
                          </FormLabel>
                          <Box
                            onClick={() =>
                              selectedItems.cities.length > 0 &&
                              handleOpenModal("zipCodes")
                            }
                            className={classes.box}
                          >
                            <TextField
                              fullWidth
                              id="zipCodes"
                              name="zipCodes"
                              variant="standard"
                              placeholder="Select Zip Codes"
                              value={renderSelectedValues(
                                selectedItems.zipCodes
                              )}
                              InputProps={{
                                className: classes.inputHeight,
                                disableUnderline: true,
                                readOnly: true,
                                endAdornment: selectedItems.cities.length >
                                  0 && (
                                  <IconButton>
                                    <ArrowRightIcon />
                                  </IconButton>
                                ),
                              }}
                              className={classnames(classes.inputFieldFilters, {
                                [classes.readOnlyInput]:
                                  selectedItems.cities.length === 0 &&
                                  selectedItems.zipCodes.length === 0,
                              })}
                            />
                          </Box>
                        </FormControl>
                      </Box>
                    </>
                  )}
                </Fragment>
              </Box>
            </Box>
          </Box>
          <Box className={classes.drawerButtonsContainer}>
            <Button
              onClick={toggleDrawer}
              disabled={loading}
              startIcon={<ArrowBackIcon />}
              className={classes.goBackButton}
            >
              Go Back
            </Button>
            <Button
              onClick={handleSave}
              disabled={loading}
              className={classes.saveChangesButton}
            >
              {isEditing ? "Save Changes" : "Save"}
            </Button>
          </Box>
        </Box>

        <FilterModal
          isOpen={modalOpen}
          onCloseModal={handleCloseModal}
          availableStates={statesList}
          filterType={filterType}
          optionsList={dataLists[filterType] || []}
          currentSelections={selectedItems}
          onFilterChange={handleModalChange}
        />
      </Drawer>
    </Fragment>
  );
}
