import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      "& > div": {
        margin: "0 auto",
      },
    },
  },
  iconWrapper: {
    textAlign: "center",
  },
  titleWrapper: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    textTransform: "capitalize",
  },
}));

export const DialogTitle = (props) => {
  const classes = useStyles();

  const { title, icon } = props;

  return (
    <Grid container spacing={2} className={classes.header}>
      <Grid item md={12}>
        {icon && <Box className={classes.iconWrapper}>{icon}</Box>}
        <Typography variant="h4" className={classes.titleWrapper} gutterBottom>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};
