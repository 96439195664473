import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { LunaAnalysisIcon } from "./images/images";

import useStyles from "./styles";
import CategoryHeader from "./categoryHeader";
import useLunaLensStore from "store/lunaLensAnalysis";
import DialogLoadingLuna from "./dialog/dialogLoadingLuna";
import AnalysisListHistory from "./analysisListHistory";
import AnalysisLunaChat from "./analysisLunaChat";

const AnalysisCardHistory = ({
  newAnalysisFlag,
  edit,
  indexAnalysis,
  analysis,
  userId,
  userAnalysisId,
}) => {
  const classes = useStyles();
  const [openLoading, setOpenLoading] = useState(false);
  const analysisHistory = useLunaLensStore((state) => state.analysisHistory);

  return (
    <Box
      className={classes.analysisCardContainerHistory}
      style={{ backgroundColor: newAnalysisFlag ? "#F8F8F8" : "white" }}
    >
      <Box className={classes.chatLensIconContainer}>
        <AnalysisLunaChat />
      </Box>
      <Box className={classes.analysisHeaderContainerHistory}>
        {newAnalysisFlag && <LunaAnalysisIcon />}
        <Typography variant="body1" className={classes.analysisHeaderTitle}>
          {analysis?.responseText}
        </Typography>
      </Box>
      {analysisHistory?.luna_lens_analysis_categories
        ?.filter(
          (category) =>
            category.luna_lens_analysis_categories_trade &&
            category.luna_lens_analysis_categories_trade.length > 0
        )
        .map((category, index) => (
          <React.Fragment key={index}>
            <CategoryHeader
              id={category.id}
              categoryId={category.luna_lens_category.id}
              iconType={category.luna_lens_category.icon}
              tagColor={category.luna_lens_category.icon_color}
              tagBackgroundColor={category.luna_lens_category.background_color}
              action={category?.action || ""}
              title={category.luna_lens_category.label}
              backGroundHeader="#ECECEC"
              feedback={newAnalysisFlag}
              fillUp={false}
              fillDown={false}
              onHandleThumbUp={() => {}}
              onHandleThumbDown={() => {}}
              edit={edit}
              indexCategory={index}
              deleted={category?.action === "deleteCategory"}
              indexAnalysis={indexAnalysis}
              userId={userId}
              userAnalysisId={userAnalysisId}
            />
            <AnalysisListHistory
              analysis={category?.luna_lens_analysis_categories_trade}
              background={newAnalysisFlag}
              edit={edit}
              indexCategory={index}
              deleted={category?.action === "deleteCategory"}
            />
          </React.Fragment>
        ))}
      <DialogLoadingLuna open={openLoading} handleCancel={setOpenLoading} />
    </Box>
  );
};

export default AnalysisCardHistory;
