import http from "./httpService";
import moment from "moment";
import { apiUrl } from "lib/config";
import { getFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";
const apiEndpoint = apiUrl + "/worktickets";
const apiEndpointSingular = apiUrl + "/workticket";
const apiEndpointProject = apiUrl + "/projects";

export function getWorktickets(filterData, filterAddon = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}${filterAddon ?? ""}`, {});
}

export function getExternalFilters() {
  const customerId = 63;
  return http.get(`${apiEndpoint}/filters-external?customer_id=${customerId}`);
}

export function getServiceCategoriesFilters() {
  return http.get(`${apiUrl}/filters/service-categories`);
}

export function getWorkticketsDeprecated(filterData, filterAddon = undefined) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/deprecated?${filterString}${filterAddon ?? ""}`
  );
}

export function getWorkticketsProject(project_id, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpointProject}/${project_id}/worktickets?${filterString}`
  );
}

export function getWorkticketsGeo(geoData) {
  const geoString = `latitude=${geoData.latitude}&longitude=${geoData.longitude}&radius=${geoData.radius}`;
  return http.get(`${apiEndpoint}/geo?${geoString}`);
}

export function getWorkticketsTab(tab, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}filters[tab]=${tab}`);
}

export function getWorkticketsTabProject(project_id, tab, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpointProject}/${project_id}/worktickets?${filterString}filters[tab]=${tab}`,
    {}
  );
}

export function getWorkticketsTabOffset(
  tab,
  offset,
  filterData,
  filterAddon = undefined
) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}filters[tab]=${tab}&offset=${offset}${
      filterAddon ?? ""
    }`
  );
}

export function getWorkticketsTabOffsetDeprecated(
  tab,
  offset,
  filterData,
  filterAddon = undefined
) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}/deprecated?${filterString}filters[tab]=${tab}&offset=${offset}${
      filterAddon ?? ""
    }`
  );
}

export function getWorkticketsTabOffsetProject(
  project_id,
  tab,
  offset,
  filterData
) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpointProject}/${project_id}/worktickets?${filterString}filters[tab]=${tab}&offset=${offset}`
  );
}

export function getWorkticketsTabData(
  tab,
  offset,
  filterData,
  filterAddon = undefined
) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}filters[tab]=${tab}&offset=${offset}${
      filterAddon ?? ""
    }`
  );
}

export function getWorkticketsSearch(search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}search=${search}`);
}

export function getWorkticketsSearchProject(project_id, search, filterData) {
  const filterString = getFilterString(filterData);
  return http.get(
    `${apiEndpointProject}/${project_id}/worktickets?${filterString}search=${search}`
  );
}

export function getWorkticketDetails(id) {
  return http.get(`${apiEndpoint}/${id}`);
}

export function updateWorkticketDetails(id, data) {
  return http.put(`${apiEndpoint}/${id}`, data);
}

export function updateWorkticketStatus(id, data) {
  return http.put(`${apiEndpoint}/${id}/change-status`, data);
}

export function reviewWorkticketCancellation(id, data) {
  return http.put(`${apiEndpoint}/${id}/review-cancellation`, data);
}

export function recallWorkticketStatus(id, data) {
  return http.put(`${apiEndpoint}/${id}/recall`, data);
}

export function updateWorkticketWGStatus(id, data) {
  return http.put(`${apiEndpoint}/${id}/change-status-wg`, data);
}

export function updateWorkticketWGApprove(id, data) {
  return http.put(`${apiEndpoint}/${id}/approve-wg`, data);
}

export function updateWorkticketBulk(data) {
  http.setFormEncode();
  return http.post(`${apiEndpoint}/update/bulk`, setFormData(data));
}

export function updateWorkticketBulkStatus(data) {
  return http.post(`${apiEndpoint}/change-status/bulk`, data);
}

export function createWorkticket(data) {
  return http.post(`${apiEndpointSingular}`, data);
}

export function createWorkticketEmergency(data) {
  return http.post(`${apiEndpointSingular}`, setFormData(data));
}

export function assignUserWorkticket(id_workticket, data) {
  return http.post(`${apiEndpoint}/${id_workticket}/user`, data);
}

export function saveWorkticketBulkAssign(data) {
  return http.post(`${apiEndpoint}/bulk-assign`, data);
}

export function removeUserWorkticket(id_workticket, id_user) {
  return http.delete(`${apiEndpoint}/${id_workticket}/users/${id_user}`, {});
}

export function addWorkticketTask(id, data) {
  return http.post(`${apiEndpoint}/${id}/task`, data);
}

export function completeWorkticketTask(id, task_id) {
  return http.put(`${apiEndpoint}/${id}/tasks/${task_id}/complete`);
}

export function deleteWorkticketTask(id, task_id) {
  return http.delete(`${apiEndpoint}/${id}/tasks/${task_id}`);
}

export function getWorkticketComments(id) {
  return http.get(`${apiEndpoint}/${id}/comments`);
}

export function addWorkticketComment(id, data) {
  return http.post(`${apiEndpoint}/${id}/comment`, data);
}

export function updateWorkticketComment(id, id_comment, data) {
  return http.put(`${apiEndpoint}/${id}/comments/${id_comment}`, data);
}

export function deleteWorkticketComment(id, id_comment) {
  return http.delete(`${apiEndpoint}/${id}/comments/${id_comment}`);
}

export function saveWorkticketSchedule(id, data) {
  return http.post(`${apiEndpoint}/${id}/schedule`, data);
}

export function saveWorkticketBulkSchedule(data) {
  return http.post(`${apiEndpoint}/bulk-schedule`, data);
}

export function updateWorkticketSchedule(id_workticket, id_schedule, data) {
  return http.put(
    `${apiEndpoint}/${id_workticket}/schedules/${id_schedule}/`,
    data
  );
}

export function deleteWorkticketSchedule(id_workticket, id_schedule) {
  return http.delete(
    `${apiEndpoint}/${id_workticket}/schedules/${id_schedule}`,
    {}
  );
}

export function assignUserSchedule(id_workticket, id_schedule, data) {
  return http.post(
    `${apiEndpoint}/${id_workticket}/schedules/${id_schedule}/user`,
    data
  );
}

export function removeUserSchedule(id_workticket, id_schedule, id_user) {
  return http.delete(
    `${apiEndpoint}/${id_workticket}/schedules/${id_schedule}/users/${id_user}`
  );
}

export function getWorkticketSchedule(id) {
  return http.get(`${apiEndpoint}/${id}/schedules`);
}

export function getWorkticketScheduleCalendar(
  start_date = undefined,
  end_date = undefined
) {
  return http.get(
    `${apiEndpoint}/schedules/calendar?${
      start_date ? `start_date=${start_date}` : ``
    }${end_date ? `&end_date=${end_date}` : ``}`
  );
}

export function getWorkticketScheduleUser(id, user_id, current) {
  return http.get(
    `${apiEndpoint}/${id}/schedules/users/${user_id}${
      current ? "?current=1" : ""
    }`
  );
}

export function clockInWorkticketStrict(id, id_schedule, data) {
  return http.post(`${apiEndpoint}/${id}/clock/${id_schedule}/in`, data);
}

export function clockInWorkticketNonStrict(id, data) {
  return http.post(`${apiEndpoint}/${id}/clock/in`, data);
}

export function clockOutWorkticket(id, id_schedule, data) {
  return http.post(`${apiEndpoint}/${id}/clock/${id_schedule}/out`, data);
}

export function clockPreviewWorkticket(id, data) {
  return http.post(`${apiEndpoint}/${id}/clock/preview`, data);
}

export function saveWorkticketSignature(id, data) {
  http.setMultiPart();
  return http.post(
    `${apiEndpoint}/${id}/upload-signature-file`,
    setFormData(data)
  );
}

export function saveWorkticketFile(id, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/${id}/upload-files`, setFormData(data));
}

export function saveWorkticketFileWG(id, data) {
  http.setMultiPart();
  return http.post(`${apiEndpoint}/${id}/upload-files-wg`, setFormData(data));
}

export function duplicateWorkticket(id) {
  return http.post(`${apiEndpoint}/${id}/duplicate`, {});
}

export function deleteWorkticket(id) {
  return http.delete(`${apiEndpoint}/${id}`, {});
}

export function saveTimekeepingManual(id, data) {
  return http.post(`${apiEndpoint}/${id}/clock`, data);
}

export function updateTimekeepingManual(id, id_entry, data) {
  return http.put(`${apiEndpoint}/${id}/timekeeping/entries/${id_entry}`, data);
}

export function deleteTimekeepingManual(id, id_entry) {
  return http.delete(`${apiEndpoint}/${id}/timekeeping/entries/${id_entry}`);
}

export function clockTimekeeping(id, id_schedule, data) {
  return http.post(`${apiEndpoint}/${id}/clock/${id_schedule}/in`, data);
}

export function getTimekeepingHistory(id) {
  return http.get(`${apiEndpoint}/${id}/timekeeping`, {
    params: {},
  });
}

export function getWorkticketPdf(
  id,
  timekeeping = undefined,
  signature = undefined,
  image = undefined
) {
  const tParam = timekeeping ? "has_timekeeping=1&" : "";
  const sParam = signature ? "has_signature=1&" : "";
  const iParam = image ? "has_image=1&" : "";
  return http.get(`${apiEndpoint}/${id}/pdf?${tParam}${sParam}${iParam}`, {
    responseType: "blob",
  });
}

export function workticketGenerator(data) {
  return http.post(`${apiUrl}/workticket-generator`, data);
}

export function deleteWorkticketGenerator(id) {
  return http.delete(`${apiUrl}/workticket-generators/${id}`);
}

export function workticketTimekeeping(is_exported, dateFilter, filters) {
  const dateStartFilterString = `${
    dateFilter.startDateActive
      ? `&start_date=${moment(dateFilter.startDate).format(
          "YYYY-MM-DD HH:mm:ss"
        )}`
      : ``
  }`;

  const dateEndFilterString = `${
    dateFilter.endDateActive
      ? `&end_date=${moment(dateFilter.endDate).format("YYYY-MM-DD HH:mm:ss")}`
      : ``
  }`;

  const selectedJobFilter = filters.find(
    (filter) => filter.filter === "job_id"
  );
  const jobFilterString = selectedJobFilter
    ? `&job_id=${selectedJobFilter.values[0].value}`
    : ``;
  return http.get(
    `${apiEndpoint}/timekeeping/list?export_file=0&is_exported=${is_exported}${dateStartFilterString}${dateEndFilterString}${jobFilterString}`
  );
}

export function workticketTimekeepingExport(data) {
  return http.post(`${apiEndpoint}/timekeeping/export-ids`, data);
}

export function workticketBillingAP(id) {
  return http.get(`${apiEndpoint}/${id}/ap-records `);
}

export function workticketExport(data) {
  return http.post(`${apiEndpoint}/export-ids`, data);
}

export function workticketUnlink(id) {
  return http.post(`${apiEndpoint}/${id}/recurrence-override`);
}

export function workticketBillOverride(id, data) {
  return http.post(`${apiEndpoint}/${id}/bill-override`, data);
}

export function workticketPaymentOverride(id, data) {
  return http.post(`${apiEndpoint}/${id}/payment-override`, data);
}

export function changePartner(id_workticket, id_user) {
  return http.post(`${apiEndpoint}/${id_workticket}/partner/${id_user}`, {});
}

export function rate(id_workticket, data) {
  return http.put(`${apiEndpoint}/${id_workticket}/rate`, data);
}

export function saveWorkticketVideoLink(id, data) {
  return http.post(`${apiEndpoint}/${id}/upload-files`, data);
}

export function workticketFiles(id) {
  return http.get(`${apiEndpoint}/${id}/files`);
}

export function workticketPinningFile(workticket_id, file_id) {
  return http.post(
    `${apiEndpoint}/${workticket_id}/files/${file_id}/toggle-pin`
  );
}

export function updateWorkticketFMStatus(id, data) {
  return http.put(`${apiEndpoint}/${id}/change-status-fm`, data);
}

export function aproveWorkticketFM(id) {
  return http.put(`${apiEndpoint}/${id}/approve-fm`);
}

export function acceptWorkticketSC(id) {
  return http.put(`${apiEndpoint}/${id}/accept-sc`);
}

export function declineWorkticketSC(id) {
  return http.put(`${apiEndpoint}/${id}/decline-sc`);
}

export function getInvoiceSC(id) {
  return http.get(`${apiEndpoint}/${id}/get-invoice-sc`);
}

export function submitInvoiceSC(id, data) {
  return http.post(`${apiEndpoint}/${id}/submit-invoice-sc`, data);
}

export function getInvoiceFMPilot(id) {
  return http.get(`${apiEndpoint}/${id}/get-invoice-fm`);
}

export function getQuoteFMPilot(id) {
  return http.get(`${apiEndpoint}/${id}/get-quote-fm`);
}

export function getRatesFMPilot(id) {
  return http.get(`${apiEndpoint}/${id}/get-rates-fm`);
}

export function getTaxesFMPilot(id) {
  return http.get(`${apiEndpoint}/${id}/get-taxes-fm`);
}

export function getInvoiceAttachmentsFMPilot(id, invoice) {
  return http.get(`${apiEndpoint}/${id}/get-invoice-attachments-fm/${invoice}`);
}

export function submitInvoiceFMPilot(id, data) {
  return http.post(`${apiEndpoint}/${id}/submit-invoice-fm`, data);
}

export function saveInvoiceFileFMPilot(id, data) {
  http.setMultiPart();
  return http.post(
    `${apiEndpoint}/${id}/submit-invoice-files-fm`,
    setFormData(data)
  );
}

export function submitQuoteFMPilot(id, data) {
  return http.post(`${apiEndpoint}/${id}/submit-quote-fm`, data);
}

export function declineRequest(id, data) {
  return http.post(`${apiEndpoint}/${id}/submit-decline-fm`, data);
}

export function acceptWorkticketCO(id) {
  return http.put(`${apiEndpoint}/${id}/accept-corrigo`);
}

export function approveWorkticketCO(id) {
  return http.put(`${apiEndpoint}/${id}/approve-corrigo`);
}

export function declineRequestCO(id, data) {
  return http.post(`${apiEndpoint}/${id}/submit-decline-corrigo`, data);
}

export function uploadFileCheckStatus(id) {
  return http.get(`${apiEndpoint}/${id}/upload-files-check-status`);
}

export async function uploadFileChunk(id, data, onUploadProgress) {
  return http.post(`${apiEndpoint}/${id}/upload-files-chunk`, data, {
    onUploadProgress,
  });
}

export function getRatesCorrigo(id) {
  return http.get(`${apiEndpoint}/${id}/get-rates-corrigo`);
}

export function submitQuoteCorrigo(id, data) {
  return http.post(`${apiEndpoint}/${id}/submit-quote-corrigo`, data);
}

export function getQuoteCorrigo(id) {
  return http.get(`${apiEndpoint}/${id}/get-quote-corrigo`);
}

export function submitInvoiceCorrigo(id, data) {
  return http.post(`${apiEndpoint}/${id}/submit-invoice-corrigo`, data);
}

export function getInvoiceCorrigo(id) {
  return http.get(`${apiEndpoint}/${id}/get-invoice-corrigo`);
}
