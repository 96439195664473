export const getYoutubeId = (url) => {
  const regExp =
    /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
};

export const getYouTubeThumbnailById = (videoId, isHq) => {
  return `https://img.youtube.com/vi/${videoId}/${
    isHq ? "mqdefault" : "default"
  }.jpg`;
};

export const getVimeoThumbnail = async (videoUrl, quality) => {
  const vimeoId = videoUrl.split("/").pop();
  const response = await fetch(
    `https://vimeo.com/api/v2/video/${vimeoId}.json`
  );
  const data = await response.json();
  return quality ? data[0].thumbnail_large : data[0].thumbnail_small;
};

export const getWistiaThumbnail = async (videoUrl) => {
  const wistiaId = videoUrl.split("/").pop();
  const response = await fetch(
    `https://fast.wistia.com/oembed?url=https://home.wistia.com/medias/${wistiaId}&width=600&height=400`
  );
  const data = await response.json();
  return data.thumbnail_url;
};

export const getMP4Thumbnail = async (videoUrl) => {
  const response = await fetch(
    `/api/get-thumbnail?url=${encodeURIComponent(videoUrl)}`
  );
  const data = await response.json();
  return data.thumbnail_url;
};

export const getThumbnailUrl = async (url, isHq) => {
  if (url.includes("youtube.com") || url.includes("youtu.be")) {
    return getYouTubeThumbnailById(getYoutubeId(url), isHq);
  } else if (url.includes("vimeo.com")) {
    return getVimeoThumbnail(url, isHq);
  } else if (url.includes("wistia.com")) {
    return getWistiaThumbnail(url);
  }
  return null;
};

export const validateYouTubeLink = (link) => {
  const youtubeRegex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
  return youtubeRegex.test(link);
};

export const validateVimeoLink = (link) => {
  const vimeoPattern = /^(https?\:\/\/)?(www\.)?vimeo\.com\/.+$/;
  return vimeoPattern.test(link);
};

export const validateWistiaLink = (link) => {
  const wistiaPattern = /^(https?\:\/\/)?(.+\.)?wistia\.com\/medias\/.+$/;
  return wistiaPattern.test(link);
};

export const validateMP4Link = (link) => {
  const videoPattern = /\.(mp4|mov|avi|mkv|flv|wmv|webm|mpeg|mpg)$/i;
  return videoPattern.test(link);
};

export const validateVideoLink = (link) => {
  return (
    validateYouTubeLink(link) ||
    validateVimeoLink(link) ||
    validateWistiaLink(link) ||
    validateMP4Link(link)
  );
};
