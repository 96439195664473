import React, { useContext, useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import MessageDialog from "pages/workticketPage/dialog/successDialog";
import ConfirmDialog from "components/ui/dialog/confirmDialog";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import { assign } from "lodash";
import GlobalUiContext from "contexts/globalUiContext";
import { useWorkticketView } from "contexts/workticketViewContext";
import WorkticketComment from "components/ui/Worktickets/WorkticketComment";
import {
  getWorkticketComments,
  addWorkticketComment,
  updateWorkticketComment,
  deleteWorkticketComment,
} from "services/workticketService";
import useStyles from "pages/workticketPage/styles";
import { logException } from "components/util/logUtil";
import { hasPermission, permissionWorkticket } from "lib/permissions";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";
import ZeroStateCommentsLens from "./zeroStateCommentsLens";

const INTERNAL = "internal";
const PARTNER = "partner";
const CUSTOMER = "customer";

const LensComments = ({ workticketId, workticketNumber }) => {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { role, permissions } = globalUi;
  const [stateContext, dispatchContext] = useWorkticketView();

  const [subTab, setSubTab] = useState(null);
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");
  const [selectedComment, setSelectedComment] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const { workticketComments } = stateContext;

  useEffect(() => {
    fetchWorkTicketComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workticketComments) {
      if (subTab === null) {
        const firstKey = Object.keys(workticketComments)[0];
        if (firstKey) {
          setSubTab(firstKey);
        }
      }

      setCommentList(workticketComments || []);
    } else {
      setCommentList([]);
    }
  }, [workticketComments, subTab]);

  const setWorkTicketComments = (comments, flagList = true) => {
    dispatchContext({
      type: "SET_WORKTICKET_COMMENTS",
      workticketComments: comments,
    });
    if (flagList) {
      setCommentList(assign(comments, { isEditable: false }));
    }
  };

  const handleSubTabChange = (event, newValue) => {
    setSubTab(newValue);
  };

  const fetchWorkTicketComments = async () => {
    try {
      if (!workticketId) return;
      setIsLoadingComments(true);
      setWorkTicketComments(null, false);
      const response = await getWorkticketComments(workticketId);
      setWorkTicketComments(response.data.data, false);
      setIsLoadingComments(false);
    } catch (e) {
      logException(e, "Cannot load workticket comments data");
    }
  };

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  const closeMessage = () => {
    if (!isLoadingData) {
      setOpenMessage(false);
    }
  };

  const handleAddComment = async () => {
    if (comment === "") return;

    const data = { body: comment, tab: subTab, type: 2 };

    try {
      setIsLoadingData(true);
      setOpenMessage(true);

      await addWorkticketComment(workticketId, data);
      const refreshData = await getWorkticketComments(workticketId);
      setWorkTicketComments(refreshData.data.data);

      setComment("");
      setOpenMessageContent("Comment has been added successfully.");
    } catch (e) {
      logException(e, "Cannot add comment");
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleUpdateComment = async (commentId, newBody) => {
    const data = { body: newBody, tab: subTab };

    try {
      setIsLoadingData(true);
      setOpenMessage(true);

      await updateWorkticketComment(workticketId, commentId, data);

      setCommentList((prevCommentList) => {
        const updatedCommentList = { ...prevCommentList };
        updatedCommentList[subTab] = prevCommentList[subTab].map((c) =>
          c.id === commentId ? { ...c, body: newBody } : c
        );
        return updatedCommentList;
      });

      setOpenMessageContent("Comment has been updated successfully.");
    } catch (e) {
      logException(e, "Cannot update comment");
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await deleteWorkticketComment(workticketId, commentId);

      setCommentList((prevCommentList) => {
        const updatedCommentList = { ...prevCommentList };
        updatedCommentList[subTab] = prevCommentList[subTab].filter(
          (c) => c.id !== commentId
        );
        return updatedCommentList;
      });

      setOpenConfirm(false);
    } catch (e) {
      logException(e, "Cannot delete comment");
    }
  };

  const handleDeleteConfirmation = (commentId) => {
    setSelectedComment(commentId);
    setOpenConfirm(true);
  };

  const hasComments = (obj) => {
    if (!obj) {
      return false;
    }
    const keys = Object.keys(obj);
    if (keys?.length === 0) {
      return false;
    }
    const firstKey = keys[0];
    return Array.isArray(obj[firstKey]) && obj[firstKey].length > 0;
  };

  const renderComments = () => {
    const comments = commentList[subTab] || [];
    return comments.length > 0 ? (
      comments.map((comment) => (
        <WorkticketComment
          key={comment.id}
          id={comment.id}
          externalId={comment.external_id}
          user={{
            id: comment.user_id,
            name: comment.user_name,
            avatar: comment.profile_url,
          }}
          time={comment.video_metadata}
          date={comment.created_at}
          message={comment.body}
          fileId={comment.files_id}
          fileUrl={comment.file_url}
          fileName={comment.display_name}
          fileOwner={comment.user_name}
          fileType={comment.mime_type}
          fileSize={comment.file_size}
          fileCreateAt={comment.files_created_at}
          workticketId={workticketId}
          workticketNumber={workticketNumber}
          displayFileDetails={true}
          onUpdateComment={handleUpdateComment}
          onDeleteComment={() => handleDeleteConfirmation(comment.id)}
          narrow
        />
      ))
    ) : (
      <ZeroStateCommentsLens />
    );
  };

  return (
    <>
      <Box className={classes.containerCommentsLens}>
        {role?.category !== "subcontractor" && (
          <Box
            className={
              hasComments(workticketComments) && classes.containerSubTabs
            }
          >
            {
              <>
                <Tabs
                  value={subTab}
                  onChange={handleSubTabChange}
                  className={classes.activityTabRoot}
                >
                  {hasPermission(
                    permissionWorkticket.COMMENTS_INTERNAL,
                    permissions
                  ) && (
                    <Tab
                      label={`Internal`}
                      value={INTERNAL}
                      className={classes.activityTab}
                    />
                  )}
                  {hasPermission(
                    permissionWorkticket.COMMENTS_PARTNER,
                    permissions
                  ) && (
                    <Tab
                      label={`Partners`}
                      value={PARTNER}
                      className={classes.activityTab}
                    />
                  )}
                  {hasPermission(
                    permissionWorkticket.COMMENTS_CUSTOMER,
                    permissions
                  ) && (
                    <Tab
                      label={`Customers`}
                      value={CUSTOMER}
                      className={classes.activityTab}
                    />
                  )}
                </Tabs>
              </>
            }
          </Box>
        )}
        {!isLoadingComments ? (
          <>
            <Box className={classes.commentsContainerList}>
              <Box className={classes.commentsContainerListInner}>
                <Box className={classes.commentsContainerContent}>
                  {renderComments()}
                </Box>
                <Box className={classes.containerAddButton}>
                  <Input
                    className={classes.commentInputText}
                    type="text"
                    placeholder="Add a comment"
                    multiline
                    disableUnderline
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />

                  <IconButton
                    size="small"
                    style={{
                      color: comment.trim() !== "" ? "#4F98BC" : "#D9D9D9",
                    }}
                    onClick={handleAddComment}
                  >
                    <SendIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "1 1",
            }}
          >
            <LoadingStateHorizontal isVisible />
          </Box>
        )}
      </Box>

      <ConfirmDialog
        open={openConfirm}
        handleConfirm={() => handleDeleteComment(selectedComment)}
        handleClose={closeConfirm}
        message={"Are you sure you want to delete this comment?"}
        title={`Workticket ${workticketNumber}`}
      />
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
      />
    </>
  );
};

export default LensComments;
