import ServiceIcon from "assets/icons/serviceIcon";
import VisualInspectionIcon from "assets/icons/visualInspectionsIcon";

export const getServiceTypeDetails = (serviceType) => {
  switch (serviceType) {
    case 1:
    case null:
      return {
        icon: <ServiceIcon width={20} height={20} />,
        label: "Service",
      };
    case 2:
      return {
        icon: <VisualInspectionIcon width={20} height={20} />,
        label: "Inspection",
      };
    default:
      return null;
  }
};
