import React from "react";
import Button from "@material-ui/core/Button";
import {
  ThumbDown,
  ThumbDownFill,
  ThumbUp,
  ThumbUpFill,
} from "components/common/LunaLens/images/images";

import useStyles from "./styles";

const FeedbackButton = ({ type, filled, onHandleClick }) => {
  const classes = useStyles();

  return (
    <>
      {type === "up" ? (
        filled ? (
          <Button
            className={classes.buttonFeedbackFilled}
            onClick={onHandleClick}
          >
            <ThumbUpFill width={21} height={21} />
          </Button>
        ) : (
          <Button className={classes.buttonFeedback} onClick={onHandleClick}>
            <ThumbUp width={21} height={21} />
          </Button>
        )
      ) : filled ? (
        <Button
          className={classes.buttonFeedbackFilled}
          onClick={onHandleClick}
        >
          <ThumbDownFill width={21} height={21} />
        </Button>
      ) : (
        <Button className={classes.buttonFeedback} onClick={onHandleClick}>
          <ThumbDown width={21} height={21} />
        </Button>
      )}
    </>
  );
};

export default FeedbackButton;
