import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import DetailedAccordion from "./Profile/Contacts/GeographicalCoverage/GeographicalCoverage";
import { ContactPage } from "./Profile/Contacts/ContactPage";
import { FilterDialogProvider } from "./context/FilterDialogContext";
import { AddContactDialogProvider } from "./context/AddContactDialogContext";
import { GeographicalCoverageProvider } from "./context/GeographicalCoverageContext";
import GlobalUiContext from "contexts/globalUiContext";
import { usePartnerState } from "contexts/partnerContext";
import { permissionPartner, hasPermission } from "lib/permissions";
import useStyles from "./styles";

export function GeneralInformation() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { globalUi } = useContext(GlobalUiContext);
  const permissions = globalUi.permissions;
  const { partnerDetails } = usePartnerState();
  const history = useHistory();

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const handleEditClick = () => {
    handleMenuClose();
    history.push(`/partner/${partnerDetails?.id}/edit`);
  };

  const hasEditPermission = hasPermission(
    permissionPartner.PARTNER_MANAGE,
    permissions
  );

  return (
    <Box className={classes.generalInformationWrapper}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography className={classes.generalInformationSubHeader}>
          General Information
        </Typography>

        {hasEditPermission && (
          <>
            <Box
              onClick={handleMenuOpen}
              className={classes.iconButtonGeneralInformation}
            >
              <MoreHorizIcon />
            </Box>
            <Menu
              id="long-menu"
              keepMounted
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{ style: { width: 200 } }}
            >
              <MenuItem onClick={handleEditClick}>
                <Box className={classes.menuIcon}>
                  <EditIcon fontSize="inherit" />
                </Box>
                <Typography variant="caption" className={classes.menuText}>
                  Edit Partner Profile
                </Typography>
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
      <AddContactDialogProvider>
        <ContactPage />
      </AddContactDialogProvider>
      <GeographicalCoverageProvider>
        <FilterDialogProvider>
          <DetailedAccordion />
        </FilterDialogProvider>
      </GeographicalCoverageProvider>
    </Box>
  );
}
