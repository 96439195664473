import { useGeographicalCoverage } from "../../../context/GeographicalCoverageContext";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { logException } from "../../../../../components/util/logUtil";
import { updatePartner } from "../../../../../services/partners/partnerService";
import { useGetUserId } from "../../../context/UserIdContext";

export function useGeoCoverage() {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [geoAction, dispatchGeoDrawer] = useGeographicalCoverage();
  const { isDrawerOpen, isEditing, locationToEdit, isAdding, messageDialog } =
    geoAction;
  const { userId } = useGetUserId();
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleClick = (event, index) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOpenDrawer = () => {
    dispatchGeoDrawer({ type: "TOGGLE_DRAWER", open: true });
    handleClose();
  };

  const toggleAddGeo = (open, isAdding) => {
    dispatchGeoDrawer({ type: "TOGGLE_ADD", open: open, isAdding: isAdding });
  };

  const toggleMessageDialog = (dispatch, open, message) => {
    dispatch({
      type: "TOGGLE_MESSAGE_DIALOG",
      open,
      message,
    });
  };

  const handleEditDrawer = (location) => {
    dispatchGeoDrawer({
      type: "TOGGLE_EDIT",
      open: true,
      isEditing: true,
      location: location,
    });
    handleClose();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = () => () => {
    setExpanded(!expanded);
  };
  const [data, setData] = useState({
    zip_codes: "",
    city_serviced: "",
    states_serviced: "",
    // Add more fields if necessary
  });
  const [citiesList, setCitiesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [zipCodesList, setZipCodesList] = useState([]);
  const [filterCities, setFilterCities] = useState([]);
  const [filterStates, setFilterStates] = useState([]);
  const [deletedStates, setDeletedStates] = useState([]);
  const [filterZipCodes, setFilterZipCodes] = useState([]);

  useEffect(() => {
    if (isEditing) {
      // Mapping states to Edit
      const statesToSelect = [
        {
          value: locationToEdit.abbreviation,
          label: locationToEdit.name,
        },
      ];
      setStatesList(statesToSelect);

      //Mapping Cities To Edit
      const citiesToSelect = locationToEdit.cities.map((city) => ({
        value: city.id,
        label: city.city_name,
        state_abbreviation: city.state_abbreviation,
      }));
      setCitiesList(citiesToSelect);

      // Mapping Zip Codes To Edit
      const zipCodesToSelect = locationToEdit.zip_codes.map((zip) => {
        const city = citiesToSelect.find(
          (city) => parseInt(city.value) === parseInt(zip.city_id)
        );
        return {
          value: zip.id,
          label: zip.zip,
          city: city?.label,
          state_abbreviation: locationToEdit.abbreviation,
        };
      });
      setZipCodesList(zipCodesToSelect);
    }
  }, [isEditing, locationToEdit]);

  function submitEditing() {
    let cities_added = [];
    let zip_codes_added = [];
    let cities_deleted = [];
    let zip_codes_deleted = [];
    if (locationToEdit.cities.length > 0) {
      locationToEdit.cities.forEach((city) => {
        if (!filterCities.includes(city.id)) {
          cities_deleted.push(city.id);
        }
      });
      filterCities.forEach((city) => {
        if (!locationToEdit.cities.some((c) => c.id === city)) {
          cities_added.push(city);
        }
      });
    } else {
      cities_added = filterCities;
    }
    if (locationToEdit.zip_codes.length > 0) {
      locationToEdit.zip_codes.forEach((zip) => {
        if (!filterZipCodes.includes(zip.id)) {
          zip_codes_deleted.push(zip.id);
        }
      });
      filterZipCodes.forEach((zip) => {
        if (!locationToEdit.zip_codes.some((z) => z.id === zip)) {
          zip_codes_added.push(zip);
        }
      });
    } else {
      zip_codes_added = filterZipCodes;
    }

    const data = {
      cities_added,
      cities_deleted,
      zip_codes_added,
      zip_codes_deleted,
    };
    setLoading(true);
    geoMutation.mutate({
      partner_id: userId,
      data,
    });
  }

  function submitDelete(item) {
    const data = {
      states_deleted: [item.abbreviation],
      zip_codes_deleted: item.zip_codes.map((zip) => zip.id),
      cities_deleted: item.cities.map((city) => city.id),
    };
    geoMutation.mutate({
      partner_id: userId,
      data: data,
    });
  }

  function submitAdd() {
    const data = {
      states_added: filterStates,
      cities_added: filterCities,
      zip_codes_added: filterZipCodes,
    };
    setLoading(true);
    geoMutation.mutate({
      partner_id: userId,
      data: data,
    });
  }
  const queryClient = useQueryClient();

  const geoMutation = useMutation(updatePartner, {
    onSuccess: () => {
      queryClient.invalidateQueries("partnerGeo");
      if (isEditing) {
        dispatchGeoDrawer({
          type: "TOGGLE_EDIT",
          open: false,
          isEditing: false,
          locationToEdit: null,
        });
        toggleMessageDialog(
          dispatchGeoDrawer,
          true,
          "The Geographical Coverage has been successfully updated."
        );
      }
      if (isAdding) {
        dispatchGeoDrawer({
          type: "TOGGLE_ADD",
          open: false,
          isAdding: false,
        });
        toggleMessageDialog(
          dispatchGeoDrawer,
          true,
          "A new Geographical Coverage has been successfully added."
        );
      }
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
      toggleMessageDialog(dispatchGeoDrawer, false);
      logException(error, "Failed to update geo");
    },
  });

  return {
    geoAction,
    dispatchGeoDrawer,
    anchorEl,
    handleOpenDrawer,
    handleClick,
    isDrawerOpen,
    handleChange,
    expanded,
    setExpanded,
    handleClose,
    handleEditDrawer,
    data,
    setData,
    citiesList,
    setCitiesList,
    statesList,
    setStatesList,
    zipCodesList,
    setZipCodesList,
    filterCities,
    setFilterCities,
    filterStates,
    setFilterStates,
    filterZipCodes,
    filtersOpen,
    setFiltersOpen,
    deletedStates,
    setDeletedStates,
    submitEditing,
    setFilterZipCodes,
    submitDelete,
    toggleAddGeo,
    submitAdd,
    loading,
    setLoading,
    geoMutation,
    messageDialog,
  };
}
