import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5,
    zIndex: 100,
    background: "#ffffff",
  },
  iconClose: {
    color: "#979797",
    width: 18,
    height: 18,
  },
}));

export const DialogCloseIcon = (props) => {
  const classes = useStyles();

  const { handleClose } = props;

  return (
    <IconButton
      aria-label="close"
      onClick={handleClose}
      className={classes.wrapperClose}
    >
      <CloseIcon className={classes.iconClose} />
    </IconButton>
  );
};
