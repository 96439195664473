import React, { useState, useEffect } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import FrameIcon from "assets/icons/frameIcon";
import ThumbUpAltIcon from "assets/icons/handThumbUp";
import ThumbDownAltIcon from "assets/icons/handThumbDown";
import { LunaLogo } from "assets/icons/lunaLogo";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import MessageDialog from "components/ui/dialog/messageDialog";
import { logException } from "components/util/logUtil";
import { getReasonFeedback, storePartnerFeedback } from "services/aiServices";
import useStyles from "./styles";

const validateFields = (reason, comment) => {
  const errors = {};
  const validationRules = {
    reason: !reason.length,
    comment: !comment,
  };
  Object.keys(validationRules).forEach((key) => {
    if (validationRules[key]) {
      errors[key] = validationRules[key];
    }
  });
  return errors;
};

const SearchFeedback = (props) => {
  const classes = useStyles();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [selectedFeedbackType, setSelectedFeedbackType] = useState(null);
  const [isFeedbackLoading, setIsFeedbackLoading] = useState(true);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isLoadingDialogVisible, setIsLoadingDialogVisible] = useState(false);
  const [feedbackReasonOptions, setFeedbackReasonOptions] = useState([]);
  const [selectedFeedbackReasons, setSelectedFeedbackReasons] = useState([]);
  const [feedbackComment, setFeedbackComment] = useState("");
  const [isReasonError, setIsReasonError] = useState(false);
  const [isCommentError, setIsCommentError] = useState(false);
  const { lunaSearchData } = props;
  const { runId, userId, userEmail, prompt } = lunaSearchData;

  useEffect(() => {
    const fetchFeedbackOptions = async () => {
      try {
        setIsFeedbackLoading(true);
        const { data } = await getReasonFeedback();
        setFeedbackReasonOptions(
          data.reasons.map(({ id, name }) => ({ id, label: name }))
        );
      } catch (error) {
        logException(error, "Failed to fetch feedback options");
      } finally {
        setIsFeedbackLoading(false);
      }
    };

    fetchFeedbackOptions();
  }, []);

  const handleCloseModal = () => {
    setIsFeedbackModalOpen(false);
    setSelectedFeedbackType(null);
    setSelectedFeedbackReasons([]);
    setFeedbackComment("");
    setIsReasonError(false);
    setIsCommentError(false);
  };

  const handleFeedbackChange = (event, newValue) => {
    setSelectedFeedbackReasons(newValue.map((r) => r.value) || []);
    setIsReasonError(false);
  };

  const handleFeedbackClick = (type) => {
    setSelectedFeedbackType(type);
    setIsFeedbackModalOpen(true);
  };

  const handleThumbUpClick = async () => {
    const feedbackData = createFeedbackData(1);
    setIsLoadingDialogVisible(true);

    try {
      await storePartnerFeedback(feedbackData);

      setIsLoadingDialogVisible(false);
      handleCloseModal();
      setIsSuccessDialogOpen(true);
    } catch (error) {
      logException(error, "Failed to submit feedback");
    }
  };

  const handleConfirm = async () => {
    const errors = validateFields(selectedFeedbackReasons, feedbackComment);
    if (errors.reason || errors.comment) {
      setIsReasonError(!!errors.reason);
      setIsCommentError(!!errors.comment);
      return;
    }

    setIsLoadingDialogVisible(true);
    const feedbackData = createFeedbackData(selectedFeedbackType, true);

    try {
      await storePartnerFeedback(feedbackData);

      setIsLoadingDialogVisible(false);
      handleCloseModal();
      setIsSuccessDialogOpen(true);
    } catch (error) {
      logException(error, "Failed to submit feedback");
    }
  };

  const createFeedbackData = (score, requiredComment = false) => {
    const formattedReasons = selectedFeedbackReasons.map((id) => {
      const reason = feedbackReasonOptions.find((option) => option.id === id);
      return `${id},${reason.label}`;
    });

    return {
      feedback_date: new Date().toISOString(),
      feedback_score: score,
      feedback_comment: requiredComment
        ? feedbackComment.trim()
        : feedbackComment,
      feedback_reasons: formattedReasons,
      run_id: runId,
      user_id: userId,
      user_email: userEmail,
      prompt: prompt,
    };
  };

  const closeSuccessDialog = () => {
    setIsSuccessDialogOpen(false);
  };

  return (
    <Box className={classes.container}>
      {props.isNoResults ? (
        <>
          <Box className={classes.zeroStateContainer}>
            <FrameIcon className={classes.frameIcon} />
            <Typography variant="caption" className={classes.textNoSearch}>
              No Search Results
            </Typography>
            <Typography variant="caption" className={classes.textDetail}>
              Try to be more specific with your request and be
            </Typography>
            <Typography
              variant="caption"
              className={classes.textDetail}
              style={{
                paddingTop: 5,
              }}
            >
              as clear as possible with what you are looking for.
            </Typography>

            <Box className={classes.containerSectionFeedbackButtons}>
              <Typography variant="caption" className={classes.textFeedback}>
                Give feedback on Luna
              </Typography>
              <Box className={classes.containerFeedbackButtonNoResults}>
                <IconButton
                  className={classes.feedbackButton}
                  onClick={handleThumbUpClick}
                  style={{ marginRight: 8, marginLeft: 10 }}
                >
                  <ThumbUpAltIcon />
                </IconButton>
                <IconButton
                  className={classes.feedbackButton}
                  onClick={() => handleFeedbackClick(0)}
                >
                  <ThumbDownAltIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </>
      ) : (
        <Box className={classes.containerFeedbackButtonSearch}>
          <IconButton
            className={classes.feedbackButton}
            onClick={handleThumbUpClick}
            style={{ marginRight: 8, marginLeft: 10 }}
          >
            <ThumbUpAltIcon />
          </IconButton>
          <IconButton
            className={classes.feedbackButton}
            onClick={() => handleFeedbackClick(0)}
          >
            <ThumbDownAltIcon />
          </IconButton>
        </Box>
      )}

      <Dialog
        open={isLoadingDialogVisible}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogContent>
          <LoadingIndicator />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isFeedbackModalOpen && !isLoadingDialogVisible}
        onClose={handleCloseModal}
        aria-labelledby="feedback-modal-title"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle className={classes.wrapperDialog}>
          <Box className={classes.wrapperTitle}>
            <Box display="flex" alignItems="center">
              <LunaLogo />
              <Typography
                variant="caption"
                gutterBottom
                className={classes.titleDialog}
              >
                Luna Feedback
              </Typography>
            </Box>
            <IconButton
              aria-label="close"
              onClick={handleCloseModal}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          {isFeedbackLoading ? (
            <LinearProgress color="secondary" />
          ) : (
            <Box className={classes.selectContainer}>
              <FormSelectAuto
                multiple
                name="tellUsMore"
                label="Tell us more"
                placeholder="Select Reasons"
                noTruncate
                options={feedbackReasonOptions.map((option) => ({
                  value: option.id,
                  label: option.label,
                }))}
                value={feedbackReasonOptions
                  .filter((option) =>
                    selectedFeedbackReasons.includes(option.id)
                  )
                  .map((option) => ({
                    value: option.id,
                    label: option.label,
                  }))}
                handleChange={handleFeedbackChange}
                error={
                  isReasonError
                    ? [
                        {
                          key: "tellUsMore",
                          message: "At least one feedback reason is required",
                        },
                      ]
                    : []
                }
              />
            </Box>
          )}
          <Box className={classes.selectContainer}>
            <FormControl fullWidth>
              <TextField
                multiline
                id="comment"
                size="small"
                variant="standard"
                name="comment"
                placeholder="Write here ..."
                minRows={5}
                onChange={(event) => {
                  setFeedbackComment(event.target.value);
                  setIsCommentError(false);
                }}
                value={feedbackComment}
                error={isCommentError}
                InputProps={{ disableUnderline: true }}
                className={classes.textArea}
              />
              {isCommentError && (
                <FormHelperText className={classes.wrapperError} error>
                  Comment is required
                </FormHelperText>
              )}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions className={classes.actionItemButton}>
          <Button onClick={handleCloseModal} className={classes.buttonCancel}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            className={classes.buttonConfirm}
          >
            Send Feedback
          </Button>
        </DialogActions>
      </Dialog>

      <MessageDialog
        open={isSuccessDialogOpen}
        handleClose={closeSuccessDialog}
        title="Success"
        message="Feedback has been successfully submitted."
      />
    </Box>
  );
};

export default SearchFeedback;
