import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100%",
    display: "flex",
    maxWidth: "100%",
  },
  rootContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 120px)",
    },
  },
  tableContainer: {
    display: "flex",
    flexGrow: 1,
    height: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  breadcrumbLink: {
    fontSize: "14px",
    color: "#c4c4c4",
  },
  header: {
    marginBottom: 10,
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  headerLink: {
    color: "#4f98bc",
    fontWeight: "bold",
  },
  breadcrumbs: {
    border: "none",
    backgroundColor: "transparent",
  },
  loading: {
    textAlign: "center",
    margin: "40px 0",
    padding: "60px 20px",
    background: "#f4f4f4",
    borderRadius: "10px",
  },
  loadingTable: {
    width: "97%",
    bottom: 0,
    position: "absolute",
  },
  contentCounter: {
    right: 88,
    position: "absolute",
    zIndex: 2,
    marginTop: 12,
    "& .MuiChip-outlined": {
      border: "none",
    },
    "& .MuiChip-label": {
      paddingRight: 0,
      fontWeight: "bold",
    },
    [theme.breakpoints.down("sm")]: {
      // left: 20,
      // top: 185,
      // position: "relative",
      position: "static",
      textAlign: "right",
      margin: 0,
      height: 30,
    },
  },
  tablerow: {
    "& > td": {
      borderBottom: "solid 1px rgba(0, 0, 0, 0.05)",
    },
  },
  fullHeightTable: {
    flex: "1 1",
    display: "flex",
    maxHeight: "85%",
    maxWidth: "100%",
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "98%",
    },
  },
  tableRightPad: {
    marginRight: 94,
  },
  type: {
    width: "110px !important",
    height: "auto !important",
    borderRadius: "3px !important",
    color: "#fff !important",
    fontSize: "10px !important",
    "& > span": {
      padding: "3px 15px !important",
      width: "100%",
      textAlign: "center",
      fontWeight: "700",
    },
    "& > svg": {
      color: "#fff !important",
    },
  },
  typeIcon: {
    fontSize: "20px !important",
    marginTop: "4px",
    marginBottom: "4px",
  },
  submitted: {
    minWidth: "60px !important",
    height: "auto !important",
    borderRadius: "3px !important",
    padding: 0,
    fontSize: "12px !important",
    color: "#78c1ce",
    borderColor: "#78c1ce",
    "& > span": {
      padding: "0px 15px !important",
      fontWeight: "700",
    },
    "& > svg": {
      color: "#78c1ce !important",
    },
  },
  logo: {
    height: "26px",
  },
  wrapperSuccess: {
    textAlign: "center",
  },
  wrapperActionSuccess: {
    textAlign: "center",
    justifyContent: "center !important",
    marginBottom: 20,
  },
  titleSuccess: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 5,
  },
  contentSuccess: {
    fontSize: 14,
  },
  iconSuccess: {
    color: "#328914",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  iconItem: {
    color: "#747474",
    width: 44,
    height: 44,
    marginBottom: 5,
  },
  wrapperClose: {
    position: "absolute",
    right: 5,
    top: 5,
  },
  iconClose: {
    color: "#979797",
    width: 20,
    height: 20,
  },
  buttonBegin: {
    textTransform: "Capitalize",
    backgroundColor: "#4f97bb",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  buttonCancel: {
    textTransform: "Capitalize",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 50,
      paddingRight: 50,
    },
  },
  buttonAction: {
    textTransform: "Capitalize",
    color: "#ffffff",
  },
  buttonReject: {
    backgroundColor: "#f23955",
    "&:hover": {
      backgroundColor: "#e53550",
    },
  },
  buttonRQuote: {
    backgroundColor: "#328914",
    "&:hover": {
      backgroundColor: "#308014",
    },
  },
  buttonRTicket: {
    backgroundColor: "#4f98bc",
    "&:hover": {
      backgroundColor: "#4587a7",
    },
  },
  generalWrapper: {
    display: "flex",
    margin: "auto",
  },
  pageBodyWrapper: {
    display: "flex",
    maxHeight: "100%",
    height: "100%",
    alignItems: "stretch",
    [theme.breakpoints.up("sm")]: {
      marginRight: 70,
    },
  },
  contentContainer: {
    padding: theme.spacing(1),
    flex: "1 1",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    width: 1,
    [theme.breakpoints.up("sm")]: {
      padding: "0 20px",
    },
  },

  detailRoot: {
    maxHeight: "100%",
    height: "100%",
    position: "relative",
    marginRight: theme.spacing(2),
    minWidth: 400,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
  detailWrapper: {
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(2),
    paddingLeft: 0,
    height: "75%",
    width: "100%",
    overflowY: "scroll",
    maxWidth: 400,
    "&::-webkit-scrollbar": {
      width: "0.4em",
      backgroundColor: "#d4d4d4",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#8a8c8e",
      borderRadius: 5,
      outline: "1px solid slategrey",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      minWidth: "100%",
    },
  },
  hasClose: {
    paddingRight: 50,
  },
  detailActionWrapper: {
    backgroundColor: "#f8f8f8",
    boxShadow: "0 0 4px 0 #dfdfdf",
    bottom: 0,
    position: "sticky",
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& > button": {
      margin: "0 5px",
      flexGrow: 1,
    },
    padding: "10px 5px",
  },
  detailTabbed: {
    borderTop: "1px solid #ececec",
    paddingTop: 20,
  },
  detailActivity: {
    marginTop: 15,
    minHeight: 174,
  },
  detailDocument: {
    marginTop: 15,
    minHeight: 80,
  },
  activityLine: {
    fontSize: 14,
  },
  activityIcon: {
    marginRight: 5,
    top: 5,
    position: "relative",
  },
  activityTabRoot: {
    minHeight: 25,
  },
  activityTab: {
    padding: 0,
    margin: 0,
    marginRight: 20,
    minWidth: 60,
    textTransform: "capitalize",
    minHeight: 25,
    fontSize: 14,
    "&.Mui-selected": {
      color: theme.palette.secondary.main,
      fontWeight: "bold",
    },
  },
  descriptionTooltip: {
    textTransform: "none",
    textAlign: "left",
    minWidth: 0,
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
  newLabel: {
    marginLeft: 5,
    fontSize: 16,
    color: "#9ABC66",
  },
  newIndicator: {
    position: "relative",
    top: 5,
    fontSize: 20,
    color: "#9ABC66",
  },
  newLabel3: {
    marginLeft: 5,
    fontSize: 16,
    color: "#4f98bc",
  },
  newIndicator3: {
    position: "relative",
    top: 5,
    fontSize: 20,
    color: "#4f98bc",
  },
  newLabel2: {
    marginLeft: 5,
    fontSize: 16,
    color: "#328914",
  },
  newIndicator2: {
    position: "relative",
    top: 5,
    fontSize: 20,
    color: "#328914",
  },
  newLabel5: {
    marginLeft: 5,
    fontSize: 16,
    color: theme.palette.primary.inverted,
  },
  newIndicator5: {
    position: "relative",
    top: 5,
    fontSize: 20,
    color: theme.palette.primary.inverted,
  },
  newLabel0: {
    marginLeft: 5,
    fontSize: 16,
    color: "#f23955",
  },
  newIndicator0: {
    position: "relative",
    top: 5,
    fontSize: 20,
    color: "#f23955",
  },
  newLabel4: {
    marginLeft: 5,
    fontSize: 16,
    color: "#328914",
  },
  newIndicator4: {
    position: "relative",
    top: 5,
    fontSize: 20,
    color: "#328914",
  },
  newLabel1: {
    marginLeft: 5,
    fontSize: 16,
    color: "#328914",
  },
  newIndicator1: {
    position: "relative",
    top: 5,
    fontSize: 20,
    color: "#328914",
  },
  wrapperContainer: {
    display: "flex",
    flex: "1 1",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  containerUploadFile: {
    textAlign: "right",
  },
  buttonUpload: {
    textTransform: "Capitalize",
    width: 160,
    marginTop: 10,
  },
  centerLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default useStyles;
