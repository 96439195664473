import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment-timezone";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { fmPilotExpires, scExpires } from "constants.js";
import { formatAppDateTZ } from "components/util/timeFormat";
import useStyles from "./styles";

const WorkticketRequestStatus = (props) => {
  const classes = useStyles();
  const { category, status, dateCreated, timezone, isApproved, dateFinal } =
    props;
  const [timeText, setTimeText] = useState("");
  const [chipClass, setChipClass] = useState(classes.chipJustReceived);
  const specificDate = moment(dateCreated).tz(timezone);

  useEffect(() => {
    if ([8].includes(parseInt(category)) && parseInt(status) === 6) {
      if (specificDate && timezone) {
        const timer = setInterval(() => {
          const now = moment().tz(timezone);
          const diffInSeconds = now.diff(specificDate, "seconds");
          let displayText = "";
          let clChip = classes.chipJustReceived;
          if (diffInSeconds >= fmPilotExpires * 60) {
            clearInterval(timer);
            displayText = "Expired";
            clChip = classes.chipExpired;
          } else if (diffInSeconds < 60) {
            displayText = "Just Received";
            setChipClass(classes.chipJustReceived);
          } else if (diffInSeconds < fmPilotExpires * 60) {
            const duration = moment.duration(diffInSeconds, "seconds");
            const minutes = duration.minutes();
            const seconds = duration.seconds();
            displayText = `${
              30 - (seconds === 0 ? minutes - 1 : minutes)
            }:${(seconds === 0 ? 0 : 60 - seconds)
              .toString()
              .padStart(2, "0")} mins left`;
            clChip = classes.chipMinsLeft;
          } else {
            clearInterval(timer);
            displayText = "Just Received";
          }
          setTimeText(displayText);
          setChipClass(clChip);
        }, 1000);

        return () => clearInterval(timer);
      }
    } else if ([9].includes(parseInt(category)) && parseInt(status) === 6) {
      // Corrigo
      if (specificDate && timezone) {
        const dateToAccept = moment(dateFinal).tz(timezone);
        const timer = setInterval(() => {
          const now = moment().tz(timezone);
          const diffInSeconds = now.diff(specificDate, "seconds");
          const secondsToExpire = dateToAccept.diff(specificDate, "seconds");
          let displayText = "";
          let clChip = classes.chipJustReceived;
          if (diffInSeconds >= secondsToExpire) {
            clearInterval(timer);
            displayText = "Expired";
            clChip = classes.chipExpired;
          } else if (diffInSeconds < 60) {
            displayText = "Just Received";
            setChipClass(classes.chipJustReceived);
          } else if (diffInSeconds < secondsToExpire) {
            const pendingTime = secondsToExpire - diffInSeconds;
            const duration = moment.duration(pendingTime, "seconds");
            const minutes = duration.minutes();
            const seconds = duration.seconds();
            const hours = duration.hours();
            const days = duration.days();
            if (days > 1) {
              displayText = `${days} days left`;
            } else if (days === 1) {
              displayText = `${days} day left`;
            } else if (hours > 0) {
              displayText = `${hours}:${minutes
                .toString()
                .padStart(2, "0")} hours left`;
            } else if (minutes > 0) {
              displayText = `${minutes}:${seconds
                .toString()
                .padStart(2, "0")} mins left`;
            } else {
              displayText = `${minutes}:${seconds
                .toString()
                .padStart(2, "0")} secs left`;
            }
            clChip = classes.chipMinsLeft;
          } else {
            clearInterval(timer);
            displayText = "Just Received";
          }
          setTimeText(displayText);
          setChipClass(clChip);
        }, 1000);

        return () => clearInterval(timer);
      }
    } else if ([8, 9].includes(parseInt(category))) {
      if (isApproved) {
        setTimeText("Accepted");
        setChipClass(classes.chipAccepted);
      } else {
        setTimeText("Pending Approval");
        setChipClass(classes.pendingApproval);
      }
    } else if (parseInt(category) === 7 && [0, 6].includes(parseInt(status))) {
      const specificDateSC = formatAppDateTZ(
        dateCreated,
        "YYYY-MM-DD HH:mm:ss",
        timezone
      );
      // Timer for service channel
      if (specificDateSC && timezone) {
        const timer = setInterval(() => {
          const now = moment().tz(timezone);
          const diffInSeconds = now.diff(specificDateSC, "seconds");
          let displayText = "";
          let clChip = classes.chipJustReceived;
          if (diffInSeconds >= scExpires * 60) {
            clearInterval(timer);
            displayText = "Expired";
            clChip = classes.chipExpired;
          } else if (diffInSeconds < 60) {
            displayText = "Just Received";
            setChipClass(classes.chipJustReceived);
          } else if (diffInSeconds < scExpires * 60) {
            const pendingTime = scExpires * 60 - diffInSeconds;
            const duration = moment.duration(pendingTime, "seconds");
            const minutes = Math.floor(duration.asMinutes());
            const seconds = duration.seconds();

            if (minutes < 60) {
              displayText = `${minutes}:${seconds
                .toString()
                .padStart(2, "0")} mins left`;
            } else {
              const hours = Math.floor(minutes / 60);
              const remainingMinutes = minutes % 60;
              displayText = `${hours}:${remainingMinutes
                .toString()
                .padStart(2, "0")} hours left`;
            }
            clChip = classes.chipMinsLeft;
          } else {
            clearInterval(timer);
            displayText = "Just Received";
          }
          setTimeText(displayText);
          setChipClass(clChip);
        }, 1000);

        return () => clearInterval(timer);
      }
      setChipClass(classes.chipAccepted);
    } else if (parseInt(category) === 7 && parseInt(status) === 1) {
      setTimeText("Accepted");
      setChipClass(classes.chipAccepted);
    } else {
      setTimeText("Declined");
      setChipClass(classes.chipExpired);
    }
  }, [
    dateCreated,
    category,
    specificDate,
    timezone,
    classes.chipJustReceived,
    classes.chipMinsLeft,
    classes.chipExpired,
    classes.chipAccepted,
    classes.pendingApproval,
    status,
    isApproved,
    dateFinal,
  ]);

  return (
    <Box className={classNames(classes.root, chipClass)}>
      <Typography variant="body2" noWrap style={{ color: "#EA5168" }}>
        {timeText}
      </Typography>
    </Box>
  );
};

export default WorkticketRequestStatus;
