import React from "react";
import { Box } from "@material-ui/core/";
import AnalysisStatusResume from "./analysisStatusResume";
import useStyles from "./styles";

const AnalysisStatusResumeList = ({
  analysis,
  onCardClick,
  onHistoryOptionClick,
  userId,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.statusResumeListContainer}>
      {analysis.map((analysisDetail) => (
        <AnalysisStatusResume
          key={analysisDetail.id}
          analysis={analysisDetail}
          onCardClick={onCardClick}
          handleOptionClick={onHistoryOptionClick}
          userId={userId}
        />
      ))}
    </Box>
  );
};

export default AnalysisStatusResumeList;
