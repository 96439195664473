import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ImageGallery from "./gallery/imageGallery";

import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";

const AnalysisListHistory = ({
  analysis,
  background,
  resume,
  edit,
  indexCategory,
  deleted = false,
}) => {
  const classes = useStyles();
  const updateDescription = useLunaLensStore(
    (state) => state.updateDescription
  );
  const updateDescriptionEdit = useLunaLensStore(
    (state) => state.updateDescriptionEdit
  );
  const addTradeEdit = useLunaLensStore((state) => state.addTradeEdit);

  const onHandleAddTradeEdit = () => {
    addTradeEdit(indexCategory, null, "");
  };

  return (
    <Box
      className={classes.listContainer}
      style={{ backgroundColor: background ? "#F8F8F8" : "white" }}
    >
      <List>
        {resume ? (
          <ListItem>
            <Box className={classes.listItemContainer}>
              <ListItemIcon className={classes.listItemIcon}>
                <FiberManualRecordIcon className={classes.listRecordIcon} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <>
                    <Typography
                      component="span"
                      variant="body1"
                      className={classes.listSpan}
                    >
                      {analysis[0]?.title && `${analysis[0]?.title}: `}
                    </Typography>
                    <Typography
                      component="span"
                      variant="body1"
                      style={{ whiteSpace: "pre-line" }}
                    >
                      {analysis[0]?.description?.length > 180
                        ? analysis[0]?.description.slice(0, 180) + "..."
                        : analysis[0]?.description}
                    </Typography>
                  </>
                }
              />
            </Box>
          </ListItem>
        ) : (
          analysis.map((a, index) =>
            !edit && !a.edit ? (
              <ListItem key={index}>
                <Box>
                  <Box className={classes.listItemContainer}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <FiberManualRecordIcon
                        className={classes.listRecordIcon}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <>
                          {a?.title && (
                            <Typography
                              component="span"
                              variant="body1"
                              className={classes.listSpan}
                              style={
                                deleted
                                  ? { textDecoration: "line-through" }
                                  : {}
                              }
                            >
                              {`${a?.title
                                .split("_")
                                .map(
                                  (word) =>
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                )
                                .join(" ")}: `}
                            </Typography>
                          )}
                          <Typography
                            component="span"
                            variant="body1"
                            style={
                              deleted
                                ? {
                                    textDecoration: "line-through",
                                    whiteSpace: "pre-line",
                                  }
                                : { whiteSpace: "pre-line" }
                            }
                          >
                            {a?.description}
                          </Typography>
                        </>
                      }
                    />
                  </Box>
                  <Box style={{ margin: "12px 0px" }}>
                    <ImageGallery
                      imageUrls={a?.luna_lens_trade_media?.map((item) => ({
                        id: item?.file.id,
                        url: item?.file.file_url,
                        mime_type: 2,
                      }))}
                      numberImages={14}
                      viewOnly={true}
                      title={a.title}
                    />
                  </Box>
                </Box>
              </ListItem>
            ) : (
              <React.Fragment key={index}>
                {a.title && (
                  <Typography className={classes.listSpan}>{`${a.title
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}: `}</Typography>
                )}
                <TextField
                  className={classes.textArea}
                  value={a.description}
                  onChange={(e) => {
                    if (a.edit) {
                      updateDescriptionEdit(
                        indexCategory,
                        index,
                        e.target.value
                      );
                    } else {
                      updateDescription(indexCategory, index, e.target.value);
                    }
                  }}
                  placeholder="Write here..."
                  multiline
                  minRows={4}
                  maxRows={4}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                />
              </React.Fragment>
            )
          )
        )}
      </List>
      {!resume && analysis[0].edit && (
        <Button
          className={classes.buttonHeader}
          style={{ marginBottom: "10px" }}
          onClick={() => {
            onHandleAddTradeEdit();
          }}
        >
          <AddIcon style={{ color: "#4F98BC" }} />
        </Button>
      )}
    </Box>
  );
};

export default AnalysisListHistory;
