import React, { useContext, Fragment } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import EditIcon from "@material-ui/icons/Edit";
import ThreeDots from "../icons/ThreeDots";
import { ArrowUp } from "../icons/ArrowUp";
import { ArrowDown } from "../icons/ArrowDown";
import { DeleteBin } from "pages/partnerPage/icons/DeleteBin";
import { AddQuestionDialog } from "./AddQuestionDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import DeleteQuestionDialogue from "./DeleteQuestionDialogue";
import { useAddQuestionModal } from "../context/AddQuestionDialogContext";
import { useQuestionsLogic } from "./useQuestions";
import GlobalUiContext from "contexts/globalUiContext";
import { hasPermission, permissionPartner } from "lib/permissions";
import * as classnames from "classnames";
import useStyles from "../styles";

export function Questions() {
  const classes = useStyles();
  const { globalUi } = useContext(GlobalUiContext);
  const { role, permissions } = globalUi;
  const { handleDialogueOpen } = useAddQuestionModal();
  const {
    data,
    expanded,
    anchorEls,
    editIndex,
    setEditIndex,
    loadingState,
    handleChange,
    handleClose,
    handleClick,
    handleEditClick,
    handleTitleChange,
    handleBodyChange,
    handleOnDelete,
    handleEditQuestion,
    handleUploadAnswer,
    isLoading,
    isDeleteQuestionDialogueOpen,
    setIsDeleteQuestionDialogueOpen,
    handleCloseOnDelete,
    deleteQuestionId,
    setDeleteQuestionId,
    userId,
    handleEditingQuestionAnswer,
  } = useQuestionsLogic();

  const menuOptions = [
    {
      label: "Edit",
      icon: <EditIcon fontSize="inherit" />,
      permission: true,
      onClick: (index) => {
        //check if already expanded first if expanded dont call handleChange
        if (!expanded[index]) {
          handleChange(index);
        }
        handleEditClick(index);
      },
    },
    {
      label: "Delete",
      icon: <DeleteBin fontSize="inherit" />,
      permission: permissionPartner.PARTNER_MANAGE,
      onClick: (index, question) => {
        handleCloseOnDelete(index);
        setIsDeleteQuestionDialogueOpen(true);
        setDeleteQuestionId(question.id);
      },
    },
  ];

  const userHasPermission = (permission) =>
    hasPermission(permission, permissions);

  const filteredMenuOptions = menuOptions.filter((option) =>
    userHasPermission(option.permission)
  );
  // const filteredMenuOptions = menuOptions;

  const handleMenuItemClick = (index, onClick, question) => (event) => {
    event.stopPropagation();
    onClick(index, question);
    handleClose(index)();
  };

  return (
    <Fragment>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Fragment>
          <Box className={classes.questionsTitleContainer}>
            <Typography className={classes.questionsSubheader}>
              Questions
            </Typography>
            {userHasPermission(permissionPartner.PARTNER_MANAGE) && (
              <Box className={classes.addContactButtonWrapper}>
                <Button
                  onClick={handleDialogueOpen}
                  className={classes.addContactButton}
                >
                  Add New Question
                </Button>
              </Box>
            )}
          </Box>
          <Box className={classes.questionsFlexContainer}>
            {data?.map((question, index) => (
              <Fragment key={index}>
                <Accordion
                  elevation={0}
                  classes={{ root: classes.questionAccordionRoot }}
                  expanded={expanded[index] === true}
                  onChange={() => handleChange(index)}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    style={{
                      backgroundColor: "transparent", // Keeps it transparent in summary as well
                    }}
                  >
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      classes={{ root: classes.questionsListMenuButton }}
                      className={`${classes.questionsListMenu} ${
                        expanded[index] ? classes.questionsListMenuExpanded : ""
                      }`}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClick(event, index);
                      }}
                      disableRipple={true}
                      onFocus={(event) => event.stopPropagation()}
                    >
                      <ThreeDots />
                    </IconButton>
                    {/*{userHasPermission(permissionPartner.PARTNER_MANAGE) && (*/}
                    {/*  <IconButton*/}
                    {/*    aria-label="more"*/}
                    {/*    aria-controls="long-menu"*/}
                    {/*    aria-haspopup="true"*/}
                    {/*    classes={{ root: classes.questionsListMenuButton }}*/}
                    {/*    className={`${classes.questionsListMenu} ${*/}
                    {/*      expanded[index]*/}
                    {/*        ? classes.questionsListMenuExpanded*/}
                    {/*        : ""*/}
                    {/*    }`}*/}
                    {/*    onClick={(event) => {*/}
                    {/*      event.stopPropagation();*/}
                    {/*      handleClick(event, index);*/}
                    {/*    }}*/}
                    {/*    disableRipple={true}*/}
                    {/*    onFocus={(event) => event.stopPropagation()}*/}
                    {/*  >*/}
                    {/*    <ThreeDots />*/}
                    {/*  </IconButton>*/}
                    {/*)}*/}
                    {expanded[index] ? (
                      <Box
                        className={`${classes.arrowDownQuestionTest} ${
                          editIndex === index
                            ? classes.arrowDownQuestionEdit
                            : classes.arrowDownQuestion
                        }`}
                      >
                        <ArrowUp />
                      </Box>
                    ) : (
                      <Box
                        className={`${classes.trueArrowDownEdit} ${
                          editIndex === index
                            ? classes.arrowDownQuestionEdit
                            : classes.arrowDownQuestion
                        }`}
                      >
                        <ArrowDown />
                      </Box>
                    )}
                    {editIndex === index &&
                    userHasPermission(permissionPartner.PARTNER_MANAGE) ? (
                      <Box className={classes.changeQuestionTitleContainer}>
                        <Box className={classes.questionEditingContainer}>
                          <Typography>Question</Typography>
                          <TextField
                            classes={{ root: classes.questionTitleEdit }}
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            onChange={(e) =>
                              handleTitleChange(
                                index,
                                e.target.value,
                                question.id
                              )
                            }
                            autoFocus={true}
                            InputProps={{ disableUnderline: true }}
                            defaultValue={question.question.question}
                            multiline
                            minRows={1}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        className={classnames(
                          classes.changeQuestionTitleContainer,
                          classes.textOverflow
                        )}
                      >
                        <Typography className={classes.questionTitle}>
                          {question.question.question}
                        </Typography>
                      </Box>
                    )}
                  </AccordionSummary>
                  {filteredMenuOptions.length > 0 && (
                    <Menu
                      id={`simple-menu-${index}`}
                      anchorEl={anchorEls[index]}
                      keepMounted
                      open={Boolean(anchorEls[index])}
                      onClose={() => handleClose(index)()}
                    >
                      {filteredMenuOptions.map((option, menuIndex) => (
                        <MenuItem
                          key={menuIndex}
                          disabled={loadingState}
                          onClick={handleMenuItemClick(
                            index,
                            option.onClick,
                            question
                          )}
                        >
                          <Box className={classes.menuIcon}>{option.icon}</Box>
                          <Typography
                            variant="caption"
                            className={classes.menuText}
                          >
                            {option.label}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  )}
                  <AccordionDetails
                    className={classes.accordionDetailsContainer}
                  >
                    {editIndex !== index &&
                      (question?.answer?.length > 0 ? (
                        <Typography className={classes.questionBody}>
                          {question.answer}
                        </Typography>
                      ) : (
                        <>
                          <TextareaAutosize
                            minRows={6}
                            defaultValue={question.answer}
                            onChange={(event) => handleBodyChange(index, event)}
                            maxRows={8}
                            className={classes.bodyTextarea}
                            aria-label="maximum height"
                          />
                          <Box className={classes.addAnswerButtonWrapper}>
                            <Button
                              disabled={loadingState}
                              onClick={() => handleUploadAnswer(question)}
                              className={classes.addAnswerButton}
                            >
                              Upload Answer
                            </Button>
                          </Box>
                        </>
                      ))}
                    {editIndex === index && (
                      <>
                        <Box className={classes.questionEditingContainer}>
                          <Typography>Answer</Typography>
                          <TextareaAutosize
                            minRows={6}
                            defaultValue={question.answer}
                            onChange={(event) => handleBodyChange(index, event)}
                            maxRows={8}
                            className={classes.editingbodyTextarea}
                            aria-label="maximum height"
                          />
                        </Box>

                        <Box
                          className={classes.formQuestionTitleButtonContainer}
                        >
                          <Button
                            disabled={loadingState}
                            onClick={(event) => {
                              event.stopPropagation();
                              setEditIndex(null);
                            }}
                            className={classes.cancelButton}
                          >
                            Cancel
                          </Button>
                          <Button
                            disabled={loadingState}
                            onClick={(event) => {
                              event.stopPropagation();
                              // handleEditQuestion(index, question);
                              const hasPermissions = userHasPermission(
                                permissionPartner.PARTNER_MANAGE
                              );
                              handleEditingQuestionAnswer(
                                question,
                                index,
                                hasPermissions
                              );
                            }}
                            className={classes.buttonConfirm}
                          >
                            Save
                          </Button>
                        </Box>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              </Fragment>
            ))}
          </Box>
          <DeleteQuestionDialogue
            isDeleteQuestionDialogueOpen={isDeleteQuestionDialogueOpen}
            handleDeleteUserDialogueClose={() =>
              setIsDeleteQuestionDialogueOpen(false)
            }
            resetDeleteQuestionId={() => setDeleteQuestionId(null)}
            handleDeleteUser={() => handleOnDelete(deleteQuestionId)}
          />
          <AddQuestionDialog />
        </Fragment>
      )}
    </Fragment>
  );
}
