import React from "react";
import Box from "@material-ui/core/Box";
import CategorySelectionTag from "./categorySelectionTag";
import useStyles from "./styles";

const CategorySelectionTagList = ({ categories, removeCategoryInput }) => {
  const classes = useStyles();
  return (
    <Box className={classes.categorySelectionListContainer}>
      {categories.map((category, index) => (
        <Box key={index}>
          <CategorySelectionTag
            category={category}
            removeCategoryInput={removeCategoryInput}
          />
        </Box>
      ))}
    </Box>
  );
};

export default CategorySelectionTagList;
