import { logException } from "components/util/logUtil";

export const mapData = (data, idKey, textKey, additionalKeys) => {
  const sortedData = data.sort((a, b) => {
    if (textKey === "zip") {
      return parseInt(a[textKey], 10) - parseInt(b[textKey], 10);
    } else {
      return a[textKey].localeCompare(b[textKey]);
    }
  });

  return sortedData.map((item) => {
    const mappedItem = { value: item[idKey], label: item[textKey] };

    for (const [key, value] of Object.entries(additionalKeys)) {
      if (value.includes(".")) {
        const keys = value.split(".");
        let nestedValue = item;
        keys.forEach((k) => {
          nestedValue = nestedValue ? nestedValue[k] : undefined;
        });
        mappedItem[key] = nestedValue;
      } else {
        mappedItem[key] = item[value];
      }
    }

    return mappedItem;
  });
};

export const filterCitiesBySelectedStates = (cities, selectedStates) => {
  return cities.filter((city) =>
    selectedStates.some((state) => city.state_abbreviation === state.value)
  );
};

export const filterZipCodesBySelectedCities = (zipCodes, selectedCities) => {
  return zipCodes.filter((zipCode) =>
    selectedCities.some(
      (city) =>
        city.label === zipCode.city &&
        city.state_abbreviation === zipCode.state_abbreviation
    )
  );
};

export const fetchAndSetData = async (
  fetchFunction,
  setStateFunction,
  dataKey,
  idKey,
  textKey,
  additionalKeys = {},
  mapFunction,
  setIsLoadingData = () => {}
) => {
  try {
    setIsLoadingData(true);
    const response = await fetchFunction();
    const data = response.data.data[dataKey];

    if (Array.isArray(data)) {
      setStateFunction(mapFunction(data, idKey, textKey, additionalKeys));
      setIsLoadingData(false);
    } else {
      setIsLoadingData(false);
      console.error(`Error: ${dataKey} data is not an array`, data);
    }
  } catch (error) {
    setIsLoadingData(false);
    logException(error, `Error fetching ${dataKey} data`);
  }
};
