import React from "react";

const VisualInspectionIcon = ({ width = 25, height = 33 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 25 33"
    fill="none"
  >
    <path
      d="M23.6105 30.8608L23.6074 30.8686C23.5996 30.8904 23.5918 30.9107 23.584 30.9325C23.5778 30.9481 23.5731 30.9621 23.5668 30.9777C23.545 31.0338 23.5216 31.0899 23.4967 31.1444C23.4811 31.1787 23.464 31.213 23.4468 31.2473C23.4297 31.2816 23.4126 31.3159 23.3939 31.3486C23.3814 31.3704 23.3689 31.3938 23.3565 31.4156C23.344 31.4374 23.33 31.4592 23.3175 31.481C23.305 31.5029 23.291 31.5247 23.277 31.5465C23.2567 31.5792 23.2349 31.6104 23.2131 31.6431C23.1913 31.6743 23.1695 31.7055 23.1461 31.7366C23.1461 31.7366 23.1445 31.7397 23.143 31.7413C23.0978 31.8021 23.0495 31.8597 22.9996 31.9174C22.9482 31.9766 22.8936 32.0343 22.8375 32.0888C22.8017 32.1247 22.7643 32.1589 22.7269 32.1932C22.7269 32.1932 22.7253 32.1932 22.7238 32.1948C22.6848 32.2291 22.6458 32.2618 22.6069 32.2945C22.5991 32.3008 22.5928 32.307 22.5851 32.3117C22.5523 32.3382 22.518 32.3647 22.4822 32.3896C22.3326 32.4987 22.1705 32.5969 21.9975 32.681C20.4454 33.4337 18.5815 32.8088 17.793 31.2847L17.7213 31.1366L16.6429 28.9144L16.5432 28.7071C16.1676 27.9326 16.0071 27.1082 16.0398 26.3056C16.0398 26.2791 16.0413 26.2542 16.0429 26.2277C16.046 26.1731 16.0507 26.117 16.0554 26.0625C16.06 26.0033 16.0663 25.9456 16.0741 25.8864C16.0772 25.8568 16.0819 25.8256 16.0865 25.796C16.0928 25.7555 16.099 25.7165 16.1052 25.676C16.1099 25.6448 16.1161 25.6121 16.1224 25.581C16.1255 25.5607 16.1302 25.542 16.1333 25.5217C16.1364 25.5062 16.1395 25.4921 16.1426 25.4781C16.1426 25.4734 16.1426 25.4703 16.1442 25.4672C16.152 25.4313 16.1598 25.3971 16.1676 25.3612C16.3671 24.5103 16.7847 23.714 17.3909 23.0657C17.5997 22.8413 17.8319 22.6356 18.0844 22.4501C18.1966 22.3675 18.315 22.2881 18.4366 22.2133C18.5348 22.1525 18.6345 22.0964 18.7389 22.0418C19.1488 21.7676 19.5431 21.4668 19.9186 21.1426L20.1212 21.5587L20.1259 21.5681L21.6827 24.7753L23.4235 28.3611L23.5029 28.5248C23.8489 29.2915 23.8645 30.1252 23.6089 30.8655L23.6105 30.8608Z"
      fill="url(#paint0_linear_1_2191)"
    />
    <path
      d="M23.4113 15.9797C23.1604 16.7028 22.8471 17.3885 22.4809 18.029C21.7983 19.2227 20.9272 20.2684 19.9204 21.138C19.5449 21.4621 19.1506 21.7629 18.7407 22.0372C18.6363 22.0917 18.5366 22.1478 18.4384 22.2086C18.3169 22.2834 18.2 22.3613 18.0862 22.4455C17.8338 22.6309 17.6031 22.8382 17.3927 23.061C16.7865 23.7109 16.3673 24.5057 16.1694 25.3566C16.1616 25.3908 16.1522 25.4267 16.146 25.4625C16.146 25.4656 16.1445 25.4703 16.1429 25.4734C16.1398 25.4875 16.1367 25.503 16.1351 25.5186C16.1304 25.5389 16.1273 25.5576 16.1242 25.5778C16.118 25.609 16.1117 25.6417 16.107 25.6729C16.1008 25.7119 16.0946 25.7524 16.0883 25.7929C16.0837 25.8225 16.079 25.8537 16.0759 25.8833C16.0681 25.9425 16.0619 26.0002 16.0572 26.0594C16.0525 26.1139 16.0478 26.17 16.0447 26.2246C16.0432 26.2511 16.0416 26.276 16.0416 26.3025C16.0073 27.0692 16.1538 27.8235 16.4499 28.5092L14.2027 23.8807L13.9097 23.2776C13.9097 23.2776 13.9066 23.2714 13.905 23.2699L13.7991 23.0517C13.7991 23.0517 13.7959 23.0454 13.7959 23.0423C13.2801 21.9078 13.1819 20.5816 13.623 19.31C13.9643 18.3235 14.5814 17.5116 15.359 16.9287C15.3621 16.9256 15.3653 16.9241 15.3684 16.9209C15.4323 16.8773 15.4946 16.8337 15.5569 16.7869C15.5569 16.7869 15.5601 16.7854 15.5616 16.7838C16.1398 16.3553 16.6431 15.8192 17.039 15.1927C17.2758 14.8171 17.4738 14.4088 17.6249 13.9709C18.694 10.8868 17.0592 7.5207 13.9752 6.45164C10.8911 5.38258 7.52496 7.01734 6.4559 10.1014C5.52242 12.7943 6.6507 15.7023 9.00388 17.108C9.08024 17.1532 9.15816 17.1968 9.23608 17.2389C10.6293 18.1053 11.0984 19.9271 10.2771 21.3639C9.4714 22.7743 7.71509 23.3026 6.27513 22.6029C6.15825 22.539 6.04137 22.4735 5.9276 22.4049C1.08411 19.5624 -1.24258 13.6094 0.666459 8.09887C2.84198 1.81853 9.69737 -1.51021 15.9793 0.666874C22.2596 2.8424 25.5883 9.69778 23.4128 15.9797H23.4113Z"
      fill="url(#paint1_linear_1_2191)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1_2191"
        x1="23.4647"
        y1="33.9261"
        x2="16.0057"
        y2="21.4477"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.12" stop-color="#D5DD28" />
        <stop offset="0.23" stop-color="#B8C828" />
        <stop offset="0.46" stop-color="#6E942A" />
        <stop offset="0.72" stop-color="#17562C" />
        <stop offset="0.75" stop-color="#174925" />
        <stop offset="0.82" stop-color="#173A1D" />
        <stop offset="0.89" stop-color="#173118" />
        <stop offset="1" stop-color="#172F17" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_2191"
        x1="25.0186"
        y1="13.1573"
        x2="-2.69869"
        y2="15.9071"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1" stop-color="#6EC4EB" />
        <stop offset="0.2" stop-color="#65BEE8" />
        <stop offset="0.36" stop-color="#4CB1E1" />
        <stop offset="0.55" stop-color="#299DD7" />
        <stop offset="0.62" stop-color="#2691CB" />
        <stop offset="0.74" stop-color="#2072AC" />
        <stop offset="0.86" stop-color="#194E88" />
        <stop offset="1" stop-color="#0D152E" />
      </linearGradient>
    </defs>
  </svg>
);

export default VisualInspectionIcon;
