import moment from "moment";

const arrayFields = [
  "files",
  "workticket_ids",
  "job_ids",
  "user_ids",
  "category_ids",
  "tasks",
  "wt_user_ids",
  "add_job_ids",
  "remove_job_ids",
  "add_tasks",
  "remove_task_ids",
  "interval_days",
  "remove_file_ids",
  "witnesses",
  "file_ids",
  "inspection_types",
];

export const setFormData = (data) => {
  let payload = new FormData();
  Object.keys(data).forEach((value) => {
    if (arrayFields.includes(value)) {
      if (data[value] !== null) {
        const list = data[value];
        list.map((item) => {
          return payload.append(`${value}[]`, item);
        });
      }
    } else {
      payload.append(value, data[value]);
    }
  });
  return payload;
};

export const getFilterString = (filterData) => {
  let queryString = "";
  if (filterData) {
    if (filterData.dateFilters) {
      if (filterData.dateFilters.startDateActive) {
        queryString += `filters[start_date]=${moment(
          filterData.dateFilters.startDate
        ).format("YYYY-MM-DD HH:mm:ss")}&`;
      }

      if (filterData.dateFilters.endDateActive) {
        queryString += `filters[end_date]=${moment(
          filterData.dateFilters.endDate
        ).format("YYYY-MM-DD HH:mm:ss")}&`;
      }
    }
    if (filterData.filters.length) {
      filterData.filters.forEach((element) => {
        element.values.forEach((item) => {
          queryString += `filters[${element.filter}][]=${item.value}&`;
        });
      });
    }
  }

  if (queryString === "") {
    queryString = "filters[]&";
  }
  return queryString;
};
