import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  drawerWrapper: {
    "& .MuiDrawer-paper": {
      width: 455,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      "& .MuiDrawer-paper": {
        width: "100%",
      },
    },
  },
  buttonClose: {
    position: "absolute",
    top: 0,
    left: 0,
    color: "#979797",
  },
  iconClose: {
    fontSize: 16,
  },
  currentStepContainer: {
    position: "absolute",
    top: 16,
    right: 16,
  },
  currentStep: {
    fontSize: 14,
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: "bold",
  },
  headerContainer: {
    backgroundColor: "#f8f8f8",
    paddingTop: 50,
    paddingLeft: 30,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  headerContainerTitle: {
    fontSize: 18,
    marginBottom: 15,
    fontWeight: "normal",
  },
  headerContainerSubTitle: {
    fontSize: 14,
    fontFamily: '"Nunito Sans", "Helvetica", "Arial", sans-serif',
    fontWeight: "normal",
  },
  optionWrapper: {
    padding: "0px 30px 50px 30px",
    minWidth: 440,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      minWidth: 300,
    },
  },
  contentTitle: {
    marginTop: 24,
    fontSize: 14,
  },
  optionContainer: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 40,
  },
  optionPaper: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#ebecf0",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100,
    },
    "&:hover": {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    },
  },
  optionPaperActive: {
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#d4d6dd",
    cursor: "pointer",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100,
    },
  },
  optionPaper2: {
    paddingTop: 20,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#ebecf0",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100,
    },
    "&:hover": {
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    },
  },
  optionPaperActive2: {
    paddingTop: 20,
    paddingBottom: 6,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#d4d6dd",
    cursor: "pointer",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important",
    [theme.breakpoints.down("sm")]: {
      minHeight: 100,
    },
  },
  optionBox: {
    textAlign: "center",
  },
  optionBoxTitle: {
    fontSize: 12,
    lineHeight: "14px",
  },
  optionBoxContent: {
    fontSize: 10,
  },
  avatarBox: {
    margin: "0 auto",
    marginBottom: 10,
  },
  avatarType1: {
    backgroundColor: "#9CBB65",
    "& svg": {
      color: "#ffffff",
    },
  },
  avatarType2: {
    backgroundColor: "#78C1CE",
    "& svg": {
      color: "#ffffff",
    },
  },
  avatarType3: {
    backgroundColor: "#F39A3E",
    "& svg": {
      color: "#ffffff",
    },
  },
  actionBox: {
    textAlign: "right",
  },
  containerInformationCreate: {
    padding: 20,
    width: "100%",
    "& > div > div": {
      marginBottom: 16,
    },
  },
  buttonBackDrawer: {
    textTransform: "Capitalize",
    paddingLeft: 0,
    paddingRight: 0,
    float: "left",
    color: "#4f98bc",
  },
  textInformation: {
    fontSize: 10,
    paddingTop: 4,
  },
  textInformationContainer: {
    display: "inline-flex",
    marginBottom: 20,
  },
  warningIcon: {
    marginRight: 4,
    color: "#979797",
  },
  containerOptions2: {
    marginTop: 10,
    marginBottom: 60,
  },
  containerOptions3: {
    marginTop: 10,
    marginBottom: 30,
  },
  inputLabelP: {
    fontSize: "14px !important",
    color: "#747474 !important",
    fontWeight: "bold !important",
    marginBottom: 5,
  },
  groupOption2: {
    color: "#4f98bc",
    width: "200px",
    "& > button": {
      color: "#4f98bc",
      textTransform: "capitalize",
    },
  },
  groupOptionActive: {
    backgroundColor: "#4f98bc",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#417d9b",
    },
  },
  containerCheckbox: {
    marginLeft: 8,
    marginBottom: 16,
  },
  checkbox: {
    color: "#ececec",
    border: "none",
    padding: 0,
    paddingRight: 5,
    "& + .MuiFormControlLabel-label": {
      fontSize: 14,
    },
  },
  centerLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default useStyles;
