import React, { Fragment, useState } from "react";
import useStyles from "../../../styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { GeographicalAccordion } from "./GeographicalAccordion";
import { usePartnerMainInfo } from "../../usePartnerMainInfo";
import { GeographicalCoverageDrawer } from "./GeographicalCoverageDrawer";
import { useGeoCoverage } from "./useGeoCoverage";
import LoadingIndicator from "../../../../../components/common/LoadingIndicator/loadingIndicator";

export default function DetailedAccordion() {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(Array(3).fill(false)); // Assuming 3 accordions initially
  const { partnerStates, isPartnerMainInfoLoading } = usePartnerMainInfo();
  const { isDrawerOpen, toggleAddGeo } = useGeoCoverage();
  return (
    <Fragment>
      {isPartnerMainInfoLoading ? (
        <LoadingIndicator />
      ) : (
        <Fragment>
          <Box className={classes.accordionContainer}>
            <Typography className={classes.geographicalCoverageTitle}>
              Geographic Coverage
            </Typography>
            {partnerStates?.map((item, accordionIndex) => (
              <GeographicalAccordion
                accordionIndex={accordionIndex}
                item={item}
                classes={classes}
                expanded={expanded}
              />
            ))}
            <Box className={classes.addNewCoverageButtonWrapper}>
              <Button
                onClick={() => toggleAddGeo(true, true)}
                className={classes.addContactButton}
              >
                Add New Coverage
              </Button>
            </Box>
            {isDrawerOpen ? <GeographicalCoverageDrawer /> : null}
          </Box>
        </Fragment>
      )}
    </Fragment>
  );
}
