import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MessageDialog from "../../partnersList/dialog/messageDialog";
import ErrorDialog from "components/ui/dialog/errorDialog";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import useStyles from "../../partnersList/dialog/styles";
import { RedQuestionMark } from "../icons/RedQuestionMark";

const DeleteQuestionDialogue = ({
  isDeleteQuestionDialogueOpen,
  handleDeleteUser,
  userToDelete,
  handleDeleteUserDialogueClose,
  resetDeleteQuestionId,
}) => {
  const classes = useStyles();
  const [isLoadingDialogOpen, setIsLoadingDialogOpen] = useState(false);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCloseDialog = () => {
    handleDeleteUserDialogueClose();
  };

  const handleConfirmDelete = async () => {
    setIsLoadingDialogOpen(true);
    handleDeleteUser();
    setIsLoadingDialogOpen(false);
    handleCloseDialog();
    setIsSuccessDialogOpen(true);
    resetDeleteQuestionId();
  };

  const handleCloseSuccessDialog = () => setIsSuccessDialogOpen(false);

  const handleCloseErrorDialog = () => setIsErrorDialogOpen(false);

  return (
    <>
      <Dialog
        open={isLoadingDialogOpen}
        aria-labelledby="loading-dialog-title"
        aria-describedby="loading-dialog-description"
        BackdropProps={{
          style: { backgroundColor: "rgba(0,0,0,0.5)", opacity: 0.1 },
        }}
        maxWidth="xs"
        PaperProps={{
          elevation: 0,
        }}
        fullWidth
      >
        <DialogContent>
          <LoadingIndicator />
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDeleteQuestionDialogueOpen && !isLoadingDialogOpen}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="xs"
        // BackdropProps={{
        //   style: { backgroundColor: "rgba(0,0,0,0.5)", opacity: 0.1 },
        // }}
        PaperProps={{
          elevation: 0,
        }}
        fullWidth
      >
        <DialogContent className={classes.wrapperDialog}>
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            className={classes.wrapperDialogClose}
          >
            <CloseIcon className={classes.iconDialogClose} />
          </IconButton>
          <RedQuestionMark className={classes.iconDialog} />
          <Typography variant="h6" gutterBottom className={classes.titleDialog}>
            Delete Question
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            className={classes.contentDialog}
          >
            Are you sure you want to delete this question?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.wrapperDialogAction}>
          <Button
            onClick={handleCloseDialog}
            disabled={isLoadingDialogOpen}
            className={classes.buttonOutlined}
            variant="outlined"
            size="large"
            color="primary"
          >
            Cancel
          </Button>
          <Button
            disabled={isLoadingDialogOpen}
            onClick={handleConfirmDelete}
            className={classes.button}
            color="primary"
            variant="contained"
            size="large"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <MessageDialog
        open={isSuccessDialogOpen}
        title="Success!"
        message="The question has been successfully deleted."
        handleClose={handleCloseSuccessDialog}
      />

      <ErrorDialog
        open={isErrorDialogOpen}
        title="Error!"
        message={errorMessage}
        handleClose={handleCloseErrorDialog}
      />
    </>
  );
};

export default DeleteQuestionDialogue;
