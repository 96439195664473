import React from "react";
import Chip from "@material-ui/core/Chip";

const BadgeComponent = ({
  icon,
  label,
  height = 21,
  marginRight = 3,
  paddingLeft = 10,
  border = "1px solid #BAE7FA",
  borderRadius = 4,
  fontSize = 10,
  fontWeight = 400,
  color = "#ffffff",
  backgroundColor = "#fff",
}) => {
  const chipStyles = {
    height,
    marginRight,
    paddingLeft,
    border,
    borderRadius,
    fontSize,
    fontWeight,
    color,
    backgroundColor,
  };

  return <Chip icon={icon} label={label} style={chipStyles} />;
};

export default BadgeComponent;
