import React from "react";
import Box from "@material-ui/core/Box";
import {
  checkBadge,
  clipboardDocumentCheck,
  documentPlus,
  exclamationTriangle,
  flag,
  shieldExclamation,
} from "components/common/LunaLens/images/images";

import useStyles from "./styles";

const iconsMap = {
  checkBadge,
  clipboardDocumentCheck,
  documentPlus,
  exclamationTriangle,
  flag,
  shieldExclamation,
};

const BadgeIcon = ({
  iconType = "documentPlus",
  color = "#000",
  backgroundColor = "#999",
  width = 18,
  height = 18,
  background = false,
}) => {
  const classes = useStyles();
  const renderIcon = iconsMap[iconType]
    ? iconsMap[iconType](width, height, color)
    : null;

  return (
    <Box
      className={background && classes.badgeIconContainer}
      style={background && { backgroundColor: backgroundColor }}
    >
      {renderIcon}
    </Box>
  );
};

export default BadgeIcon;
