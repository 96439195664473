import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import FormSelectChipsAuto from "components/ui/FormContent/formSelectChipsAuto";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { SuccessIcon } from "../images/images";
import DeleteIcon from "@material-ui/icons/Delete";

import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";

const DialogAddCategory = ({
  open,
  handleCancel,
  currentCategoryId,
  indexAnalysis,
}) => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categorySelected, setCategorySelected] = useState(null);

  const analysis = useLunaLensStore((state) => state.analysis);
  const categoryList = useLunaLensStore((state) => state.categoryList);
  const setAnalysisList = useLunaLensStore((state) => state.setAnalysisList);

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setIsLoading(false);
      setSuccess(false);
      setCategorySelected(null);
    }
  }, [open]);

  const handleConfirm = async () => {
    setIsLoading(true);
    const index = analysis.findIndex(
      (category) => category.id === currentCategoryId
    );
    const newCategory = {
      analysis_id: analysis[index].analysis_id,
      category_id: categorySelected.id,
      action: "addCategory",
      luna_lens_category: categorySelected,
      luna_lens_analysis_categories_trade: [
        {
          title: null,
          description: "",
          edit: true,
        },
      ],
    };
    const newAnalysis = [...analysis];
    newAnalysis.splice(index + 1, 0, newCategory);
    setAnalysisList(newAnalysis);
    handleCancel();
  };

  const handleChangeCategory = (event, value) => {
    setCategorySelected(value);
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {isLoading ? (
        <Box className={classes.loadingContainer}>
          <LoadingStateHorizontal isVisible={isLoading} />
        </Box>
      ) : (
        <>
          <MuiDialogTitle disableTypography className={classes.root}>
            {success ? (
              <Box className={classes.successContainer}>
                <SuccessIcon />
                <Typography variant="h6" className={classes.dialogTitle}>
                  Success!
                </Typography>
              </Box>
            ) : (
              <Box
                style={{ flexDirection: "column" }}
                className={classes.titleContainerCategory}
              >
                <DeleteIcon fontSize="large" />
                <Typography variant="h6" className={classes.dialogTitle}>
                  Add Category
                </Typography>
              </Box>
            )}
            {handleCancel ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleCancel}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
          <MuiDialogContent>
            {success ? (
              <Box>
                <Typography variant="body1" className={classes.successContent}>
                  Your category has been added successfully.
                </Typography>
              </Box>
            ) : (
              <Box className={classes.filterGroupCenter}>
                <Typography
                  variant="body1"
                  className={classes.filterTitleAnalysis}
                >
                  Select the category to add.
                </Typography>
                <FormSelectChipsAuto
                  gridSizes={[{ size: "md", val: 12 }]}
                  options={categoryList.map((category) => ({
                    id: category.id,
                    first_name: category.label,
                    last_name: "",
                    label: category.label,
                    icon: category.icon,
                    icon_color: category.icon_color,
                    background_color: category.background_color,
                  }))}
                  name="category"
                  handleChange={handleChangeCategory}
                  value={categorySelected}
                />
              </Box>
            )}
          </MuiDialogContent>
          <MuiDialogActions>
            {success ? (
              <Button
                onClick={handleCancel}
                className={classes.buttonConfirmSuccess}
                color="primary"
                variant="contained"
                size="large"
                fullWidth
              >
                Okay
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleCancel}
                  className={classes.buttonCancelVariantAnalysis}
                  variant="outlined"
                  size="large"
                  color="primary"
                  fullWidth
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleConfirm}
                  className={classes.buttonConfirmVariantAnalysis}
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Add Category
                </Button>
              </>
            )}
          </MuiDialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogAddCategory;
