import React, { useState, useEffect } from "react";

import moment from "moment";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import StopIcon from "@material-ui/icons/Stop";
import StartIcon from "@material-ui/icons/PlayArrow";
import TravelIcon from "@material-ui/icons/DirectionsCar";

import useStyles from "./styles";

const Timer = (props) => {
  const classes = useStyles();
  const [displayTime, setDisplayTime] = useState("00:00:00");

  const { time, travel, handleStart, handleStop, disabled } = props;

  useEffect(() => {
    setDisplayTime(
      moment
        .unix(time / 1000)
        .utc()
        .format("HH:mm:ss")
    );
  }, [time]);

  const startButton =
    time === 0 ? (
      <Fab
        color="primary"
        aria-label="Start"
        onClick={handleStart}
        className={classes.buttonStart}
        disabled={disabled}
      >
        {travel ? (
          <TravelIcon className={classes.iconLarge} />
        ) : (
          <StartIcon className={classes.iconLarge} />
        )}
      </Fab>
    ) : null;
  const stopButton =
    time === 0 ? null : (
      <Fab
        color="primary"
        aria-label="Stop"
        onClick={handleStop}
        className={classes.buttonStop}
        disabled={disabled}
      >
        {travel ? (
          <TravelIcon className={classes.iconLarge} />
        ) : (
          <StopIcon className={classes.iconLarge} />
        )}
      </Fab>
    );

  return (
    <React.Fragment>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs={5} md={5}>
          {startButton}
          {stopButton}
        </Grid>
        <Grid item xs={7} md={7}>
          <Typography variant="h5" className={classes.textTimer}>
            {displayTime}
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Timer;
