import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { SuccessIcon } from "../images/images";
import { LunaLogo } from "assets/icons/lunaLogo";

import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";
import { getFeedbackReasons, saveFeedback } from "services/lunaLensService";

const DialogFeedbackLuna = ({ open, handleCancel, thread, type }) => {
  const [filters, setFilters] = useState([]);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [feedbackComments, setFeedbackComments] = useState("");
  const [options, setOptions] = useState([]);

  const setAnalysisFeedback = useLunaLensStore(
    (state) => state.setAnalysisFeedback
  );

  const setWorkticketAnalysisFeedback = useLunaLensStore(
    (state) => state.setWorkticketAnalysisFeedback
  );

  const classes = useStyles();

  useEffect(() => {
    const getOptions = async () => {
      const response = await getFeedbackReasons();
      setOptions(response.data.feedbackReasons);
    };
    getOptions();
  }, []);

  useEffect(() => {
    if (open) {
      setFeedbackComments("");
      setFilters([]);
      setIsLoading(false);
      setSuccess(false);
    }
  }, [open]);

  const handleChangeFilters = (event, value) => {
    setFilters(value ?? []);
  };

  const handleSendFeedback = async () => {
    if (filters.length === 0) return;
    const reasons = filters.map((filter) => `${filter.value},${filter.label}`);
    const user = JSON.parse(localStorage.getItem("user"));
    setIsLoading(true);
    await saveFeedback({
      type: "negative",
      reason: filters,
      comment: feedbackComments,
      run_id: thread?.runId,
      session_id: null,
      workticket_analysis_id: thread?.id,
      category: thread?.category,
      feedback_date: new Date().toISOString(),
      feedback_score: 0,
      feedback_reasons: reasons,
      prompt: thread?.prompt,
      user_id: user.id,
      user_email: user.email,
    });
    if (type) {
      setWorkticketAnalysisFeedback(thread.id, {
        type: "negative",
        reason: filters,
        comment: feedbackComments,
      });
    } else {
      setAnalysisFeedback(thread.id, thread.category, {
        type: "negative",
        reason: filters,
        comment: feedbackComments,
      });
    }

    setIsLoading(false);
    setSuccess(true);
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
      PaperProps={{
        style: {
          backgroundColor: isLoading ? "transparent" : "white",
          boxShadow: "none",
        },
      }}
    >
      {isLoading ? (
        <Box className={classes.loadingContainer}>
          <LoadingStateHorizontal isVisible={isLoading} />
        </Box>
      ) : (
        <>
          <MuiDialogTitle disableTypography className={classes.root}>
            {success ? (
              <Box className={classes.successContainer}>
                <SuccessIcon />
                <Typography variant="h6" className={classes.dialogTitle}>
                  Success!
                </Typography>
              </Box>
            ) : (
              <Box className={classes.titleContainer}>
                <LunaLogo />
                <Typography variant="h6" className={classes.dialogTitle}>
                  Luna Feedback
                </Typography>
              </Box>
            )}
            {handleCancel ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleCancel}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
          <MuiDialogContent>
            {success ? (
              <Box>
                <Typography variant="body1" className={classes.successContent}>
                  Your feedback has been saved successfully.
                </Typography>
              </Box>
            ) : (
              <Box className={classes.filterGroup}>
                <FormSelectAuto
                  options={options ?? []}
                  name="feedback"
                  label="Tell us more"
                  placeholder="Select Reasons"
                  handleChange={handleChangeFilters}
                  multiple={true}
                  value={filters}
                  noTruncate
                  fullWidth
                />
                <TextField
                  className={classes.textArea}
                  value={feedbackComments}
                  onChange={(e) => setFeedbackComments(e.target.value)}
                  placeholder="Write here..."
                  multiline
                  minRows={6}
                  maxRows={70}
                  InputProps={{
                    className: classes.input,
                    disableUnderline: true,
                  }}
                />
              </Box>
            )}
          </MuiDialogContent>
          <MuiDialogActions>
            {success ? (
              <Button
                onClick={handleCancel}
                className={classes.buttonConfirmSuccess}
                color="primary"
                variant="contained"
                size="large"
                fullWidth
              >
                Okay
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleCancel}
                  className={classes.buttonCancelVariant}
                  variant="outlined"
                  size="large"
                  color="primary"
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleSendFeedback}
                  className={classes.buttonConfirmVariant}
                  color="primary"
                  variant="contained"
                  size="large"
                >
                  Send Feedback
                </Button>
              </>
            )}
          </MuiDialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogFeedbackLuna;
