import React, { useState, useContext } from "react";
import {
  Typography,
  Avatar,
  Box,
  Menu,
  MenuItem,
  Input,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import TimerIcon from "@material-ui/icons/Timer";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { documentIcon, imageIcon, videoIcon, linkIcon } from "./icons";
import FilePreviewDialog from "components/common/File/filePreviewDialog";
import { dateFormatField } from "components/util/timeFormat";
import { extractCommentTime } from "components/util/commentUtil";
import { permissionAdmin, hasPermission } from "lib/permissions";
import GlobalUiContext from "contexts/globalUiContext";
import * as classNames from "classnames";
import useStyles from "./styles";

const iconMap = {
  1: { icon: documentIcon(), label: "PDF" },
  2: { icon: imageIcon(), label: "Image" },
  3: { icon: videoIcon(), label: "Video" },
  4: { icon: linkIcon(), label: "Link" },
  5: { icon: linkIcon(), label: "Link" },
};

const WorkticketComment = ({
  id,
  externalId,
  user,
  time,
  date,
  message,
  fileId,
  fileUrl,
  fileName,
  fileOwner,
  fileType,
  fileSize,
  fileCreateAt,
  workticketId,
  workticketNumber,
  showMenuOptions = true,
  displayFileDetails = false,
  onUpdateComment,
  onDeleteComment,
  narrow,
}) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery("(max-width:1310px)");
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedComment, setUpdatedComment] = useState(message);
  const usr = JSON.parse(localStorage.getItem("user"));

  const isOwner = parseInt(user.id) === usr.id;

  const handleMenuClick = (event, commentId) => {
    setAnchorEl(event.currentTarget);
    setSelectedCommentId(commentId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditComment = () => {
    setEditMode(true);
    handleMenuClose();
  };

  const handleUpdateComment = () => {
    if (onUpdateComment) {
      onUpdateComment(selectedCommentId, updatedComment);
    }
    setEditMode(false);
  };

  const handleDeleteComment = () => {
    if (onDeleteComment) {
      onDeleteComment(selectedCommentId);
    }
  };

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const truncateMiddle = (str, frontChars, backChars) => {
    if (str.length <= frontChars + backChars) {
      return str;
    }
    const front = str.slice(0, frontChars);
    const back = str.slice(-backChars);
    return `${front}...${back}`;
  };

  const files = [
    {
      id: fileId,
      file_url: fileUrl,
      is_pinned: "0",
      mime_type: fileType,
      display_name: fileName,
      owner: fileOwner,
      file_size: fileSize,
      created_at: fileCreateAt,
      size: fileSize,
    },
  ];

  return (
    <Box
      className={classNames(classes.card, {
        [classes.cardRight]: !isOwner,
        [classes.cardLeft]: isOwner,
      })}
    >
      <Box className={classes.content}>
        <Box className={classes.header}>
          <Box className={classes.headerName}>
            <Box>
              {user.avatar ? (
                <Avatar className={classes.avatar} src={user.avatar} />
              ) : (
                <Avatar className={classes.avatarName}>
                  {user.name
                    .split(" ")
                    .map((word) => word.charAt(0))
                    .join("")}
                </Avatar>
              )}
            </Box>
            <Typography className={classes.name}>{user.name}</Typography>
            {extractCommentTime(time) && (
              <Button
                variant="outlined"
                className={classes.roundedButtonClock}
                startIcon={<TimerIcon style={{ fontSize: 12 }} />}
              >
                <Typography className={classes.commentClock}>
                  {extractCommentTime(time)}
                </Typography>
              </Button>
            )}
          </Box>
          <Box className={classes.dateContainer}>
            <Typography className={classes.date}>
              {dateFormatField(date)}
            </Typography>

            {showMenuOptions && (externalId === null || externalId === "") && (
              <Box className={classes.paddingMenuIcon}>
                {(hasPermission(permissionAdmin.COMMENTS_MANAGE, permissions) ||
                  usr.id === parseInt(user.id)) && (
                  <MoreHorizIcon
                    style={{ fontSize: 20 }}
                    onClick={(event) => handleMenuClick(event, id)}
                  />
                )}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                  {usr.id === parseInt(user.id) ? (
                    <MenuItem onClick={handleEditComment}>
                      <Box className={classes.menuItem}>
                        <Box className={classes.menuIcon}>
                          <EditIcon fontSize="inherit" />
                        </Box>
                        <Typography
                          variant="caption"
                          className={classes.menuText}
                        >
                          Edit
                        </Typography>
                      </Box>
                    </MenuItem>
                  ) : null}

                  <MenuItem onClick={handleDeleteComment}>
                    <Box className={classes.menuItem}>
                      <Box className={classes.menuIcon}>
                        <DeleteIcon fontSize="inherit" />
                      </Box>
                      <Typography
                        variant="caption"
                        className={classes.menuText}
                      >
                        Delete
                      </Typography>
                    </Box>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
        </Box>

        {!editMode && (
          <Typography style={{ lineHeight: "1.1" }} className={classes.message}>
            {message}
          </Typography>
        )}

        {!editMode &&
          displayFileDetails &&
          fileName &&
          iconMap[parseInt(fileType)] && (
            <Box className={classes.fileInfo}>
              <Avatar
                className={classes.fileIcon}
                onClick={() => handleThumbnailClick(0)}
              >
                {iconMap[parseInt(fileType)].icon}
              </Avatar>
              <Box>
                <Typography className={classes.fileDetailsName}>
                  {narrow ? truncateMiddle(fileName, 10, 7) : fileName}
                </Typography>
                <Typography className={classes.fileDetails}>
                  {[
                    fileOwner,
                    iconMap[parseInt(fileType)].label,
                    fileSize ? `${fileSize} MB` : "",
                  ]
                    .filter(Boolean)
                    .join(" | ")}
                </Typography>
              </Box>
            </Box>
          )}

        {editMode && (
          <Box className={classes.inputUpdateContainer}>
            <Box className={classes.updateCommentContainer}>
              <Input
                disableUnderline
                multiline
                fullWidth
                value={updatedComment}
                onChange={(e) => setUpdatedComment(e.target.value)}
                className={classes.input}
              />
            </Box>
            <Box className={classes.updateButtonsContainer}>
              <Box mr={1}>
                <Button
                  className={classes.cancelCommentButton}
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
              </Box>
              <Box ml={1}>
                <Button
                  className={classes.updateCommentButton}
                  onClick={handleUpdateComment}
                >
                  {isSmallScreen ? "Update" : "Update Comment"}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {selectedImageIndex !== null && openDialog && (
        <FilePreviewDialog
          open={openDialog}
          onClose={handleCloseDialog}
          files={files}
          workTicketNumber={workticketNumber}
          workTicketId={workticketId}
          currentImageIndex={selectedImageIndex}
        />
      )}
    </Box>
  );
};

export default WorkticketComment;
