export const appTimezone = "America/New_York";

export const requestOptionsFilter = [
  { value: 1, label: "Work Order" },
  { value: 2, label: "General" },
  { value: 3, label: "Emergency" },
  { value: -1, label: "All" },
];

export const statusRequests = [
  { id: 1, text: "Pending", onTabing: true },
  { id: 2, text: "Approved for Quote", onTabing: true },
  { id: 3, text: "Approved for Work Ticket", onTabing: true },
  { id: 4, text: "Resolved", onTabing: true },
  { id: 5, text: "Archived", onTabing: true },
];

export const statusRequestsSurface = [
  { id: 1, text: "Needs Action", onTabing: true },
  { id: 2, text: "Approved for Quote", onTabing: true },
  { id: 3, text: "Approved for Work Ticket", onTabing: true },
  { id: 4, text: "Resolved", onTabing: true },
  { id: 5, text: "Archived", onTabing: true },
];

export const requestOrigin = [
  { value: 0, label: "Encompass" },
  { value: 1, label: "Internal" },
];

export const projectOptions = {
  type: [
    { value: 0, label: "Quick" },
    { value: 1, label: "Add On" },
    { value: 2, label: "Contract" },
    { value: 3, label: "Multisite Services" },
    { value: 4, label: "Preventative" },
    { value: 5, label: "Temp-Staffing" },
    { value: 6, label: "Contract - Periodic Work" },
    { value: 7, label: "Visual Inspections" },
  ],
  status: [
    { value: 1, label: "Active" },
    { value: 2, label: "Archived" },
  ],
  owner: [
    { value: 1, label: "Team Member", admin: true },
    { value: 3, label: "Myself", admin: false },
  ],
};

export const projectOptionsFilter = [
  { value: 0, label: "Quick" },
  { value: 1, label: "Add On" },
  { value: 2, label: "Contract" },
  { value: 3, label: "Multisite Services" },
  { value: 4, label: "Preventative" },
  { value: 5, label: "Temp-Staffing" },
  { value: 6, label: "Contract - Periodic Work" },
  { value: 7, label: "Visual Inspections" },
  { value: -1, label: "All" },
];

export const projectOptionsKpiFilter = [
  { value: 1, label: "Add On" },
  { value: 3, label: "Multisite Services" },
  { value: -1, label: "All" },
];

export const projectsTabOptions = [
  { id: 1, text: "Active", tab: "active", onTabing: true },
  { id: 2, text: "Archived", tab: "archived", onTabing: true },
];

export const timekeepingTabOptions = [
  { id: 1, text: "Active", tab: "active", onTabing: true },
  { id: 2, text: "Synch", tab: "exported", onTabing: true },
];

export const workticketsStatusOptions = [
  // {
  //   id: 50,
  //   text: "Not Assigned",
  //   tab: "unassigned",
  //   selectable: false,
  //   onTabing: true,
  //   permission: 106,
  // },
  // {
  //   id: 50,
  //   text: "All Worktickets",
  //   tab: "all",
  //   selectable: false,
  //   onTabing: true,
  //   permission: -1,
  // },
  {
    id: 40,
    text: "All",
    tab: "all",
    selectable: false,
    onTabing: true,
    permission: -1,
  },
  // {
  //   id: 10,
  //   text: "Not Scheduled",
  //   tab: "not_scheduled",
  //   selectable: false,
  //   onTabing: true,
  //   permission: 107,
  // },
  {
    id: 41,
    text: "Requests",
    tab: "requests",
    selectable: false,
    onTabing: true,
    permission: 242,
  },
  {
    id: 0,
    text: "Not Started",
    tab: "not_started",
    selectable: true,
    onTabing: true,
    permission: -1,
  },
  {
    id: 1,
    text: "In Progress",
    tab: "in_progress",
    selectable: true,
    onTabing: true,
    permission: -1,
  },
  // {
  //   id: 60,
  //   text: "Emergency",
  //   tab: "emergency",
  //   selectable: false,
  //   onTabing: true,
  //   permission: -1,
  // },
  // {
  //   id: 20,
  //   text: "Overdue",
  //   tab: "overdue",
  //   selectable: false,
  //   onTabing: true,
  //   permission: -1,
  // },
  // {
  //   id: 30,
  //   text: " Corrective Action",
  //   tab: "corrective",
  //   selectable: false,
  //   onTabing: true,
  //   permission: -1,
  // },
  {
    id: 80,
    text: "Immediate Action",
    tab: "immediate",
    selectable: false,
    onTabing: true,
    permission: -1,
  },
  {
    id: 4,
    text: "Completed",
    tab: "completed",
    selectable: false,
    onTabing: true,
    permission: -1,
  },
  {
    id: 2,
    text: "Verified",
    tab: "done",
    selectable: true,
    onTabing: true,
    permission: -1,
  },
  {
    id: 5,
    text: "Archived",
    tab: "archived",
    selectable: false,
    onTabing: true,
    permission: 109,
  },
  {
    id: 31,
    text: "Pending Cancellation",
    tab: "pending_cancellation",
    selectable: false,
    onTabing: true,
    permission: 108,
  },
  {
    id: 3,
    text: "Cancelled",
    tab: "cancelled",
    selectable: true,
    onTabing: true,
    permission: 108,
  },
  {
    id: 6,
    text: "Request",
    tab: "requests",
    selectable: false,
    onTabing: false,
    permission: -1,
  },
  // {
  //   id: 70,
  //   text: "Archived",
  //   tab: "cancelled_archived",
  //   selectable: false,
  //   onTabing: true,
  //   permission: 109,
  // },
];

export const workticketsSurfaceStatusOptions = [
  {
    id: 100,
    text: "Following",
    tab: "following",
    selectable: false,
    onTabing: true,
    permission: -1,
  },
  {
    id: 40,
    text: "All",
    tab: "all",
    selectable: false,
    onTabing: true,
    permission: -1,
  },
  {
    id: 0,
    text: "Not Started",
    tab: "not_started",
    selectable: true,
    onTabing: true,
    permission: -1,
  },
  {
    id: 1,
    text: "In Progress",
    tab: "in_progress",
    selectable: true,
    onTabing: true,
    permission: -1,
  },
  // {
  //   id: 4,
  //   text: "Urgent Request",
  //   tab: "emergency",
  //   selectable: false,
  //   onTabing: true,
  //   permission: -1,
  // },
  // {
  //   id: 20,
  //   text: "Overdue",
  //   tab: "overdue",
  //   selectable: false,
  //   onTabing: true,
  //   permission: -1,
  // },
  // {
  //   id: 30,
  //   text: " Corrective Action",
  //   tab: "corrective",
  //   selectable: false,
  //   onTabing: true,
  //   permission: -1,
  // },
  {
    id: 80,
    text: "Immediate Action",
    tab: "immediate",
    selectable: false,
    onTabing: true,
    permission: -1,
  },
  {
    id: 4,
    text: "Completed",
    tab: "completed",
    selectable: false,
    onTabing: true,
    permission: -1,
  },
  {
    id: 2,
    text: "Verified",
    tab: "done",
    selectable: true,
    onTabing: true,
    permission: -1,
  },
  // {
  //   id: 5,
  //   text: "Archived",
  //   tab: "archived",
  //   selectable: false,
  //   onTabing: true,
  //   permission: -1,
  // },
  {
    id: 5,
    text: "Archived",
    tab: "archived",
    selectable: false,
    onTabing: true,
    permission: -1,
  },
];

export const workticketsStatus = [
  { value: 0, label: "Not Started" },
  { value: 1, label: "In Progress" },
  { value: 4, label: "Completed" },
  { value: 2, label: "Verified" },
  { value: 3, label: "Cancelled" },
];

export const workticketsRestrictStatus = [
  { value: 0, label: "Not Started" },
  { value: 1, label: "In Progress" },
  { value: 2, label: "Completed" },
  { value: 4, label: "Completed" },
];

export const workticketsCancellationReasonOptions = [
  { value: "Client Issue", label: "Client Issue" },
  {
    value: "Different service needed",
    label: "Different service needed",
  },
  { value: "Duplicate workticket", label: "Duplicate workticket" },
  {
    value: "Unable to find partner",
    label: "Unable to find partner",
  },
  {
    value: "Unable to schedule partner",
    label: "Unable to schedule partner",
  },
  {
    value: "Partner non compliant to SLA",
    label: "Partner Non Compliant to SLA",
  },
];

export const workticketsCancellationReason = [
  { value: "Cancelled", label: "Cancelled" },
  { value: "Cancelled/duplicated", label: "Cancelled-Duplicated" },
  { value: "Client Issue", label: "Client Issue" },
  {
    value: "Different service needed",
    label: "Different service needed",
  },
  { value: "Duplicate workticket", label: "Duplicate workticket" },
  { value: "Service period expired", label: "Service period expired" },
  {
    value: "Unable to find partner",
    label: "Unable to find partner",
  },
  {
    value: "Unable to schedule partner",
    label: "Unable to schedule partner",
  },
  {
    value: "Partner Non Compliant to SLA",
    label: "Partner Non Compliant to SLA",
  },
  { value: "Void", label: "VOID" },
  { value: "No Charge", label: "No charge" },
];

export const workticketsManagedBy = [
  { value: 2, label: "Team" },
  { value: 3, label: "Me" },
];

export const workticketStatusWG = [
  { value: "OPEN", label: "Open" },
  { value: "OPEN-DECLINED", label: "Open - Declined" },
  {
    value: "IN PROGRESS-DISPATCH CONFIRMED",
    label: "In Progress - Dispatch confirmed",
  },
  {
    value: "IN PROGRESS-PARTS ON ORDER",
    label: "In Progress - Parts on order",
  },
  { value: "IN PROGRESS-INCOMPLETE", label: "In Progress - Incomplete" },
  {
    value: "COMPLETED-PENDING CONFIRMATION",
    label: "Completed - Pending Confirmation",
  },
  { value: "COMPLETED-NO CHARGE", label: "Completed - No Charge" },
  { value: "CANCELLED", label: "Cancelled - EOL" },
];

export const workticketStatusWGVerify = [
  { value: "VERIFY", label: "Verified" },
  { value: "COMPLETED-NO CHARGE", label: "Completed - No Charge" },
  { value: "CANCELLED", label: "Cancelled - EOL" },
];

export const workticketClockOutWG = [
  { value: "Completed", label: "Completed" },
  { value: "Incomplete", label: "Incomplete" },
  { value: "PartsOnOrder", label: "Parts On Order" },
];

export const quotesStatus = [
  { value: 0, label: "Draft" },
  { value: 1, label: "Sent" },
  { value: 6, label: "Active" },
  { value: 30, label: "Snoozed - Locked" },
  { value: 40, label: "Snoozed - UnLocked" },
  { value: 5, label: "Approved" },
  { value: 10, label: "Not Approved" },
];

export const quoteTypeOptions = [
  { value: 0, label: "One Time", projectType: [1] },
  { value: 1, label: "Contract - External Billing", projectType: [2, 5] },
  { value: 5, label: "Contract - Billing", projectType: [5] },
];

export const groupOptions = [
  { value: 0, label: "-" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
];

export const quotesStatusOptions = [
  { id: -1, text: "All", tab: "all", selectable: false, onTabing: true },
  { id: 0, text: "Draft", tab: "draft", selectable: false, onTabing: true },
  { id: 6, text: "Active", tab: "active", selectable: false, onTabing: true },
  { id: 1, text: "Sent", tab: "sent", selectable: true, onTabing: true },
  {
    id: 30,
    text: "Snoozed",
    tab: "snoozed_locked",
    selectable: false,
    onTabing: true,
  },
  {
    id: 40,
    text: "Snoozed - UnLocked",
    tab: "snoozed_unlocked",
    selectable: false,
    onTabing: false,
  },
  {
    id: 7,
    text: "Customer Approved",
    tab: "Customer Approved",
    selectable: false,
    onTabing: false,
  },
  {
    id: 5,
    text: "Approved",
    tab: "approved",
    selectable: false,
    onTabing: true,
  },
  {
    id: 10,
    text: "Not Approved",
    tab: "not_approved",
    selectable: true,
    onTabing: true,
  },
  {
    id: 200,
    text: "Completed",
    tab: "completed",
    selectable: false,
    onTabing: true,
  },
  {
    id: 20,
    text: "Archived",
    tab: "archived",
    selectable: true,
    onTabing: true,
  },
];

export const worknovaDetailTabOptions = [
  { id: 0, text: "Errors", tab: "error", onTabing: true },
  { id: 1, text: "Accepted", tab: "accepted", onTabing: true },
];

export const billingAPTabOptions = [
  { id: 0, text: "In Progress", tab: "0", key: "in_progress", onTabing: true },
  { id: 1, text: "Ready", tab: "1", key: "ready", onTabing: true },
];

export const APStatus = [
  { id: 0, text: "Work in Progress" },
  { id: 1, text: "Pending Payment" },
  { id: 2, text: "Partial Parent" },
  { id: 3, text: "Partial Pending Payment" },
  { id: 4, text: "Exported" },
  { id: 5, text: "Rejected" },
];

export const APStatusSubs = [
  { id: 0, text: "Work Not Completed" },
  { id: 1, text: "Under Review" },
  { id: 2, text: "Under Review" },
  { id: 3, text: "Under Review" },
  { id: 4, text: "Processing Payment" },
  { id: 5, text: "Rejected" },
];

export const serviceUnits = [
  { value: "Units", label: "Units", symbol: "Units" },
  {
    value: "1000 ft²",
    label: "Square feet (1000 ft²)",
    symbol: "1000 ft²",
  },
  { value: "h", label: "Hourly Labor (h)", symbol: "h" },
];

export const exceptionTypes = [
  { value: "Industry", label: "Industry" },
  {
    value: "Customer",
    label: "Customer",
  },
  { value: "Job", label: "Job" },
];

export const priorityOptions = [
  { value: 1, label: "Low" },
  { value: 2, label: "Medium" },
  { value: 3, label: "High" },
];

export const switchOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

export const timekeepingTimerInterval = 300000;

export const projectHelpOptions = [
  {
    text: "Project to organize add on work that is being sold into an existing customer.",
    required: [1],
    optional: [],
  },
  {
    text: "Project is not billable, but you can pay team members and partners",
    required: [2],
    optional: [],
  },
  {
    text: "Project to organize tickets for task management purposes.",
    required: [0],
    optional: [],
  },
  {
    text: "Project to organize recurring and one time services for the multi-site delivery model.",
    required: [3],
    optional: [],
  },
  {
    text: "Project to organize external staffing that is part of the contract with the customer. Actuals are compared to general labor budget.",
    required: [5],
    optional: [],
  },
  {
    text: "Project to organize periodic services that are part of the contract with the customer. Actuals are compared to Periodic work budget.",
    required: [6],
    optional: [],
  },
  {
    text: "Project to organize all types of visual inspections (types and other details).",
    required: [7],
    optional: [],
  },
];

export const quoteHelpOptions = [
  {
    text: "Customer billing is handled inside the platform",
    required: [0, 2, 5],
    optional: [],
  },
  {
    text: "Customer billing is handled outside of the platform",
    required: [1],
    optional: [],
  },
  { text: "Quote has recurrence", required: [2, 3], optional: [] },
];

export const quotesSurfaceStatusOptions = [
  { id: -1, text: "All", tab: "all", selectable: false, onTabing: true },
  {
    id: 100,
    text: "Following",
    tab: "following",
    selectable: false,
    onTabing: true,
  },
  {
    id: 7,
    text: "Customer Approved",
    tab: "Customer Approved",
    selectable: false,
    onTabing: false,
  },
  {
    id: 1,
    text: "Needs Action",
    tab: "needs_action",
    selectable: false,
    onTabing: true,
  },
  {
    id: 30,
    text: "Snoozed",
    tab: "snoozed_locked",
    selectable: false,
    onTabing: true,
  },
  {
    id: 40,
    text: "Snoozed - UnLocked",
    tab: "snoozed_unlocked",
    selectable: false,
    onTabing: false,
  },
  {
    id: 5,
    text: "Approved",
    tab: "approved",
    selectable: false,
    onTabing: true,
  },
  {
    id: 10,
    text: "Not Approved",
    tab: "not_approved",
    selectable: true,
    onTabing: true,
  },
  {
    id: 20,
    text: "Archived",
    tab: "archived",
    selectable: true,
    onTabing: true,
  },
];

export const ringBellPositionOptions = [
  { value: "none", label: "Administration", type: "operations" },
  { value: "system", label: "System", type: "general" },
  { value: "executive", label: "Executive", type: "operations" },
  { value: "office_support", label: "Office Support", type: "operations" },
  { value: "director", label: "Director", type: "operations" },
  { value: "area_supervisor", label: "Area Supervisor", type: "operations" },
  { value: "manager", label: "Manager", type: "operations" },
  { value: "supervisor", label: "Supervisor", type: "operations" },
  { value: "dispatch_worker", label: "Dispatch Worker", type: "operations" },
  { value: "service_worker", label: "Service Worker", type: "operations" },
];

export const billingOptions = [
  { value: 0, label: "Bill Up Front" },
  { value: 1, label: "Bill Upon Work Completion" },
];

export const userAssignmentType = [
  { value: 0, label: "In House" },
  { value: 1, label: "Sub-Contractor" },
];

export const inHousePaymentTypes = [
  { value: 2, label: "Timekeeping Actuals - Auto" },
  { value: 3, label: "Paid via payroll system" },
];

export const subcontractorPaymentTypes = [
  { value: 0, label: "Flat Fee Up Front" },
  { value: 1, label: "Flat Fee Upon Work Completion" },
];

export const userUOMTypes = [
  { value: 0, label: "unit", disabledForProjects: [5, 6] },
  { value: 1, label: "h", disabledForProjects: [] },
  { value: 2, label: "ft²", disabledForProjects: [5, 6] },
  { value: 3, label: "service", disabledForProjects: [0, 1, 2, 3, 4, 5] },
];

export const externalTypes = [
  { value: 1, label: "Preventative" },
  { value: 2, label: "Reactive" },
];

export const sourceTypes = [
  { value: 1, label: "EOne" },
  { value: 7, label: "Service Channel" },
  { value: 8, label: "FM Pilot" },
  { value: 9, label: "Corrigo" },
];

export const levelCompliance = [
  { value: 1, label: "Non Compliant" },
  { value: 2, label: "On-Time" },
  { value: 3, label: "On-Time and On-Site" },
  { value: 4, label: "Fully Compliant" },
];

export const completionMethod = [
  { value: 1, label: "Web" },
  { value: 2, label: "Mobile" },
];

export const countryStates = [
  { value: "AL", label: "Alabama" },
  { value: "AK", label: "Alaska" },
  { value: "AR", label: "Arkansas" },
  { value: "AZ", label: "Arizona" },
  { value: "CA", label: "California" },
  { value: "CO", label: "Colorado" },
  { value: "CT", label: "Connecticut" },
  { value: "DC", label: "District of Columbia" },
  { value: "DE", label: "Delaware" },
  { value: "FL", label: "Florida" },
  { value: "GA", label: "Georgia" },
  { value: "ID", label: "Idaho" },
  { value: "IL", label: "Illinois" },
  { value: "IN", label: "Indiana" },
  { value: "IA", label: "Iowa" },
  { value: "KS", label: "Kansas" },
  { value: "KY", label: "Kentucky" },
  { value: "LA", label: "Louisiana" },
  { value: "ME", label: "Maine" },
  { value: "MD", label: "Maryland" },
  { value: "MA", label: "Massachusetts" },
  { value: "MI", label: "Michigan" },
  { value: "MN", label: "Minnesota" },
  { value: "MO", label: "Missouri" },
  { value: "MS", label: "Mississippi" },
  { value: "MT", label: "Montana" },
  { value: "NC", label: "North Carolina" },
  { value: "ND", label: "North Dakota" },
  { value: "NE", label: "Nebraska" },
  { value: "NJ", label: "New Jersey" },
  { value: "NM", label: "New Mexico" },
  { value: "NV", label: "Nevada" },
  { value: "NH", label: "New Hampshire" },
  { value: "NY", label: "New York" },
  { value: "OH", label: "Ohio" },
  { value: "OK", label: "Oklahoma" },
  { value: "OR", label: "Oregon" },
  { value: "PA", label: "Pennsylvania" },
  { value: "RI", label: "Rhode Island" },
  { value: "PR", label: "Puerto Rico" },
  { value: "SC", label: "South Carolina" },
  { value: "SD", label: "South Dakota" },
  { value: "TN", label: "Tennessee" },
  { value: "TX", label: "Texas" },
  { value: "UT", label: "Utah" },
  { value: "VT", label: "Vermont" },
  { value: "VA", label: "Virginia" },
  { value: "VI", label: "Virgin Islands" },
  { value: "WA", label: "Washington" },
  { value: "WV", label: "West Virginia" },
  { value: "WI", label: "Wisconsin" },
  { value: "WY", label: "Wyoming" },
];

export const questionQATypes = [
  { value: 1, label: "Rating" },
  { value: 2, label: "Short Answer" },
  { value: 3, label: "Multiple Choice" },
];

export const questionQACategory = [
  { value: 1, label: "Team Evaluation" },
  { value: 2, label: "Quality" },
  {
    value: 3,
    label: "Customer Satisfaction",
  },
];

export const questionQATypesES = [
  { value: 1, label: "Puntaje" },
  { value: 2, label: "Respuesta Corta" },
  { value: 3, label: "Respuesta Multiple" },
];

export const questionQACategoryES = [
  { value: 1, label: "Interna" },
  { value: 2, label: "Calidad" },
  {
    value: 3,
    label: "Satisfacción del Cliente",
  },
];

export const QASubTypes = [
  { value: 0, label: "-" },
  { value: 1, label: "Concierge" },
];

export const surveysQAStatuses = [
  { value: 1, label: "Draft" },
  { value: 2, label: "Published" },
];

export const surveysLang = ["English", "Spanish"];

export const surveysLabelAlpha = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
];

export const surveysQATypes = [
  { value: 1, label: "Team Evaluation" },
  { value: 2, label: "Quality" },
  {
    value: 3,
    label: "Customer Satisfaction",
  },
];

export const questionQARatingTypes = [
  { value: 1, label: "Numbers" },
  { value: 2, label: "Stars" },
];

export const questionQARatingTypesES = [
  { value: 1, label: "Numeros" },
  { value: 2, label: "Estrellas" },
];

export const signatureConfirmationOptions = [
  { value: 1, label: "Required" },
  { value: 2, label: "Optional" },
  { value: 3, label: "Not Available" },
];

export const surveysSubmitQAStatuses = [
  { value: 1, label: "In Progress", labelES: "En Progreso" },
  { value: 2, label: "Completed", labelES: "Completada" },
];

export const surveysLabelsLang = {
  English: {
    signatureGetLabel: "Get Signature",
    signatureViewLabel: "View Signature",
    signatureSignLabel: "Sign",
    signatureUpdateLabel: "Update Signature",
    signatureCreateLabel: "Create Signature",
    signatureCancelLabel: "Cancel",
    signatureCloseLabel: "Close",
    signatureLabel: "Signature",
    signatureMsgLabel: "Signature has been saved.",
    questionRequiredLabel: "Is Required",
    questionsLabel: "Questions",
    questionsGoTo: "Go To Question",
    navigationBackLabel: "Back",
    navigationNextLabel: "Next",
    answerAgainLabel: "Answer Again",
    answerAgainHeaderMsgLabel: "Answer this Question Again?",
    answerAgainBodyMsgLabel:
      "This will submit your first answer and allow you to answer this question again.",
    answerDeleteLabel: "Delete Answer",
    answerLabel: "Answer",
    answerNotLabel: "Not Answered",
    shortAnswerPlaceHolderLabel: "Input 2-3 sentence answer here ...",
    sucessMsgHeaderLabel: "Success",
    surveySubmitLabel: "Submit Survey",
    surveySubmitMsgHeaderLabel: "There are a few things left!",
    surveySubmitMsgBodyLabel:
      "Please Answer the following questions to submit your survey:",
    surveySubmitMsgButtonLabel: "Got it",
    surveySubmittedMsgHeaderLabel: "Survey Response Submitted",
    surveySubmittedMsgBodyLabel: "You look awesome today!",
    surveySubmittedTakeAgainLabel: "Take Again",
    surveySubmittedSeeResultsLabel: "See Results",
    feedbackLabel: "Additional Feedback",
    feedbackDocumentsLabel: "Documents",
    feedbackDocumentsFinishUploadLabel: "Finish Upload",
    feedbackCommentsLabel: "Comments",
    feedbackCommentsTeamLabel: "Team",
    feedbackCommentsPrivateLabel: "Private (not shared with team member)",
    feedbackCommentsAddLabel: "Add Comment",
    feedbackCommentsEditLabel: "Edit",
    feedbackCommentsDeleteLabel: "Delete",
    feedbackCommentsDeleteMsgLabel:
      "Are you sure you want to delete this comment?",
    feedbackCommentsCancelLabel: "Cancel",
    feedbackCommentsUpdateLabel: "Update Comment",
    surveyExitMsgHeaderLabel: "Surveys",
    surveyExitMsgBodyLabel: "Do you want to save this survey as draft?",
    surveyExitMsgYesLabel: "Yes",
    surveyExitMsgNoLabel: "No",
    signatureMissing: "Signature is Missing",
    noAnswerMessage: "This question was not answered.",
    noAnswerMessageAction: "Take another survey",
    noAnswerMessageDetail: "Do something awesome like:",
    noQuestionsAnswered:
      "Please answer at least one question before submitting a survey",
  },
  Spanish: {
    signatureGetLabel: "Obtener Firma",
    signatureViewLabel: "Ver Firma",
    signatureSignLabel: "Firmar",
    signatureUpdateLabel: "Actualizar Firma",
    signatureCreateLabel: "Crear Firma",
    signatureCancelLabel: "Cancelar",
    signatureCloseLabel: "Cerrar",
    signatureLabel: "Firma",
    signatureMsgLabel: "Firma ha sido guardada.",
    questionRequiredLabel: "Es Obligatoria",
    questionsLabel: "Preguntas",
    questionsGoTo: "Ir a Pregunta",
    navigationBackLabel: "Anterior",
    navigationNextLabel: "Siguiente",
    answerAgainLabel: "Responder de Nuevo",
    answerAgainHeaderMsgLabel: "¿Responder esta Pregunta de Nuevo?",
    answerAgainBodyMsgLabel:
      "Esto va a guardar tu primera respuesta y te dejara responder de nuevo.",
    answerDeleteLabel: "Borrar Respuesta",
    answerLabel: "Respuesta",
    answerNotLabel: "No Respondida",
    shortAnswerPlaceHolderLabel:
      "Ingresa 2-3 oraciones para responder aquí ...",
    sucessMsgHeaderLabel: "Exitoso",
    surveySubmitLabel: "Finalizar Encuesta",
    surveySubmitMsgHeaderLabel: "¡Faltan unas cosas!",
    surveySubmitMsgBodyLabel:
      "Por favor responde las siguientes preguntas para poder enviar la encuesta:",
    surveySubmitMsgButtonLabel: "Entendido",
    surveySubmittedMsgHeaderLabel: "Respuesta de Encuesta Enviada",
    surveySubmittedMsgBodyLabel: "¡Te ves genial hoy!",
    surveySubmittedTakeAgainLabel: "Responder de Nuevo",
    surveySubmittedSeeResultsLabel: "Ver Resultados",
    feedbackLabel: "Retroalimentación Adicional",
    feedbackDocumentsLabel: "Documentos",
    feedbackDocumentsFinishUploadLabel: "Finalizar Carga",
    feedbackCommentsLabel: "Comentarios",
    feedbackCommentsTeamLabel: "Equipo",
    feedbackCommentsPrivateLabel: "Privado (no compartido con el equipo)",
    feedbackCommentsAddLabel: "Agregar Comentario",
    feedbackCommentsEditLabel: "Editar",
    feedbackCommentsDeleteLabel: "Eliminar",
    feedbackCommentsDeleteMsgLabel: "¿Esta seguro de eliminar este comentario?",
    feedbackCommentsCancelLabel: "Cancelar",
    feedbackCommentsUpdateLabel: "Actualizar Comentario",
    surveyExitMsgHeaderLabel: "Encuestas",
    surveyExitMsgBodyLabel: "¿Quiere guardar esta encuesta como borrador?",
    surveyExitMsgYesLabel: "Si",
    surveyExitMsgNoLabel: "No",
    signatureMissing: "Falta Firmar la Encuesta",
    noAnswerMessage: "Esta Pregunta no fue contestada.",
    noAnswerMessageAction: "Responder otra Encuesta",
    noAnswerMessageDetail: "Has algo increíble como:",
    noQuestionsAnswered:
      "Por favor responde al menos una pregunta antes de finalizar la encuesta",
  },
};

export const noticeTypes = [
  { value: 4, label: "Verbal Warning" },
  { value: 5, label: "Verbal Warning: P&A" },
  { value: 1, label: "Phase I" },
  { value: 2, label: "Phase II" },
  { value: 3, label: "Suspension" },
];

export const triggerActions = [
  { value: "1", label: "Survey Completed" },
  { value: "2", label: "Score on Survey" },
  { value: "3", label: "Score on Question" },
  { value: "4", label: "Score on Task" },
  { value: "5", label: "Team Evaluation Completed" },
  { value: "6", label: "Team Evaluation Score" },
  { value: "7", label: "Workticket Rating Score - Recall" },
];
export const triggerOperators = [
  { value: "<", label: "Greater than" },
  { value: ">", label: "Less than" },
  { value: "<=", label: "Greater or Equal to" },
  { value: ">=", label: "Less or Equal to" },
  { value: "=", label: "Equal to" },
  { value: "!=", label: "Not Equal to" },
];
export const triggerDeliveryChannel = [
  { value: "email", label: "Email", showInList: true },
  { value: "sms", label: "Text Message", showInList: true },
  { value: "", label: "Recall", showInList: false },
];
export const triggerDeliveryEmailChannel = [{ value: "email", label: "Email" }];

export const industryList = [
  {
    value: 1,
    label: "Healthcare",
  },
  {
    value: 3,
    label: "Education",
  },
  {
    value: 4,
    label: "Commercial",
  },
  {
    value: 5,
    label: "Hospitality",
  },
  {
    value: 6,
    label: "Government",
  },
  {
    value: 7,
    label: "Public Venue",
  },
  {
    value: 8,
    label: "Service Centers",
  },
  {
    value: 9,
    label: "Industrial",
  },
  {
    value: 10,
    label: "Event",
  },
  {
    value: 11,
    label: "Construction",
  },
];
export const learnTilesTypes = [
  { value: 1, label: "Link" },
  { value: 2, label: "Video" },
];

export const learnColorWheel = [
  { value: "#17457A", label: "#17457A" },
  { value: "#4F98BC", label: "#4F98BC" },
  { value: "#328914", label: "#328914" },
  { value: "#F07A8C", label: "#F07A8C" },
  { value: "#F39A3E", label: "#F39A3E" },
  { value: "#EFC945", label: "#EFC945" },
  { value: "#9CBB65", label: "#9CBB65" },
  { value: "#78C1CE", label: "#78C1CE" },
  { value: "#99D458", label: "#99D458" },
  { value: "#FBBC55", label: "#FBBC55" },
  { value: "#548ECE", label: "#548ECE" },
  { value: "#A67EC8", label: "#A67EC8" },
];

export const taxEmemptOptions = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];

export const jobTypesOptions = [
  { value: 1, label: "Parent" },
  { value: 2, label: "Child" },
];

export const deliveryTypesOptions = [
  { value: 0, label: "Campus" },
  { value: 1, label: "Building" },
  { value: 2, label: "Space" },
  { value: 3, label: "Portfolio" },
];

export const industryOptions = [
  { value: 0, label: "Higher Education" },
  { value: 1, label: "K-12 Education" },
  { value: 2, label: "Healthcare" },
  { value: 3, label: "General Business" },
  { value: 4, label: "Hospitality and Entertainment" },
  { value: 5, label: "Retail" },
  { value: 6, label: "Aerospace and Aviation" },
  { value: 7, label: "Manufacturing and Distribution" },
  { value: 8, label: "Construction" },
  { value: 9, label: "Science and Research" },
];

export const industryOptionsCore = [
  { value: 1, label: "Healthcare" },
  { value: 3, label: "Education" },
  { value: 5, label: "Hospitality" },
  { value: 6, label: "Government" },
  { value: 12, label: "Business & Industry" },
  { value: 100, label: "Admin" },
];

export const customerAlertsFilters = [
  { value: "manager_alert", label: "Missing Manager" },
  { value: "director_alert", label: "Missing Director" },
];

export const ratingWTOptions = [1, 2, 3, 4, 5];
export const ratingWTLabelDisplay = {
  left: "Very Poor",
  center: "Average",
  right: "Excellent",
};

export const worknovaLocations = [
  { value: "Nova Main Campus", label: "Nova Main Campus" },
  { value: "Nova Regional Campus", label: "Nova Regional Campus" },
];

export const worknovaTypes = [
  { value: "Athletic Fields", label: "Athletic Fields" },
  { value: "Custodial", label: "Custodial" },
  { value: "Delivery", label: "Delivery" },
  { value: "Event Setup", label: "Event Setup" },
  { value: "General Services", label: "General Services" },
  { value: "Grounds", label: "Grounds" },
  { value: "Irrigation", label: "Irrigation" },
  { value: "Moving", label: "Moving" },
  { value: "Partitions", label: "Partitions" },
  { value: "Pressure Wash", label: "Pressure Wash" },
  { value: "Signage", label: "Signage" },
  { value: "Transportation", label: "Transportation" },
  { value: "Warehouse", label: "Warehouse" },
];

export const quoteTypes = [
  { value: 1, label: "Add On" },
  { value: 2, label: "Contract" },
];

export const completionMethodLabel = {
  0: "-",
  1: "Web",
  2: "Mobile",
};

export const sourcesLabels = {
  7: "Service Channel",
  8: "FM Pilot",
  9: "Corrigo",
};

export const rateColors = {
  1: "#F07A8C",
  2: "#F5A6B5",
  3: "#EFC945",
  4: "#9CBB65",
  5: "#328914",
};

export const fmPilotExpires = 30; // minutes
export const scExpires = 480; // 8 hours

export const partnersTabOptions = [
  { id: 1, text: "All", tab: "all", onTabing: true },
  { id: 2, text: "Active", tab: "active", onTabing: true },
  { id: 3, text: "Onboarding", tab: "onboarding", onTabing: true },
  { id: 4, text: "Potential", tab: "potential", onTabing: true },
  { id: 5, text: "Inactive", tab: "inactive", onTabing: true },
];

export const partnerStatus = [
  { value: 1, label: "Active" },
  { value: 2, label: "Onboarding" },
  { value: 3, label: "Potential" },
  { value: 4, label: "Inactive" },
];

export const partnerRatingFilter = [
  { value: 1, label: "1+ Star Rating" },
  { value: 2, label: "2+ Star Rating" },
  { value: 3, label: "3+ Star Rating" },
  { value: 4, label: "4+ Star Rating" },
  { value: 5, label: "5  Star Rating" },
];

export const insuranceComplianceFilter = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
];

export const ndaSignedFilter = [
  { value: 0, label: "Inactive" },
  { value: 1, label: "Active" },
];

export const ownershipOptions = [
  { value: 0, label: "No" },
  { value: 1, label: "Yes" },
];

export const mimeTypes = {
  PDF: 1,
  IMAGE: 2,
  VIDEO: 3,
  LINK: 4,
  LINK_360: 5,
};

export const workTicketTypes = [
  { value: 1, label: "Service" },
  { value: 2, label: "Inspection" },
];

export const ownershipTypes = [
  { value: "woman_owned", label: "Woman Owned (WBE)" },
  { value: "disability_owned", label: "Disability Owned (DOBE)" },
  { value: "veteran_owned", label: "Veteran Owned (VOBE)" },
  { value: "minority_owned", label: "Minority Owned (MBE)" },
];
