import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { withRouter } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AppBar from "@material-ui/core/AppBar";

import useStyles from "./styles";
import { AnalysisReportActive, Minimize } from "../images/images";
import useLunaLensStore from "store/lunaLensAnalysis";
import { useUploadFileLensState } from "contexts/uploadFileLensContext";
import DialogCancelAnalysis from "./dialogCancelAnalysis";
import {
  usePageHeaderDispatch,
  usePageHeaderState,
} from "contexts/pageHeaderContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogLunaLens = ({
  open,
  setOpen,
  content,
  workticketId,
  workticketNumber,
  history,
  analysis,
  analysisEditId,
}) => {
  const classes = useStyles();
  const [cancel, setCancel] = useState(false);
  const dispatchPageHeader = usePageHeaderDispatch();
  const { pageHeaderTabs } = usePageHeaderState();

  const { isUploadFile } = useUploadFileLensState();

  const startingAnalysis = useLunaLensStore((state) => state.startingAnalysis);
  const setStartingAnalysis = useLunaLensStore(
    (state) => state.setStartingAnalysis
  );
  const resetSelectedMedia = useLunaLensStore(
    (state) => state.resetSelectedMedia
  );
  const startAnalysisFlag = useLunaLensStore(
    (state) => state.startAnalysisFlag
  );
  const resetSelectedCategories = useLunaLensStore(
    (state) => state.resetSelectedCategories
  );
  const resetAllCategories = useLunaLensStore(
    (state) => state.resetAllCategories
  );
  const setAllGlobalSelectedMediaCount = useLunaLensStore(
    (state) => state.setAllGlobalSelectedMediaCount
  );
  const analysisInformation = useLunaLensStore(
    (state) => state.analysisInformation
  );
  const resetAnalysisInformation = useLunaLensStore(
    (state) => state.resetAnalysisInformation
  );
  const analysisThread = useLunaLensStore((state) => state.analysisThread);
  const analysisComment = useLunaLensStore((state) => state.analysisComment);
  const setAnalysisComment = useLunaLensStore(
    (state) => state.setAnalysisComment
  );
  const globalThreadId = useLunaLensStore((state) => state.globalThreadId);
  const clearGlobalThreadId = useLunaLensStore(
    (state) => state.clearGlobalThreadId
  );

  const handleClose = () => {
    if (isUploadFile) return;
    resetSelectedMedia();
    resetSelectedCategories();
    resetAllCategories();
    setAllGlobalSelectedMediaCount(0);
    resetAnalysisInformation();
  };

  return (
    <>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        TransitionProps={{ onExited: () => resetAnalysisInformation() }}
      >
        <AppBar className={classes.headerBarDialog}>
          <Box>
            {!startAnalysisFlag && (
              <IconButton
                aria-label="close"
                onClick={() => {
                  if (isUploadFile) return;
                  setAnalysisComment(false);
                  if (analysis === "new") {
                    if (startingAnalysis) {
                      setCancel(true);
                    } else {
                      clearGlobalThreadId();
                      resetSelectedMedia();
                      resetSelectedCategories();
                      resetAllCategories();
                      setAllGlobalSelectedMediaCount(0);
                      setOpen(false);
                    }
                  } else {
                    setOpen(false);
                  }
                }}
                style={{ padding: 6 }}
              >
                <CloseIcon style={{ color: "#747474" }} />
              </IconButton>
            )}
            {((analysisInformation?.categories?.length > 0 &&
              !startAnalysisFlag) ||
              (analysis !== "new" && !analysisComment)) && (
              <IconButton
                aria-label="close"
                onClick={async () => {
                  if (isUploadFile) return;
                  setOpen(false);
                  resetSelectedMedia();
                  resetSelectedCategories();
                  resetAllCategories();
                  setAllGlobalSelectedMediaCount(0);
                  const tabId = new Date().valueOf();
                  const analysisId =
                    analysis === "new" ? analysisThread?.id : analysisEditId;
                  pageHeaderTabs.push({
                    id: tabId,
                    label: `${workticketNumber}`,
                    link: `/lens-analysis/${workticketId}/${workticketNumber}/${analysisId}/${
                      analysis === "new" ? "new" : "edit"
                    }/${globalThreadId}`,
                    hasClose: true,
                    parent: false,
                  });
                  dispatchPageHeader({
                    type: "SET_PAGE_TAB",
                    pageHeaderTabs: [...pageHeaderTabs],
                  });
                  clearGlobalThreadId();
                }}
                style={{ padding: 6 }}
              >
                <Minimize style={{ color: "#747474" }} />
              </IconButton>
            )}
          </Box>
          <Box className={classes.headerBarDialogTitleContainer}>
            <AnalysisReportActive width={29} height={30} />
            <Typography variant="h4" className={classes.headerBarDialogTitle}>
              Generate Insights Report
            </Typography>
          </Box>
        </AppBar>
        <Box className={classes.BodyDialogContainer}>{content}</Box>
      </Dialog>
      <DialogCancelAnalysis
        open={cancel}
        handleCancel={() => setCancel(false)}
        handleConfirm={() => {
          clearGlobalThreadId();
          setStartingAnalysis(false);
          setCancel(false);
          setOpen(false);
          resetSelectedMedia();
          resetSelectedCategories();
          resetAllCategories();
          setAllGlobalSelectedMediaCount(0);
        }}
      />
    </>
  );
};

export default withRouter(DialogLunaLens);
