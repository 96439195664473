import React from "react";
import addOnLogo from "../../../../assets/icons/calendar + icon.png";
import classNames from "classnames";
import Chip from "@material-ui/core/Chip";
import QuickIcon from "@material-ui/icons/Receipt";
import ContractIcon from "@material-ui/icons/EventNote";
import PreventativeIcon from "@material-ui/icons/EventAvailable";
import TeamIcon from "@material-ui/icons/Group";
import VisualInspectionIcon from "assets/icons/visualInspectionsIcon";
import ServiceIcon from "assets/icons/serviceIcon";

import { projectOptions } from "constants.js";
import useStyles from "./styles";

const ProjectTypeChip = (props) => {
  const classes = useStyles();

  const { type } = props;
  const typeSelected = projectOptions.type.find(
    (typeItem) => typeItem.value === Number(type)
  );

  if (!typeSelected) {
    return "Error type";
  }
  let classChip = null;
  let iconChip = null;

  switch (typeSelected.label) {
    case "Quick":
      classChip = classes.chipQuick;
      iconChip = <QuickIcon />;
      break;
    case "Add On":
      classChip = classes.chipAddOn;
      iconChip = <img src={addOnLogo} alt="Add On" width="10" height="12" />;
      break;
    case "Contract":
      classChip = classes.chipPeriodic;
      iconChip = <ContractIcon />;
      break;
    case "Multisite Services":
      classChip = classes.chipServiceTypes;
      iconChip = <ServiceIcon width={15} height={20} />;
      break;
    case "Preventative":
      classChip = classes.chipPreventative;
      iconChip = <PreventativeIcon />;
      break;
    case "Temp-Staffing":
      classChip = classes.chipStaffing;
      iconChip = <TeamIcon />;
      break;
    case "Contract - Periodic Work":
      classChip = classes.chipPeriodic;
      iconChip = <ContractIcon />;
      break;
    case "Visual Inspections":
      classChip = classes.chipServiceTypes;
      iconChip = <VisualInspectionIcon width={12} height={16} />;
      break;
    default:
      break;
  }

  return (
    <Chip
      icon={iconChip}
      label={typeSelected.label}
      className={classNames(classes.chip, classChip)}
    />
  );
};

export default ProjectTypeChip;
