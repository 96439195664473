import React from "react";
import { Box, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  pageInfo: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(3),
  },
  navButton: {
    padding: theme.spacing(1),
    color: "#4F98BC",
  },
}));

const ImageGallerNavFooter = ({
  currentPage,
  totalPages,
  imagesPerPage,
  totalImages,
  handlePrev,
  handleNext,
  disabled,
}) => {
  const classes = useStyles();

  const getPageInfo = () => {
    const start = currentPage * imagesPerPage + 1;
    const end = Math.min((currentPage + 1) * imagesPerPage, totalImages);
    return `${start}-${end} of ${totalImages}`;
  };

  console.log(disabled);

  return (
    <Box className={classes.footer}>
      <Box className={classes.pageInfo}>
        <Typography
          style={{
            fontSize: 12,
            fontWeight: "600",
          }}
        >
          {getPageInfo()}
        </Typography>
      </Box>
      <Box>
        <IconButton
          className={classes.navButton}
          onClick={handlePrev}
          disabled={currentPage === 0 || disabled}
        >
          <ChevronLeftRoundedIcon color="inherit" />
        </IconButton>
        <IconButton
          className={classes.navButton}
          onClick={handleNext}
          disabled={currentPage === totalPages - 1 || disabled}
        >
          <ChevronRightRoundedIcon color="inherit" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ImageGallerNavFooter;
