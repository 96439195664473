import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { LensIcon } from "./images/lensIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    border: "1px solid var(--Blue-400, #4F98BC)",
    background: "var(--White, #FFF)",
    boxShadow: "0px 4px 10px 0px rgba(79, 152, 188, 0.20)",
    padding: 12,
    textTransform: "none",
    fontWeight: 800,
    color: "#4F98BC",
    position: "relative",
    zIndex: 1,
    transition: "background 0.3s ease-in-out, color 0.3s ease-in-out",
    "&:hover": {
      background: "var(--Blue-100, #EDF5F8)",
      color: "#4F98BC",
    },
    "& .MuiButton-startIcon": {
      marginLeft: 0,
    },
  },
}));

const LunaLensButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      disableElevation
      onClick={onClick}
      className={classes.root}
      startIcon={<LensIcon />}
    >
      New Insights Report
    </Button>
  );
};

export default LunaLensButton;
