import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { SuccessIcon } from "../images/images";
import EditIcon from "@material-ui/icons/Edit";

import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";
import { getLunaLensCategoryById } from "services/lunaLensService";

const DialogChangeCategory = ({
  open,
  handleCancel,
  handleSubmit,
  currentCategoryId,
  newCategoryId,
  analysisWorkticketId,
  analysis,
}) => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const updateAnalysisCategory = useLunaLensStore(
    (state) => state.updateAnalysisCategory
  );

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setIsLoading(false);
      setSuccess(false);
    }
  }, [open]);

  const handleConfirm = async () => {
    setIsLoading(true);
    const res = await getLunaLensCategoryById(newCategoryId);
    const newAnalysis = analysis?.luna_lens_analysis_categories.map(
      (category) => {
        if (category.id === currentCategoryId) {
          return {
            ...category,
            category_id: String(res.data.category.id),
            luna_lens_category: res.data.category,
            oldId: currentCategoryId,
            action: "editCategory",
          };
        }
        return category;
      }
    );
    updateAnalysisCategory(analysisWorkticketId, newAnalysis);
    handleCancel();
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {isLoading ? (
        <Box className={classes.loadingContainer}>
          <LoadingStateHorizontal isVisible={isLoading} />
        </Box>
      ) : (
        <>
          <MuiDialogTitle disableTypography className={classes.root}>
            {success ? (
              <Box className={classes.successContainer}>
                <SuccessIcon />
                <Typography variant="h6" className={classes.dialogTitle}>
                  Success!
                </Typography>
              </Box>
            ) : (
              <Box
                style={{ flexDirection: "column" }}
                className={classes.titleContainerCategory}
              >
                <EditIcon fontSize="large" />
                <Typography variant="h6" className={classes.dialogTitle}>
                  Change Category
                </Typography>
              </Box>
            )}
            {handleCancel ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleCancel}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
          <MuiDialogContent>
            {success ? (
              <Box>
                <Typography variant="body1" className={classes.successContent}>
                  Your category has been changed successfully.
                </Typography>
              </Box>
            ) : (
              <Box className={classes.filterGroupCenter}>
                <Typography
                  variant="body1"
                  className={classes.filterTitleAnalysis}
                >
                  Are you sure you want to change the category?
                </Typography>
              </Box>
            )}
          </MuiDialogContent>
          <MuiDialogActions>
            {success ? (
              <Button
                onClick={handleCancel}
                className={classes.buttonConfirmSuccess}
                color="primary"
                variant="contained"
                size="large"
                fullWidth
              >
                Okay
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleCancel}
                  className={classes.buttonCancelVariantAnalysis}
                  variant="outlined"
                  size="large"
                  color="primary"
                  fullWidth
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleConfirm}
                  className={classes.buttonConfirmVariantAnalysis}
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Change Category
                </Button>
              </>
            )}
          </MuiDialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogChangeCategory;
