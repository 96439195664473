import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import eosOneLogo from "../../../assets/logo-eos-one.png";
import { PinIcon, downloadIconSimple, icon360 } from "./icons";
import Thumbnail from "./thumbnail";
import CommentList from "./commentList";
import AddCommentButton from "./addCommentButton";
import EmbeddedPdf from "./embeddedPdf";
import FloatingComments from "./floatingComment";
import TimestampComment from "./timestampComment";
import VideoPlayer from "./videoPlayer";
import TabComment from "./tabComment";
import { validateWistiaLink } from "components/util/youtubeUtil";
import { mimeTypes } from "constants.js";
import {
  dateFormatField,
  convertTimeToSeconds,
} from "components/util/timeFormat";
import { getCommentByFileId } from "services/fileService";
import { useWorkticketView } from "contexts/workticketViewContext";
import * as classNames from "classnames";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const FilePreviewDialog = ({
  open,
  onClose,
  files,
  workTicketNumber,
  workTicketId,
  currentImageIndex,
}) => {
  const classes = useStyles();
  const [stateContext, dispatchContext] = useWorkticketView();
  const [dialogImageIndex, setDialogImageIndex] = useState(currentImageIndex);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [forceRender, setForceRender] = useState(false);
  const [playedTime, setPlayedTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [activeTab, setActiveTab] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [isLoadingCommentsList, setIsLoadingCommentsList] = useState(true);
  const { workticketFileComments } = stateContext ?? null;
  const playerRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setDialogImageIndex(currentImageIndex);
  }, [currentImageIndex]);

  // Reset playedTime to 0 when the video changes
  useEffect(() => {
    const loadFile = async () => {
      // Load files comments
      setIsLoadingCommentsList(true);
      const fileSelected = files[dialogImageIndex];
      const response = await getCommentByFileId(fileSelected?.id);
      const dataComment = response.data.data;
      dispatchContext({
        type: "SET_WORKTICKET_FILE_COMMENTS",
        workticketFileComments: dataComment,
      });
      const firstKey = Object.keys(dataComment)[0];
      if (firstKey) {
        setActiveTab(firstKey);
      }
      setIsLoadingCommentsList(false);
    };
    // Reset playedTime to 0
    setPlayedTime(0);
    loadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogImageIndex]);

  useEffect(() => {
    if (workticketFileComments) {
      setCommentList(workticketFileComments[activeTab]);
    }
  }, [workticketFileComments, activeTab]);

  // Force re-render when the fullscreen state changes
  useEffect(() => {
    setForceRender(!forceRender);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFullScreen]);

  // Update playedTime when toggling between fullscreen and normal mode
  // useEffect(() => {
  //   if (isFullScreen && playerRef.current) {
  //     const currentTime = playerRef.current.getCurrentTime();
  //     setPlayedTime(currentTime);
  //   }
  // }, [isFullScreen, dialogImageIndex]);

  const handleClick = (index) => {
    setDialogImageIndex(index);
  };

  const handleFullScreenClick = () => {
    setIsFullScreen(!isFullScreen);
  };

  const handleDownloadClick = () => {
    window.open(files[dialogImageIndex].file_url, "_blank");
  };

  const handleTimeClick = (time) => {
    const seconds = convertTimeToSeconds(time);
    if (playerRef.current) {
      playerRef.current.seekTo(seconds, "seconds");
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const videoUrl = files[dialogImageIndex]?.file_url;
  if (!videoUrl) {
    logException("file_url is undefined or dialogImageIndex is out of bounds");
    return null;
  }
  const isWistiaVideo = validateWistiaLink(videoUrl);

  const renderHeaderBar = () => {
    return (
      <Box className={classes.headerBar}>
        {/* Logo and pinning */}
        <img src={eosOneLogo} alt="Encompass Onsite" className={classes.logo} />
        <Box className={classes.bottomBar}>
          {files[dialogImageIndex] &&
            parseInt(files[dialogImageIndex].is_pinned) === 1 && (
              <Box className={classes.pinIconImg}>{PinIcon()}</Box>
            )}

          {parseInt(files[dialogImageIndex].mime_type) ===
            mimeTypes.LINK_360 && (
            <Button
              className={classes.roundedButton}
              variant="outlined"
              startIcon={icon360()}
            >
              360°
            </Button>
          )}

          {[mimeTypes.VIDEO, mimeTypes.LINK, mimeTypes.LINK_360].includes(
            parseInt(files[dialogImageIndex].mime_type)
          ) && (
            <IconButton
              className={classes.fullScreenIcon}
              onClick={handleFullScreenClick}
            >
              <FullscreenIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth fullScreen={true}>
      {/* Header */}
      {!isFullScreen && (
        <Box className={classes.videoPreviewHeaderContainer}>
          <IconButton
            className={classes.closeIconButtonDialog}
            onClick={onClose}
            size="small"
          >
            <CloseIcon />
          </IconButton>
          <Box>
            <Typography variant="h6" className={classes.videoPreviewTitle}>
              {workTicketNumber}
            </Typography>
          </Box>
        </Box>
      )}
      <DialogContent
        className={classNames({
          [classes.dialogFullScreen]: isFullScreen,
          [classes.dialogNormal]: !isFullScreen,
        })}
      >
        <Box className={classes.flexColumnContainer}>
          <Box className={classes.flexColumnChild}>
            {/* File viewer */}
            <Box
              className={classNames(classes.fileViewerContainer, {
                [classes.fullScreenBorder]: isFullScreen,
                [classes.normalBorder]: !isFullScreen,
              })}
            >
              {renderHeaderBar()}
              {/* Specific content based on file type */}
              <Box
                className={classes.fileViewerContainerInner}
                id="contentViewerId"
              >
                {files[dialogImageIndex] && (
                  <>
                    {/* Render PDF file */}
                    {parseInt(files[dialogImageIndex].mime_type) ===
                      mimeTypes.PDF && (
                      <>
                        <EmbeddedPdf
                          src={files[dialogImageIndex].file_url}
                          height={"100%"}
                        />
                      </>
                    )}
                    {/* Render YT, vimeo, wistia, mp4 files and link 360  */}
                    {[
                      mimeTypes.VIDEO,
                      mimeTypes.LINK,
                      mimeTypes.LINK_360,
                    ].includes(parseInt(files[dialogImageIndex].mime_type)) && (
                      <>
                        <Box>
                          <VideoPlayer
                            key={isWistiaVideo ? forceRender : null}
                            url={files[dialogImageIndex].file_url}
                            playerRef={playerRef}
                            isFullScreen={isFullScreen}
                            fullScreenPlayer={false}
                            initialSeekTime={isFullScreen ? playedTime : 0}
                            onVideoDurationChange={setVideoDuration}
                          />

                          {isFullScreen && (
                            <Box className={classes.floatingBox}>
                              <FloatingComments
                                data={workticketFileComments}
                                file={files[dialogImageIndex]}
                                workTicketId={workTicketId}
                                activeTab={activeTab}
                                playerRef={playerRef}
                                isLoadingComments={isLoadingCommentsList}
                                videoDuration={videoDuration}
                              />
                            </Box>
                          )}
                        </Box>
                      </>
                    )}

                    {/* Render image file */}
                    {parseInt(files[dialogImageIndex].mime_type) ===
                      mimeTypes.IMAGE && (
                      <Box className={classes.previewImageContainer}>
                        <Box
                          className={classes.imageGallery}
                          style={{
                            backgroundImage: `url('${files[dialogImageIndex].file_url}')`,
                          }}
                        ></Box>
                      </Box>
                    )}
                  </>
                )}
              </Box>
              {/* Info section and File Details */}
              {!isFullScreen && (
                <Box className={classes.fileInfoBoxBorder}>
                  <Box className={classes.imageInfoContainerStyle}>
                    <Typography
                      variant="body1"
                      className={classes.imageNameStyle}
                    >
                      {files[dialogImageIndex]?.display_name || ""}
                    </Typography>
                    {![mimeTypes.LINK, mimeTypes.LINK_360].includes(
                      parseInt(files[dialogImageIndex].mime_type)
                    ) &&
                      user?.is_super && (
                        <Button
                          className={classes.previewFileBtnDownload}
                          variant="outlined"
                          startIcon={downloadIconSimple()}
                          onClick={handleDownloadClick}
                        >
                          <Typography variant="body2">Download</Typography>
                        </Button>
                      )}
                  </Box>
                  <Typography
                    variant="body2"
                    className={classes.fileInfoTypography}
                  >
                    {[
                      files[dialogImageIndex]?.owner,
                      files[dialogImageIndex]?.file_size
                        ? files[dialogImageIndex].file_size + " MB"
                        : "",
                      dateFormatField(
                        files[dialogImageIndex]?.created_at || ""
                      ),
                    ]
                      .filter(Boolean)
                      .join(" | ")}
                  </Typography>
                </Box>
              )}
            </Box>
            {/* Mini Gallery */}
            <Box className={classes.thumbnailContainer}>
              {!isFullScreen && (
                <Box className={classes.thumbnails}>
                  {files.map((file, index) => (
                    <Thumbnail
                      key={index}
                      file={file}
                      width={92}
                      height={64}
                      onClick={() => handleClick(index)}
                      pinIcon={parseInt(file.is_pinned) === 1 && PinIcon()}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {!isFullScreen && (
          <Box className={classes.commentSectionStyle}>
            {/* Comment list */}
            <Box style={{ marginLeft: 16, marginRight: 16, marginBottom: 16 }}>
              <TabComment activeTab={activeTab} onChangeTab={handleTabChange} />
            </Box>
            <Box className={classes.scrollableBox}>
              <Box style={{ padding: 16 }}>
                <CommentList
                  data={commentList}
                  file={files[dialogImageIndex]}
                  workTicketId={workTicketId}
                  activeTab={activeTab}
                  onTimeClick={handleTimeClick}
                  isLoadingComments={isLoadingCommentsList}
                />
              </Box>
            </Box>
            {/* Button to add comments */}
            <Box className={classes.commentsInputContainer}>
              {[mimeTypes.LINK, mimeTypes.LINK_360, mimeTypes.VIDEO].includes(
                parseInt(files[dialogImageIndex]?.mime_type)
              ) ? (
                <TimestampComment
                  file={files[dialogImageIndex]}
                  workTicketId={workTicketId}
                  playerRef={playerRef}
                  videoDuration={videoDuration}
                  activeTab={activeTab}
                />
              ) : (
                <Box className={classes.timestampContainer}>
                  <AddCommentButton
                    file={files[dialogImageIndex]}
                    workTicketId={workTicketId}
                    activeTab={activeTab}
                    onCommentChange={() => {}}
                  />
                </Box>
              )}
            </Box>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FilePreviewDialog;
