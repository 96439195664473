import React, { useEffect, useState } from "react";
import ImageGallery from "./gallery/imageGallery";
import FilePreviewDialog from "../File/filePreviewDialog";

const SelectedMediaGallery = ({
  media,
  category,
  title,
  workticketId,
  workticketNumber,
}) => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [openDialog, setOpenDialog] = useState(true);

  useEffect(() => {
    const mediaFilesResponse = media.map((item) => ({
      id: item?.file.id,
      file_url: item?.file.file_url,
      is_pinned: "0",
      mime_type: item?.file.mime_type,
      display_name: item?.file.display_name,
      owner: item?.file.owner,
      file_size: item?.file.file_size,
      created_at: item?.file.created_at,
      size: item?.file.file_size,
    }));
    setMediaFiles(mediaFilesResponse);
  }, [media]);

  const handleOpenImage = (index) => {
    setSelectedImageIndex(index.index);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <ImageGallery
        imageUrls={media?.map((item) => ({
          id: item.file.id,
          url: item.file.file_url,
          mime_type: item.file.mime_type,
        }))}
        numberImages={28}
        viewOnly={true}
        category={category}
        title={title}
        handleOpenImage={handleOpenImage}
      />
      {selectedImageIndex !== null && openDialog && (
        <FilePreviewDialog
          open={openDialog}
          onClose={handleCloseDialog}
          files={mediaFiles}
          workTicketNumber={workticketNumber}
          workTicketId={workticketId}
          currentImageIndex={selectedImageIndex}
        />
      )}
    </>
  );
};

export default SelectedMediaGallery;
