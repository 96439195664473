import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import MessageDialog from "pages/workticketSurfacePage/dialog/successDialog";
import ConfirmDialog from "components/ui/dialog/confirmDialog";
import WorkticketComment from "components/ui/Worktickets/WorkticketComment";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import SendIcon from "@material-ui/icons/Send";
import {
  addWorkticketComment,
  updateWorkticketComment,
  deleteWorkticketComment,
} from "services/workticketService";
import { useWorkticketView } from "contexts/workticketViewContext";
import { getWorkticketComment } from "services/workticketSurfaceService";
import { logException } from "components/util/logUtil";
import useStyles from "pages/workticketSurfacePage/styles";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";
import ZeroStateCommentsLens from "./zeroStateCommentsLens";

const LensCommentsSurface = ({ workticketId, workticketNumber }) => {
  const classes = useStyles();
  const [stateContext, dispatchContext] = useWorkticketView();

  const [subTab] = useState("customer");
  const [comment, setComment] = useState("");
  const [commentList, setCommentList] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedComment, setSelectedComment] = useState(null);
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [openMessage, setOpenMessage] = useState(false);
  const [openMessageContent, setOpenMessageContent] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false);

  const { workticketComments } = stateContext;

  useEffect(() => {
    fetchWorkTicketComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (workticketComments) {
      setCommentList(workticketComments || []);
    }
  }, [workticketComments]);

  const fetchWorkTicketComments = async () => {
    try {
      if (!workticketId) return;
      setIsLoadingComments(true);
      const response = await getWorkticketComment(workticketId);
      setWorkTicketComments(response.data.data);
      setIsLoadingComments(false);
    } catch (e) {
      console.log("Cannot load workticket comments data");
    }
  };

  const setWorkTicketComments = (comments, flagList = true) => {
    dispatchContext({
      type: "SET_WORKTICKET_COMMENTS",
      workticketComments: comments,
    });
    if (flagList) {
      setCommentList(comments);
    }
  };

  const closeConfirm = () => {
    setOpenConfirm(false);
  };

  const closeMessage = () => {
    if (!isLoadingData) {
      setOpenMessage(false);
    }
  };

  const handleAddComment = async () => {
    if (comment === "") return;

    const data = { body: comment, tab: subTab, type: 2 };

    try {
      setIsLoadingData(true);
      setOpenMessage(true);

      await addWorkticketComment(workticketId, data);
      const refreshData = await getWorkticketComment(workticketId);
      setWorkTicketComments(refreshData.data.data);

      setComment("");
      setOpenMessageContent("Comment has been added successfully.");
    } catch (e) {
      logException(e, "Cannot add comment");
    } finally {
      setIsLoadingData(false);
    }
  };

  const handleDeleteConfirmation = (commentId) => {
    setSelectedComment(commentId);
    setOpenConfirm(true);
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await deleteWorkticketComment(workticketId, commentId);

      setCommentList((prevCommentList) => {
        const updatedCommentList = { ...prevCommentList };
        updatedCommentList[subTab] = prevCommentList[subTab].filter(
          (c) => c.id !== commentId
        );
        return updatedCommentList;
      });

      setOpenConfirm(false);
    } catch (e) {
      logException(e, "Cannot delete comment");
    }
  };

  const handleUpdateComment = async (commentId, newBody) => {
    const data = { body: newBody, tab: subTab };

    try {
      setIsLoadingData(true);
      setOpenMessage(true);

      await updateWorkticketComment(workticketId, commentId, data);

      setCommentList((prevCommentList) => {
        const updatedCommentList = { ...prevCommentList };
        updatedCommentList[subTab] = prevCommentList[subTab].map((c) =>
          c.id === commentId ? { ...c, body: newBody } : c
        );

        setComment("");
        return updatedCommentList;
      });

      setOpenMessageContent("Comment has been updated successfully.");
    } catch (e) {
      logException(e, "Cannot update comment");
    } finally {
      setIsLoadingData(false);
    }
  };

  const renderComments = () => {
    const comments = commentList[subTab] || [];
    return comments.length > 0 ? (
      comments.map((comment) => (
        <WorkticketComment
          key={comment.id}
          id={comment.id}
          externalId={comment.external_id}
          user={{
            id: comment.user_id,
            name: comment.user_name,
            avatar: comment.profile_url,
          }}
          time={comment.video_metadata}
          date={comment.created_at}
          message={comment.body}
          fileId={comment.files_id}
          fileUrl={comment.file_url}
          fileName={comment.display_name}
          fileOwner={comment.user_name}
          fileType={comment.mime_type}
          fileSize={comment.file_size}
          fileCreateAt={comment.files_created_at}
          workticketId={workticketId}
          workticketNumber={workticketNumber}
          displayFileDetails={true}
          onUpdateComment={handleUpdateComment}
          onDeleteComment={() => handleDeleteConfirmation(comment.id)}
        />
      ))
    ) : (
      <ZeroStateCommentsLens />
    );
  };

  return (
    <>
      <Box className={classes.containerCommentsLens}>
        {!isLoadingComments ? (
          <>
            <Box className={classes.commentsContainerList}>
              <Box className={classes.commentsContainerListInner}>
                <Box className={classes.commentsContainerContent}>
                  {renderComments()}
                </Box>
                <Box className={classes.containerAddButton}>
                  <Input
                    className={classes.commentInputText}
                    type="text"
                    placeholder="Add a comment"
                    multiline
                    disableUnderline
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />

                  <IconButton
                    size="small"
                    style={{
                      color: comment.trim() !== "" ? "#4F98BC" : "#D9D9D9",
                    }}
                    onClick={handleAddComment}
                  >
                    <SendIcon />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "1 1",
            }}
          >
            <LoadingStateHorizontal isVisible />
          </Box>
        )}
      </Box>
      <ConfirmDialog
        open={openConfirm}
        handleConfirm={() => handleDeleteComment(selectedComment)}
        handleClose={closeConfirm}
        message={"Are you sure you want to delete this comment?"}
        title={`Workticket ${workticketNumber}`}
      />
      <MessageDialog
        title={"Success!"}
        open={openMessage}
        handleClose={closeMessage}
        message={openMessageContent}
        isLoadingData={isLoadingData}
      />
    </>
  );
};

export default LensCommentsSurface;
