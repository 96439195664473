import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { logException } from "components/util/logUtil";
import FormInput from "components/ui/FormContent/formInput";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import LoadingStateHorizontal from "components/common/LoadingStateHorizontal/LoadingStateHorizontal";
import { LayersIcon, SuccessIcon } from "../images/images";
import { createLunaLensCategory } from "services/lunaLensService";

import useStyles from "./styles";
import useLunaLensStore from "store/lunaLensAnalysis";

const DialogNewCategory = ({
  open,
  handleCancel,
  handleSubmit,
  currentCategoryId,
}) => {
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryName, setCategoryName] = useState("");

  const addCategory = useLunaLensStore((state) => state.addCategory);
  const analysis = useLunaLensStore((state) => state.analysis);
  const setAnalysisList = useLunaLensStore((state) => state.setAnalysisList);

  const classes = useStyles();

  useEffect(() => {
    if (open) {
      setCategoryName("");
      setIsLoading(false);
      setSuccess(false);
    }
  }, [open]);

  const handleBlur = (event) => {
    try {
      setCategoryName(event.value);
    } catch (e) {
      logException(e, "Cannot set new value to field");
    }
  };

  const handleConfirm = async () => {
    if (categoryName.trim() === "") return;

    setIsLoading(true);
    const user = JSON.parse(localStorage.getItem("user"));
    const res = await createLunaLensCategory({
      name: categoryName,
      user_id: user.id,
    });
    addCategory(res.data.category);
    const newAnalysis = analysis.map((category) => {
      if (category.id === currentCategoryId) {
        return {
          ...category,
          category_id: String(res.data.category.id),
          luna_lens_category: res.data.category,
          oldId: currentCategoryId,
          action: "editCategory",
        };
      }
      return category;
    });
    setAnalysisList(newAnalysis);
    handleCancel();
  };

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCancel();
        }
      }}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      {isLoading ? (
        <Box className={classes.loadingContainer}>
          <LoadingStateHorizontal isVisible={isLoading} />
        </Box>
      ) : (
        <>
          <MuiDialogTitle disableTypography className={classes.root}>
            {success ? (
              <Box className={classes.successContainer}>
                <SuccessIcon />
                <Typography variant="h6" className={classes.dialogTitle}>
                  Success!
                </Typography>
              </Box>
            ) : (
              <Box className={classes.titleContainerCategory}>
                <LayersIcon color="#4D4D4D" background="none" />
                <Typography variant="h6" className={classes.dialogTitle}>
                  Create New Category
                </Typography>
              </Box>
            )}
            {handleCancel ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleCancel}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
          <MuiDialogContent>
            {success ? (
              <Box>
                <Typography variant="body1" className={classes.successContent}>
                  Your category has been saved successfully.
                </Typography>
              </Box>
            ) : (
              <Box className={classes.filterGroupCenter}>
                <Typography
                  variant="body1"
                  className={classes.filterTitleAnalysis}
                >
                  This category will be added to the default list and shown in
                  future analysis.
                </Typography>
                <FormInput
                  gridSizes={[{ size: "md", val: 12 }]}
                  name="name"
                  label="Name"
                  placeholder="Write a name"
                  value={categoryName}
                  handleBlur={handleBlur}
                />
              </Box>
            )}
          </MuiDialogContent>
          <MuiDialogActions>
            {success ? (
              <Button
                onClick={handleCancel}
                className={classes.buttonConfirmSuccess}
                color="primary"
                variant="contained"
                size="large"
                fullWidth
              >
                Okay
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleCancel}
                  className={classes.buttonCancelVariantAnalysis}
                  variant="outlined"
                  size="large"
                  color="primary"
                  fullWidth
                >
                  Cancel
                </Button>

                <Button
                  onClick={handleConfirm}
                  className={classes.buttonConfirmVariantAnalysis}
                  color="primary"
                  variant="contained"
                  size="large"
                  fullWidth
                >
                  Create Category
                </Button>
              </>
            )}
          </MuiDialogActions>
        </>
      )}
    </Dialog>
  );
};

export default DialogNewCategory;
