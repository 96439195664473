import React, { createContext, useContext, useReducer } from "react";
import merge from "lodash/merge";

const defaultState = {
  isUploadFile: false,
  uploadFiles: [],
  resetFile: null,
};

const UploadFileLensStateContext = createContext();
const UploadFileLensDispatchContext = createContext();

function uploadFileLensReducer(state, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        isUploadFile: action.isUploadFile,
      };
    case "SET_UPLOAD_FILES":
      return {
        ...state,
        uploadFiles: action.uploadFiles,
      };
    case "UPDATE_UPLOAD_PROGRESS":
      const updateProgressInCategory = (files, fileName, progress) => {
        return files.map((file) => {
          if (file.display_name === fileName) {
            let status = file.status;
            if (progress === 100) {
              status = "Completed";
            }
            return { ...file, progress, status };
          }
          return file;
        });
      };

      const updatedAll = updateProgressInCategory(
        state.uploadFiles.all,
        action.fileName,
        action.progress
      );
      const updatedPdf = updateProgressInCategory(
        state.uploadFiles.pdf,
        action.fileName,
        action.progress
      );
      const updatedImage = updateProgressInCategory(
        state.uploadFiles.image,
        action.fileName,
        action.progress
      );
      const updatedVideo = updateProgressInCategory(
        state.uploadFiles.video,
        action.fileName,
        action.progress
      );
      const updatedLinks = updateProgressInCategory(
        state.uploadFiles.links,
        action.fileName,
        action.progress
      );

      return {
        ...state,
        uploadFiles: {
          all: updatedAll,
          pdf: updatedPdf,
          image: updatedImage,
          video: updatedVideo,
          links: updatedLinks,
        },
      };
    case "UPDATE_UPLOAD_STATUS":
      const updateStatusInCategory = (files, fileName, status) => {
        return files.map((file) => {
          if (file.display_name === fileName) {
            return { ...file, status };
          }
          return file;
        });
      };

      const filesWithUpdatedStatusAll = updateStatusInCategory(
        state.uploadFiles.all,
        action.fileName,
        action.status
      );
      const filesWithUpdatedStatusPdf = updateStatusInCategory(
        state.uploadFiles.pdf,
        action.fileName,
        action.status
      );
      const filesWithUpdatedStatusImage = updateStatusInCategory(
        state.uploadFiles.image,
        action.fileName,
        action.status
      );
      const filesWithUpdatedStatusVideo = updateStatusInCategory(
        state.uploadFiles.video,
        action.fileName,
        action.status
      );
      const filesWithUpdatedStatusLinks = updateStatusInCategory(
        state.uploadFiles.links,
        action.fileName,
        action.status
      );

      return {
        ...state,
        uploadFiles: {
          all: filesWithUpdatedStatusAll,
          pdf: filesWithUpdatedStatusPdf,
          image: filesWithUpdatedStatusImage,
          video: filesWithUpdatedStatusVideo,
          links: filesWithUpdatedStatusLinks,
        },
      };
    case "UPDATE_UPLOAD_FILE_ID":
      const updateFileIdInCategory = (files, fileName, fileId) => {
        return files.map((file) => {
          if (file.display_name === fileName) {
            return { ...file, id: fileId };
          }
          return file;
        });
      };

      const filesWithUpdatedIdAll = updateFileIdInCategory(
        state.uploadFiles.all,
        action.fileName,
        action.fileId
      );
      const filesWithUpdatedIdPdf = updateFileIdInCategory(
        state.uploadFiles.pdf,
        action.fileName,
        action.fileId
      );
      const filesWithUpdatedIdImage = updateFileIdInCategory(
        state.uploadFiles.image,
        action.fileName,
        action.fileId
      );
      const filesWithUpdatedIdVideo = updateFileIdInCategory(
        state.uploadFiles.video,
        action.fileName,
        action.fileId
      );
      const filesWithUpdatedIdLinks = updateFileIdInCategory(
        state.uploadFiles.links,
        action.fileName,
        action.fileId
      );

      return {
        ...state,
        uploadFiles: {
          all: filesWithUpdatedIdAll,
          pdf: filesWithUpdatedIdPdf,
          image: filesWithUpdatedIdImage,
          video: filesWithUpdatedIdVideo,
          links: filesWithUpdatedIdLinks,
        },
      };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UploadFileLensProvider({ initialState, children }) {
  var mergedState = merge({}, defaultState, initialState);
  const [state, dispatch] = useReducer(uploadFileLensReducer, mergedState);

  return (
    <UploadFileLensStateContext.Provider value={state}>
      <UploadFileLensDispatchContext.Provider value={dispatch}>
        {children}
      </UploadFileLensDispatchContext.Provider>
    </UploadFileLensStateContext.Provider>
  );
}

function useUploadFileLensState() {
  const context = useContext(UploadFileLensStateContext);
  if (context === undefined) {
    throw new Error(
      "useUploadFileState must be used within a UploadFileProvider"
    );
  }
  return context;
}

function useUploadFileLensDispatch() {
  const context = useContext(UploadFileLensDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useUploadFilesDispatch must be used within a UploadFilesProvider"
    );
  }
  return context;
}

export {
  UploadFileLensProvider,
  useUploadFileLensState,
  useUploadFileLensDispatch,
};
