import React from "react";
import Box from "@material-ui/core/Box";
import CategoryTag from "./categoryTag";
import useStyles from "./styles";

const CategoryTagList = ({ categories, hideLabel }) => {
  const classes = useStyles();
  return (
    <Box className={classes.tagListContainer}>
      {categories.map((category, index) => (
        <CategoryTag
          key={index}
          iconType={category.luna_lens_category.icon}
          color={category.luna_lens_category.icon_color}
          backgroundColor={category.luna_lens_category.background_color}
          text={category.luna_lens_category.label}
          hideLabel={hideLabel}
        />
      ))}
    </Box>
  );
};

export default CategoryTagList;
