import React, { useContext, useEffect, useState } from "react";
import * as classNames from "classnames";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import CalendarFilters from "components/common/Filters/calendarOptionFilters";
import WorkticketLiquidityFieldKpi from "components/ui/Worktickets/WorkticketLiquidityFieldKpi";
import withFilterContext from "components/hoc/withFilterContext";
import { getPartnersMetrics } from "services/partners/metricsService";
import { dateFormatField } from "components/util/timeFormat";
import { hasPermission, permissionDashboard } from "lib/permissions";
import { getFilterData, useFilterState } from "contexts/filterContext";
import GlobalUiContext from "contexts/globalUiContext";
import { usePartnerState } from "contexts/partnerContext";
import { ratingWTLabelDisplay } from "constants.js";
import useStyles from "./styles";

const Metrics = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [dataMetadata, setDataMetadata] = useState(null);
  const [urlFilter, setUrlFilter] = useState("");
  const filterState = useFilterState();
  const { partnerDetails } = usePartnerState();
  const { globalUi } = useContext(GlobalUiContext);
  const { permissions } = globalUi;
  const classes = useStyles();

  useEffect(() => {
    const loadMetrics = async () => {
      setIsLoading(true);
      const filterData = getFilterData(filterState);
      const { data } = await getPartnersMetrics(partnerDetails?.id, filterData);
      const resultMetadata = data.data.metadata;
      setData(data.data.metrics);
      setDataMetadata(resultMetadata);
      setUrlFilter(
        `?filters[start_date]=${resultMetadata.start_date}&filters[end_date]=${resultMetadata.end_date}&$filters[assigned][]=${resultMetadata.user_id}`
      );
      setIsLoading(false);
    };
    if (partnerDetails?.id) {
      loadMetrics();
    }
  }, [filterState, partnerDetails]);

  if (isLoading) {
    return (
      <Box className={classes.zeroContainer}>
        <Typography
          variant="h3"
          className={classes.metricsInformationSubHeader}
        >
          Metric Summary
        </Typography>
        <LoadingIndicator />
      </Box>
    );
  }

  const ratedScore = Number(
    parseFloat(data.worktickets_rated_score).toFixed(0)
  );

  return (
    <Box className={classes.metricsMainContainer}>
      <Box className={classes.metricsMainHeader}>
        <Typography
          variant="h3"
          className={classes.metricsInformationSubHeader}
        >
          Metric Summary
        </Typography>
        <Box className={classes.metricsMainHeaderDates}>
          <CalendarFilters />
          <Box className={classes.dateRangeContainer}>
            <Box>
              <Typography variant="body1">Date Range:</Typography>
            </Box>
            {dataMetadata ? (
              <Box>
                {dateFormatField(dataMetadata.start_date)} -{" "}
                {dateFormatField(dataMetadata.end_date)}
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>

      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Box className={classes.metricDataContainer}>
            <Box className={classes.metricStatusContainer}>
              <Box className={classes.greenDot} />
              <Typography className={classes.metricNumbersText}>
                Completed
              </Typography>
            </Box>
            <Box className={classes.metricNumbersContainer}>
              <Typography className={classes.metricDataPercent}>
                {parseFloat(data.liquidity).toFixed(0)}%
              </Typography>
              <Typography className={classes.metricNumbersText}>
                {data.completed}/{data.capacity}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.metricDataContainer}>
            <Box className={classes.metricStatusContainer}>
              <Box className={classes.whiteDot} />
              <Typography className={classes.metricNumbersText}>
                Liquidity
              </Typography>
            </Box>
            <Box className={classes.metricNumbersContainer}>
              <Typography className={classes.metricDataPercent}>
                <WorkticketLiquidityFieldKpi
                  value1={data.capacity}
                  value2={data.completed}
                  type="number"
                />
              </Typography>
              <Typography className={classes.metricNumbersText}>
                {data.completed}/{data.capacity}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.metricDataContainer}>
            <Box className={classes.metricStatusContainer}>
              <Box className={classes.lightBlueDot} />
              <Typography className={classes.metricNumbersText}>
                Completed On-Time
              </Typography>
            </Box>

            {data.capacity > 0 ? (
              <Box className={classes.metricNumbersContainer}>
                <Typography className={classes.metricDataPercent}>
                  {parseFloat((data.ontime / data.capacity) * 100).toFixed(0)}%
                </Typography>
                <Typography className={classes.metricNumbersText}>
                  {data.ontime}/{data.capacity}
                </Typography>
              </Box>
            ) : (
              <Box className={classes.metricNumbersContainer}>
                <Typography className={classes.metricDataPercent}>
                  0%
                </Typography>
                <Typography className={classes.metricNumbersText}>
                  0/0
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className={classes.metricDataContainer}>
            <Box className={classes.metricStatusContainer}>
              <Box className={classes.purpleDot} />
              <Typography className={classes.metricNumbersText}>
                Completed On-Time & On-Site
              </Typography>
            </Box>
            {data.capacity > 0 ? (
              <Box className={classes.metricNumbersContainer}>
                <Typography className={classes.metricDataPercent}>
                  {parseFloat(
                    (data.ontime_onsite / data.capacity) * 100
                  ).toFixed(0)}
                  %
                </Typography>
                <Typography className={classes.metricNumbersText}>
                  {data.ontime_onsite}/{data.capacity}
                </Typography>
              </Box>
            ) : (
              <Box className={classes.metricNumbersContainer}>
                <Typography className={classes.metricDataPercent}>
                  0%
                </Typography>
                <Typography className={classes.metricNumbersText}>
                  0/0
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.metricDataContainer}>
            <Box className={classes.metricStatusContainer}>
              <Box className={classes.darkBlueDot} />
              <Typography className={classes.metricNumbersText}>
                Fully Compliant
              </Typography>
            </Box>
            {data.capacity > 0 ? (
              <Box className={classes.metricNumbersContainer}>
                <Typography className={classes.metricDataPercent}>
                  {parseFloat(
                    (data.fully_compliant / data.capacity) * 100
                  ).toFixed(0)}
                  %
                </Typography>
                <Typography className={classes.metricNumbersText}>
                  {data.fully_compliant}/{data.capacity}
                </Typography>
              </Box>
            ) : (
              <Box className={classes.metricNumbersContainer}>
                <Typography className={classes.metricDataPercent}>
                  0%
                </Typography>
                <Typography className={classes.metricNumbersText}>
                  0/0
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      {hasPermission(permissionDashboard.KPI, permissions) ||
      hasPermission(permissionDashboard.KPI_PARTNER, permissions) ? (
        <Box className={classes.addNewCoverageButtonWrapper}>
          <Link
            to={`/dashboard-kpi/worktickets${urlFilter}`}
            className={classes.viewDashboardButton}
          >
            View WT Dashboard
          </Link>
        </Box>
      ) : null}

      <Box className={classes.workTicketsRatingContainer}>
        <Box className={classes.metricStatusContainer}>
          <Typography className={classes.metricNumbersText}>
            Worktickets Rating
          </Typography>
        </Box>
        <Box className={classes.metricNumbersContainer}>
          <Box className={classes.ratingNumberTextContainer}>
            <Box
              className={classNames(classes.ratingNumberContainer, {
                [classes.ratingFont0]: ratedScore === 0,
                [classes.ratingFont1]: ratedScore === 1,
                [classes.ratingFont2]: ratedScore === 2,
                [classes.ratingFont3]: ratedScore === 3,
                [classes.ratingFont4]: ratedScore === 4,
                [classes.ratingFont5]: ratedScore === 5,
              })}
            >
              <Typography>
                {parseFloat(data.worktickets_rated_score).toFixed(0)}
              </Typography>
            </Box>

            <Typography className={classes.metricNumbersText}>
              {parseFloat(data.worktickets_rated_score).toFixed(0)}/5{" "}
              {ratedScore > 0 ? (
                <span className={classes.ratingTextSpan}>
                  {ratedScore > 3
                    ? ratingWTLabelDisplay.right
                    : ratedScore > 2
                    ? ratingWTLabelDisplay.center
                    : ratingWTLabelDisplay.left}
                </span>
              ) : null}
            </Typography>
          </Box>
          <Typography className={classes.metricNumbersText}>
            <span className={classes.ratingNumberSpan}>
              {data.worktickets_rated}
            </span>{" "}
            worktickets have been rated.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default withFilterContext(Metrics);
