import React from "react";
const Context = React.createContext(null);

export const Provider = Context.Provider;
export const Consumer = Context.Consumer;

const WorknovaActionContext = React.createContext();

const initialState = {
  openDeleteSingle: false,
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_DELETE_SINGLE":
      return {
        ...state,
        openDeleteSingle: action.open,
      };
    default:
      return initialState;
  }
};

const WorknovaActionProvider = ({ children }) => (
  <WorknovaActionContext.Provider
    value={React.useReducer(reducer, initialState)}
  >
    {children}
  </WorknovaActionContext.Provider>
);

function useWorknovaAction() {
  const context = React.useContext(WorknovaActionContext);
  if (context === undefined) {
    throw new Error(
      "useWorknovaAction must be used within a WorknovaActionProvider"
    );
  }
  return context;
}

export { WorknovaActionProvider, useWorknovaAction };
