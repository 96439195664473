import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const LensIcon = () => (
  <SvgIcon width="19" height="16" viewBox="0 0 19 16" fill="none">
    <path
      d="M8.91326 14.3066C8.90858 14.439 8.89378 14.5706 8.86652 14.7006C8.76446 15.1866 8.46296 15.5953 8.04149 15.8199C7.81868 15.9383 7.57015 16 7.32085 16C6.62671 16 5.97308 15.5016 5.78532 14.7894C5.71287 14.5131 5.72845 14.226 5.69105 13.9456C5.66145 13.726 5.61782 13.5081 5.56017 13.2951C5.44643 12.8781 5.27815 12.4776 5.06157 12.1083C4.63309 11.378 4.01763 10.7752 3.29466 10.3804C3.03445 10.2382 2.76178 10.123 2.48054 10.0375C2.33563 9.99308 2.18839 9.9569 2.04037 9.92811C1.90247 9.9018 1.72796 9.91331 1.59708 9.86643C1.59318 9.86479 1.58773 9.86479 1.58383 9.86479H1.57994C1.51761 9.85903 1.45451 9.85492 1.39219 9.85245C0.60689 9.75458 0 9.04978 0 8.19612C0 7.34245 0.641169 6.59982 1.45763 6.5332C1.60643 6.53732 1.75289 6.54718 1.89936 6.56199C3.44034 6.70838 4.85278 7.33259 5.99956 8.29398C6.71552 8.89434 7.3302 9.62629 7.80621 10.4503C8.29313 11.2933 8.63357 12.23 8.80341 13.2013C8.86651 13.5623 8.92806 13.9374 8.91482 14.3074L8.91326 14.3066Z"
      fill="url(#paint0_linear_493_4507)"
    />
    <path
      d="M8.51979 4.73051C8.47694 4.8547 8.42942 4.97724 8.37878 5.09977C8.34606 5.18284 8.311 5.26508 8.27438 5.3465C7.73605 6.55297 6.94452 7.54809 5.99796 8.29319C4.85118 7.3318 3.43952 6.70759 1.89775 6.5612C1.82842 6.55462 1.7583 6.54722 1.68896 6.5431C2.32858 6.58094 3.07336 6.5135 3.63506 6.16726C4.404 5.69356 5.03192 4.78808 5.47131 3.99034C5.72217 3.53473 5.88266 3.07582 6.36568 2.83403C6.76845 2.63254 7.25069 2.61692 7.68541 2.83403C8.37254 3.17451 8.71066 3.9813 8.51979 4.72969V4.73051Z"
      fill="url(#paint1_linear_493_4507)"
    />
    <path
      d="M9.08202 1.77394C9.08747 1.63577 9.10227 1.49761 9.1311 1.36109C9.23783 0.851195 9.55335 0.423541 9.99508 0.188332C10.2288 0.064148 10.4882 0 10.75 0C11.4769 0 12.1624 0.522231 12.3588 1.26816C12.4351 1.55765 12.4187 1.85865 12.4577 2.15225C12.4881 2.38252 12.534 2.61033 12.5948 2.83403C12.714 3.27155 12.8901 3.69098 13.1176 4.07751C13.5663 4.84235 14.2114 5.47396 14.9686 5.88764C15.2413 6.03649 15.5272 6.15739 15.8217 6.24703C15.9736 6.29308 16.1279 6.33174 16.2829 6.36134C16.4278 6.38931 16.6101 6.37697 16.7472 6.42549C16.7511 6.42714 16.7566 6.42714 16.7605 6.42714H16.7644C16.8298 6.43289 16.896 6.43701 16.9615 6.4403C17.7841 6.5431 18.4199 7.2808 18.4199 8.17476C18.4199 9.06872 17.7483 9.84754 16.8929 9.91662C16.7371 9.91251 16.5836 9.90182 16.4301 9.8862C14.8151 9.73323 13.3365 9.07859 12.1352 8.07196C11.3849 7.44364 10.7414 6.67633 10.242 5.81362C9.73176 4.93035 9.37572 3.94921 9.19732 2.93189C9.1311 2.55358 9.06644 2.16129 9.08124 1.77312L9.08202 1.77394Z"
      fill="url(#paint2_linear_493_4507)"
    />
    <path
      d="M9.49365 11.8057C9.53884 11.6758 9.58792 11.5475 9.64167 11.4184C9.67595 11.332 9.71257 11.2448 9.75074 11.1601C10.3148 9.89607 11.1437 8.85325 12.1355 8.07361C13.3368 9.08024 14.8162 9.73488 16.4304 9.88785C16.5029 9.89525 16.5769 9.90265 16.6493 9.90676C15.9793 9.86646 15.1995 9.93719 14.6105 10.2999C13.805 10.7966 13.1475 11.7448 12.687 12.5804C12.4237 13.0574 12.2562 13.5385 11.7498 13.7918C11.3276 14.0024 10.8227 14.0196 10.367 13.7918C9.64713 13.4349 9.29265 12.5903 9.49287 11.8057H9.49365Z"
      fill="url(#paint3_linear_493_4507)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_493_4507"
        x1="8.39362"
        y1="15.9383"
        x2="1.43194"
        y2="7.08287"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#17447F" />
        <stop offset="0.16" stopColor="#1C639E" />
        <stop offset="0.41" stopColor="#259BD6" />
        <stop offset="0.59" stopColor="#38A5DB" />
        <stop offset="0.94" stopColor="#6AC1EA" />
        <stop offset="1" stopColor="#74C7ED" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_493_4507"
        x1="7.67918"
        y1="2.49602"
        x2="2.88674"
        y2="8.25789"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.09" stopColor="#D5DD28" />
        <stop offset="0.17" stopColor="#D0D928" />
        <stop offset="0.26" stopColor="#C2CF28" />
        <stop offset="0.36" stopColor="#AABE28" />
        <stop offset="0.46" stopColor="#8AA729" />
        <stop offset="0.57" stopColor="#60892A" />
        <stop offset="0.67" stopColor="#2D652B" />
        <stop offset="0.72" stopColor="#17562C" />
        <stop offset="0.87" stopColor="#172F17" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_493_4507"
        x1="9.6258"
        y1="0.0641481"
        x2="16.9187"
        y2="9.34155"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#17447F" />
        <stop offset="0.16" stopColor="#1C639E" />
        <stop offset="0.41" stopColor="#259BD6" />
        <stop offset="0.59" stopColor="#38A5DB" />
        <stop offset="0.94" stopColor="#6AC1EA" />
        <stop offset="1" stopColor="#74C7ED" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_493_4507"
        x1="10.374"
        y1="14.1471"
        x2="15.3951"
        y2="8.1111"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.09" stopColor="#D5DD28" />
        <stop offset="0.17" stopColor="#D0D928" />
        <stop offset="0.26" stopColor="#C2CF28" />
        <stop offset="0.36" stopColor="#AABE28" />
        <stop offset="0.46" stopColor="#8AA729" />
        <stop offset="0.57" stopColor="#60892A" />
        <stop offset="0.67" stopColor="#2D652B" />
        <stop offset="0.72" stopColor="#17562C" />
        <stop offset="0.87" stopColor="#172F17" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
