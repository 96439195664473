import React from "react";
import Box from "@material-ui/core/Box";
import { zeroStateHistory } from "components/common/LunaLens/images/images";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";

const ZeroState = ({
  title = "No Insights yet",
  message = "Do some awesome like create your first insights report.",
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.zeroBodyContainer}>
      {zeroStateHistory()}
      <Typography
        variant="h4"
        align="center"
        className={classes.zeroStateTitle}
      >
        {title}
      </Typography>
      <Typography align="center">{message}</Typography>
    </Box>
  );
};

export default ZeroState;
