import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const ImageLimitError = ({ visible }) => {
  const classes = useStyles();
  const [show, setShow] = useState(visible);

  useEffect(() => {
    if (visible) {
      setShow(true);
    } else {
      const timer = setTimeout(() => setShow(false), 300);
      return () => clearTimeout(timer);
    }
  }, [visible]);

  return (
    <Box
      className={classes.imageLimitErrorContainer}
      style={{
        opacity: visible ? 1 : 0,
        visibility: show ? "visible" : "hidden",
        transition: "opacity 0.3s, visibility 0.3s",
      }}
    >
      <Typography variant="body1" style={{ color: "#9A1010" }}>
        You can only select 25 files per category at once.
      </Typography>
    </Box>
  );
};

export default ImageLimitError;
