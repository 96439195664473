import http from "./httpService";
import { apiUrl } from "lib/config";
import { generatePartnerFilterString } from "components/util/filterUtil";
import { setFormData } from "components/util/serviceUtil";
import { partnersTabOptions } from "constants.js";

const apiEndpoint = `${apiUrl}/partners`;
const apiEndpointSingular = `${apiUrl}/partner`;

export function ratePartner(id, data) {
  return http.post(`${apiEndpoint}/${id}/rate`, data);
}

export function getInactiveReasons() {
  return http.get(`${apiEndpoint}/inactive-reasons`);
}

export function changePartnerStatus(id, data) {
  return http.post(`${apiEndpoint}/${id}/change-status`, data);
}

export function getTrades() {
  return http.get(`${apiEndpoint}/trades`);
}

export function getStates() {
  return http.get(`${apiEndpoint}/states`);
}

export function createCity(data) {
  return http.post(`${apiEndpoint}/cities`, data);
}

export function getCities() {
  return http.get(`${apiEndpoint}/cities`);
}

export function createZipCode(data) {
  return http.post(`${apiEndpoint}/zip-codes`, data);
}

export function getZipCodes() {
  return http.get(`${apiEndpoint}/zip-codes`);
}

export function getCompanies() {
  return http.get(`${apiEndpoint}/companies`);
}

export function getPartnerProfileCompletion(id) {
  return http.get(`${apiEndpoint}/${id}/profile-completation`);
}

export function createPartner(data) {
  return http.post(`${apiEndpointSingular}`, data);
}

export function deletePartner(id) {
  return http.delete(`${apiEndpoint}/${id}`);
}

function getTabById(id) {
  const option = partnersTabOptions.find((tabOption) => tabOption.id === id);
  return option ? option.tab : null;
}

export function getPartners(filterData, tabId) {
  const tab = getTabById(tabId);
  const filterString = generatePartnerFilterString(filterData);
  return http.get(`${apiEndpoint}?${filterString}filters[tab]=${tab}`);
}

export function getPartnersTabOffset(
  tabId,
  offset,
  filterData,
  filterAddon = undefined
) {
  const tab = getTabById(tabId);
  const filterString = generatePartnerFilterString(filterData);
  return http.get(
    `${apiEndpoint}?${filterString}filters[tab]=${tab}&offset=${offset}${
      filterAddon ?? ""
    }`
  );
}
