import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export const SelectedImageCheck = () => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_254_3083)">
      <rect width="20" height="20" rx="10" fill="white" />
      <path
        d="M9.99999 1.66663C5.39999 1.66663 1.66666 5.39996 1.66666 9.99996C1.66666 14.6 5.39999 18.3333 9.99999 18.3333C14.6 18.3333 18.3333 14.6 18.3333 9.99996C18.3333 5.39996 14.6 1.66663 9.99999 1.66663ZM7.74166 13.575L4.74999 10.5833C4.42499 10.2583 4.42499 9.73329 4.74999 9.40829C5.07499 9.08329 5.59999 9.08329 5.92499 9.40829L8.33332 11.8083L14.0667 6.07496C14.3917 5.74996 14.9167 5.74996 15.2417 6.07496C15.5667 6.39996 15.5667 6.92496 15.2417 7.24996L8.91666 13.575C8.59999 13.9 8.06666 13.9 7.74166 13.575Z"
        fill="#4F98BC"
      />
    </g>
    <defs>
      <clipPath id="clip0_254_3083">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);
