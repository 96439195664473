import React from "react";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import SuccessIcon from "@material-ui/icons/CheckCircle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import useStyles from "../../File/dialog/styles";

const MessageDialogResumeFilesLens = ({
  open,
  title,
  successFiles,
  failedFiles,
  listFailed,
  handleClose,
  isLoadingData,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      maxWidth={"xs"}
      fullWidth={true}
      PaperProps={{
        style: {
          backgroundColor: isLoadingData ? "transparent" : "white",
          boxShadow: "none",
        },
      }}
    >
      {isLoadingData ? (
        <Box className={classes.loadingContainer}>
          <LoadingIndicator />
        </Box>
      ) : (
        <>
          <DialogContent className={classes.wrapperDialog}>
            <IconButton
              aria-label="close"
              onClick={() => handleClose(false)}
              className={classes.wrapperDialogClose}
            >
              <CloseIcon className={classes.iconDialogClose} />
            </IconButton>
            <SuccessIcon className={classes.iconSuccessDialog} />
            <Typography
              variant="h6"
              gutterBottom
              className={classes.titleDialog}
            >
              {title}
            </Typography>
            {successFiles && (
              <Typography
                variant="body1"
                gutterBottom
                className={classes.contentDialog}
              >
                <span>Successfully uploaded:</span> {successFiles}
              </Typography>
            )}
            {failedFiles && (
              <Typography
                variant="body1"
                gutterBottom
                className={classes.failedText}
              >
                <span>Failed uploads:</span> {failedFiles}
              </Typography>
            )}
            {listFailed && (
              <Box className={classes.contentDialogCenter}>
                <Typography variant="caption" gutterBottom>
                  {listFailed.split("|").map((file, index) => (
                    <React.Fragment key={index}>
                      {file}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions className={classes.wrapperDialogAction}>
            <Button
              onClick={handleClose}
              className={classes.button}
              color="primary"
              variant="contained"
              size="large"
            >
              Okay
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default MessageDialogResumeFilesLens;
