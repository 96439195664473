import React, { useState, useEffect, useRef } from "react";
import eosOneLogo from "../../../assets/logo-eos-one.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import CloseIcon from "@material-ui/icons/Close";
import { PinIcon, downloadIconSimple } from "./icons";
import Thumbnail from "./thumbnail";
import CommentPartnerList from "./commentPartnerList";
import AddCommentPartnerButton from "./addCommentPartnerButton";
import EmbeddedPdf from "./embeddedPdf";
import VideoPlayer from "./videoPlayer";
import TabPartnerComment from "./tabPartnerComment";
import { validateWistiaLink } from "components/util/youtubeUtil";
import { mimeTypes } from "constants.js";
import {
  dateFormatField,
  convertTimeToSeconds,
} from "components/util/timeFormat";
import { getCommentByFileId } from "services/fileService";
import * as classNames from "classnames";
import { logException } from "components/util/logUtil";
import useStyles from "./styles";

const FilePreviewPartnerDialog = ({
  open,
  onClose,
  files,
  partnerId,
  currentImageIndex,
}) => {
  const classes = useStyles();
  const [dialogImageIndex, setDialogImageIndex] = useState(currentImageIndex);
  const [activeTab, setActiveTab] = useState(null);
  const [commentList, setCommentList] = useState([]);
  const [isLoadingCommentsList, setIsLoadingCommentsList] = useState(true);
  const playerRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    setDialogImageIndex(currentImageIndex);
  }, [currentImageIndex]);

  // Reset playedTime to 0 when the video changes
  useEffect(() => {
    const loadFile = async () => {
      // Load files comments
      setIsLoadingCommentsList(true);
      const fileSelected = files[dialogImageIndex];
      const response = await getCommentByFileId(fileSelected.id);
      const dataComment = response.data.data;
      const firstKey = Object.keys(dataComment)[0];
      if (firstKey) {
        setActiveTab(firstKey);
        setCommentList(dataComment[firstKey]);
      }
      setIsLoadingCommentsList(false);
    };
    // Reset playedTime to 0
    loadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogImageIndex]);

  const refreshCommentData = async () => {
    setIsLoadingCommentsList(true);
    const fileSelected = files[dialogImageIndex];
    const response = await getCommentByFileId(fileSelected.id);
    const dataComment = response.data.data;
    setCommentList(dataComment[activeTab]);
    setIsLoadingCommentsList(false);
  };

  const handleClick = (index) => {
    setDialogImageIndex(index);
  };

  const handleDownloadClick = () => {
    window.open(files[dialogImageIndex].file_url, "_blank");
  };

  const handleTimeClick = (time) => {
    const seconds = convertTimeToSeconds(time);
    if (playerRef.current) {
      playerRef.current.seekTo(seconds, "seconds");
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const videoUrl = files[dialogImageIndex]?.file_url;
  if (!videoUrl) {
    logException("file_url is undefined or dialogImageIndex is out of bounds");
    return;
  }
  const isWistiaVideo = validateWistiaLink(videoUrl);

  const renderHeaderBar = () => {
    return (
      <Box className={classes.headerBar}>
        {/* Logo and pinning */}
        <img src={eosOneLogo} alt="Encompass Onsite" className={classes.logo} />
      </Box>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth fullScreen={true}>
      {/* Header */}

      <Box className={classes.videoPreviewHeaderContainer}>
        <IconButton
          className={classes.closeIconButtonDialog}
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <DialogContent className={classes.dialogNormal}>
        <Box className={classes.flexColumnContainer}>
          <Box className={classes.flexColumnChild}>
            {/* File viewer */}
            <Box
              className={classNames(
                classes.fileViewerContainer,
                classes.normalBorder
              )}
            >
              {renderHeaderBar()}
              {/* Specific content based on file type */}
              <Box
                className={classes.fileViewerContainerInner}
                id="contentViewerId"
              >
                {files[dialogImageIndex] && (
                  <>
                    {/* Render PDF file */}
                    {parseInt(files[dialogImageIndex].mime_type) ===
                      mimeTypes.PDF && (
                      <>
                        <EmbeddedPdf
                          src={files[dialogImageIndex].file_url}
                          height={"100%"}
                        />
                      </>
                    )}
                    {/* Render YT, vimeo, wistia, mp4 files and link 360  */}
                    {[
                      mimeTypes.VIDEO,
                      mimeTypes.LINK,
                      mimeTypes.LINK_360,
                    ].includes(parseInt(files[dialogImageIndex].mime_type)) && (
                      <>
                        <Box>
                          <VideoPlayer
                            key={isWistiaVideo ? false : null}
                            url={files[dialogImageIndex].file_url}
                            playerRef={playerRef}
                            isFullScreen={false}
                            fullScreenPlayer={false}
                            initialSeekTime={0}
                            onVideoDurationChange={() => {}}
                          />
                        </Box>
                      </>
                    )}

                    {/* Render image file */}
                    {parseInt(files[dialogImageIndex].mime_type) ===
                      mimeTypes.IMAGE && (
                      <Box className={classes.previewImageContainer}>
                        <Box
                          className={classes.imageGallery}
                          style={{
                            backgroundImage: `url('${files[dialogImageIndex].file_url}')`,
                          }}
                        ></Box>
                      </Box>
                    )}
                  </>
                )}
              </Box>
              {/* Info section and File Details */}

              <Box className={classes.fileInfoBoxBorder}>
                <Box className={classes.imageInfoContainerStyle}>
                  <Typography
                    variant="body1"
                    className={classes.imageNameStyle}
                  >
                    {files[dialogImageIndex]?.display_name || ""}
                  </Typography>
                  {![mimeTypes.LINK, mimeTypes.LINK_360].includes(
                    parseInt(files[dialogImageIndex].mime_type)
                  ) &&
                    user?.is_super && (
                      <Button
                        className={classes.previewFileBtnDownload}
                        variant="outlined"
                        startIcon={downloadIconSimple()}
                        onClick={handleDownloadClick}
                      >
                        <Typography variant="body2">Download</Typography>
                      </Button>
                    )}
                </Box>
                <Typography
                  variant="body2"
                  className={classes.fileInfoTypography}
                >
                  {[
                    files[dialogImageIndex]?.owner,
                    files[dialogImageIndex]?.file_size
                      ? files[dialogImageIndex].file_size + " MB"
                      : "",
                    dateFormatField(files[dialogImageIndex]?.created_at || ""),
                  ]
                    .filter(Boolean)
                    .join(" | ")}
                </Typography>
              </Box>
            </Box>
            {/* Mini Gallery */}
            <Box className={classes.thumbnailContainer}>
              <Box className={classes.thumbnails}>
                {files.map((file, index) => (
                  <Thumbnail
                    key={index}
                    file={file}
                    width={92}
                    height={64}
                    onClick={() => handleClick(index)}
                    pinIcon={parseInt(file.is_pinned) === 1 && PinIcon()}
                  />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className={classes.commentSectionStyle}>
          {/* Comment list */}
          <Box style={{ marginLeft: 16, marginRight: 16, marginBottom: 16 }}>
            <TabPartnerComment
              activeTab={activeTab}
              onChangeTab={handleTabChange}
            />
          </Box>
          <Box className={classes.scrollableBox}>
            <Box style={{ padding: 16 }}>
              <CommentPartnerList
                data={commentList}
                file={files[dialogImageIndex]}
                partnerId={partnerId}
                activeTab={activeTab}
                onTimeClick={handleTimeClick}
                refreshData={refreshCommentData}
                isLoadingComments={isLoadingCommentsList}
              />
            </Box>
          </Box>
          {/* Button to add comments */}
          <Box className={classes.commentsInputContainer}>
            <Box className={classes.timestampContainer}>
              <AddCommentPartnerButton
                file={files[dialogImageIndex]}
                partnerId={partnerId}
                activeTab={activeTab}
                refreshData={refreshCommentData}
                onCommentChange={() => {}}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FilePreviewPartnerDialog;
