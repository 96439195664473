import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";
import CategorySelectionTagList from "./categorySelectionTagList";

const AnalysisPromptCard = ({ prompt, categories }) => {
  const classes = useStyles();
  return (
    <Box className={classes.promptContainer}>
      {categories && categories.length > 0 && (
        <CategorySelectionTagList
          categories={categories.map((category) => category.luna_lens_category)}
        />
      )}
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 400,
          marginTop: categories.length > 0 ? 8 : 0,
        }}
      >
        {prompt}
      </Typography>
    </Box>
  );
};

export default AnalysisPromptCard;
