import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { StyledTab, StyledTabs } from "components/common/Table/stylesTabs";
import Skeleton from "@material-ui/lab/Skeleton";
import ProjectTabActivity from "./projectTabActivity";
import ProjectTabWorkticket from "./projectTabWorkticket";
import ProjectTabWorkticketPreventive from "./projectTabWorkticketPreventive";
import ProjectTabQuote from "./projectTabQuote";
import { useProjectView } from "contexts/projectViewContext";
import ProjectTabBilling from "./projectTabBilling";
import ProjectTabBillingPreventive from "./projectTabBillingPreventive";
import AssetsList from "./assetsTab/assetsList";
import ServicesList from "./servicesTab/servicesList";
import JobsList from "./jobsTab/jobsList";
import useStyles from "./styles";

const TabOptions = [
  { id: 1, text: "Activity", tab: "activity", onTabing: true },
  { id: 6, text: "Services", tab: "services", onTabing: true },
  { id: 2, text: "Quotes", tab: "quotes", onTabing: true },
  { id: 3, text: "Worktickets", tab: "worktickets", onTabing: true },
  { id: 7, text: "Jobs", tab: "jobs", onTabing: true },
  { id: 4, text: "Billing", tab: "billing", onTabing: true },
  { id: 5, text: "Assets", tab: "assets", onTabing: true },
];

const VISUAL_INSPECTION_PROJECT_ID = 7;

const ProjectTabs = (props) => {
  const classes = useStyles();
  const [tab, setTab] = useState(1);
  const [stateContext] = useProjectView();
  const { isLoading, project } = stateContext ?? null;

  const handleTabChange = (event, newTab) => {
    setTab(newTab);
  };

  if (isLoading) {
    return <Skeleton animation="wave" variant="rect" height={"100%"} />;
  }

  return (
    <Box className={classes.containerTabs}>
      <StyledTabs
        value={tab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="secondary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
        className={classes.rootTabs}
      >
        {TabOptions.filter(
          (item) =>
            item.onTabing &&
            (item.text !== "Services" ||
              ([3, 6, VISUAL_INSPECTION_PROJECT_ID].includes(project.type) &&
                (!project.is_external ||
                  (project.is_external && project.type_external === 1)))) &&
            (item.text !== "Billing" ||
              [6, 5, 4, 3, 2, 1, VISUAL_INSPECTION_PROJECT_ID].includes(
                project.type
              )) &&
            (item.text !== "Assets" || Boolean(project.assets.length)) &&
            (item.text !== "Quotes" || [5, 4, 2, 1].includes(project.type)) &&
            (item.text !== "Jobs" ||
              ([3, 6, VISUAL_INSPECTION_PROJECT_ID].includes(project.type) &&
                (!project.is_external ||
                  (project.is_external && project.type_external === 1))))
        ).map((option) => {
          return (
            <StyledTab
              disableRipple={true}
              label={<Box className={classes.tabItem}>{option.text}</Box>}
              value={option.id}
              key={option.id}
            />
          );
        })}
      </StyledTabs>
      <Box className={classes.containerTabContent}>
        {tab === 1 ? <ProjectTabActivity /> : null}
        {tab === 2 ? <ProjectTabQuote /> : null}
        {tab === 3 ? (
          [3, 6, VISUAL_INSPECTION_PROJECT_ID].includes(project?.type) ? (
            <ProjectTabWorkticketPreventive />
          ) : (
            <ProjectTabWorkticket />
          )
        ) : null}
        {tab === 4 ? (
          project?.type === 3 ||
          project?.type === 6 ||
          project?.type === VISUAL_INSPECTION_PROJECT_ID ? (
            <ProjectTabBillingPreventive />
          ) : (
            <ProjectTabBilling />
          )
        ) : null}
        {tab === 5 ? <AssetsList /> : null}
        {tab === 6 ? <ServicesList /> : null}
        {tab === 7 ? <JobsList /> : null}
      </Box>
    </Box>
  );
};

export default ProjectTabs;
