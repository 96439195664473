import React from "react";
import Box from "@material-ui/core/Box";
import { LunaIcon } from "./images/images";

import useStyles from "./styles";

const AnalysisLunaChat = ({ width = 34, height = 28, size }) => {
  const classes = useStyles();

  return (
    <Box
      className={classes.lunaIconContainer}
      style={{
        padding: size === "medium" ? "8px 6px 4px 6px" : "12px 10px 8px 10px",
      }}
    >
      <LunaIcon width={width} height={height} />
    </Box>
  );
};

export default AnalysisLunaChat;
