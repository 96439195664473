import React from "react";
import Box from "@material-ui/core/Box";
import useStyles from "./styles";
import Typography from "@material-ui/core/Typography";
import { NoComments } from "components/common/LunaLens/images/images";

const ZeroStateCommentsLens = () => {
  const classes = useStyles();
  return (
    <Box className={classes.zeroStateComments}>
      <Box className={classes.zeroBodyContainerComments}>
        <Box className={classes.zeroStatePhotosCircle}>{<NoComments />}</Box>
        <Typography align="center" className={classes.zeroStateTitle}>
          No Comments Yet
        </Typography>
        <Typography
          align="center"
          style={{ fontSize: 14, fontWeight: "400", wordWrap: "break-word" }}
        >
          Do something awesome like creating the first comment on this report.
        </Typography>
      </Box>
    </Box>
  );
};

export default ZeroStateCommentsLens;
