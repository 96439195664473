import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core/";
import PropTypes from "prop-types";
import classNames from "classnames";
import useStyles from "./styles";

const PENDING = 0;
const FAILED = 2;

const calculateProcessingTime = (x) => {
  return 0.05 * Math.pow(x, 2) - 0.6 * x + 19.8;
};

const ProgressBar = ({
  startDateTime,
  totalFiles,
  uniqueFiles,
  batchFiles,
  processingTimePerBatch,
  status,
  view,
  errorMessage,
}) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [minutesLeft, setMinutesLeft] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    // Clear the interval when the component is unmounted or when the state changes
    if (intervalId) {
      clearInterval(intervalId);
    }

    if (status === FAILED) {
      setProgress(50);
      return;
    }

    // Calculate progress only if the state is "Pending"
    if (status === PENDING) {
      const totalTime = calculateProcessingTime(uniqueFiles);
      const startDate = new Date(startDateTime).getTime();

      const updateProgress = () => {
        const currentTime = new Date().getTime();
        const elapsedTime = (currentTime - startDate) / 1000;

        const currentProgress = (elapsedTime / totalTime) * 100;
        const remainingTime = Math.max(totalTime - elapsedTime, 0);

        setProgress(Math.min(currentProgress, 100));
        setMinutesLeft(Math.ceil(remainingTime / 60));
      };

      updateProgress();
      const id = setInterval(updateProgress, 1000);
      setIntervalId(id);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateTime, totalFiles, batchFiles, processingTimePerBatch, status]);

  const isFailed = status === FAILED;
  const isCompleted = progress === 100;
  const isPending = status === PENDING;

  const getDisplayMessage = () => {
    if (isFailed) {
      return errorMessage
        ? errorMessage.length > 100
          ? errorMessage.slice(0, 100) + "..."
          : errorMessage
        : "There was an error in your analysis";
    } else if (isCompleted) {
      return "Finishing your Analysis";
    } else {
      return `${minutesLeft} mins left`;
    }
  };

  return (
    <Box className={classes.progressBarContainer}>
      <Box
        className={classNames(classes.progressBarBackground, {
          [classes.progressErrorBackground]: isFailed,
        })}
      >
        <Box
          className={classNames(classes.progressBarFill, {
            [classes.progressBarError]: isFailed,
          })}
          style={{ width: `${progress}%` }}
        />
      </Box>
      <Box className={view ? classes.infoContainerView : classes.infoContainer}>
        {isPending && progress !== 100 && (
          <Typography className={classes.totalFilesLabel}>
            {totalFiles} files are being reviewed
          </Typography>
        )}
        <Typography
          className={classNames(classes.timeLabel, {
            [classes.errorLabel]: isFailed,
          })}
        >
          {getDisplayMessage()}
        </Typography>
      </Box>
    </Box>
  );
};

ProgressBar.propTypes = {
  startDateTime: PropTypes.string.isRequired,
  totalFiles: PropTypes.number.isRequired,
  batchFiles: PropTypes.number.isRequired,
  processingTimePerBatch: PropTypes.number.isRequired,
  status: PropTypes.oneOf([0, 2]).isRequired,
};

export default ProgressBar;
