import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useStyles from "./styles";

const mapping = {
  1: {
    text: "In Progress",
    color: "#78C1CE",
  },
  2: {
    text: "Completed",
    color: "#9cbb65",
  },
};
const SurveyChip = ({ status = 1, style }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.radiusChip}
      style={{ backgroundColor: mapping[status]?.color, ...style }}
    >
      <Typography className={classes.chipFontColor}>
        {mapping[status]?.text}
      </Typography>
    </Box>
  );
};
export default SurveyChip;
