import React, { useState, useEffect } from "react";
import { FixedSizeList as List } from "react-window";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormSelectAuto from "components/ui/FormContent/formSelectAuto";
import LoadingIndicator from "components/common/LoadingIndicator/loadingIndicator";
import * as classnames from "classnames";
import useStyles from "./styles";

const itemSize = 50;
const filterTypesWithStateSelection = ["cities", "zipCodes"];
const filterTypesWithCitySelection = ["zipCodes"];
const filterOptions = {
  zipCodes: {
    display: "zip Codes",
    key: "zipCodes",
  },
  city: {
    display: "cities",
    key: "cities",
  },
  trade: {
    display: "trades",
    key: "trades",
  },
  state: {
    display: "states",
    key: "states",
  },
};

const FilterModal = ({
  isOpen,
  onCloseModal,
  availableStates,
  filterType,
  optionsList,
  currentSelections,
  onFilterChange,
}) => {
  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredStates, setFilteredStates] = useState(
    currentSelections.states || []
  );
  const [filteredCities, setFilteredCities] = useState(
    currentSelections.cities || []
  );

  const filteredOptions = optionsList.filter((item) => {
    const matchesSearch = item.label
      .toLowerCase()
      .includes(searchTerm.toLowerCase());

    let matchesState = true;
    let matchesCity = true;

    // Filter by states for cities
    if (filterType === filterOptions.city.key) {
      matchesState =
        filteredStates.length > 0
          ? filteredStates.some(
              (state) => item.state_abbreviation === state.value
            )
          : false;
    } else if (filterType === filterOptions.zipCodes.key) {
      // Filter by states and cities for zip codes
      matchesState =
        filteredStates.length > 0
          ? filteredStates.some(
              (state) => item.state_abbreviation === state.value
            )
          : false;
      matchesCity =
        filteredCities.length > 0
          ? filteredCities.some((city) => item.city === city.label)
          : false;
    }

    return (
      matchesSearch &&
      matchesState &&
      (filterType !== filterOptions.zipCodes.key || matchesCity)
    );
  });

  useEffect(() => {
    if (isOpen) {
      handleClear();
      setSelectedItems((prevSelected) => {
        const selectedIds =
          currentSelections[filterType]?.map((item) => item.value.toString()) ||
          [];
        return selectedIds;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    const selectedIds =
      currentSelections[filterType]?.map((item) => item.value.toString()) || [];
    setSelectedItems(selectedIds);
  }, [currentSelections, filterType]);

  useEffect(() => {
    setFilteredStates(currentSelections.states || []);
  }, [currentSelections.states]);

  useEffect(() => {
    setFilteredCities(currentSelections.cities || []);
  }, [currentSelections.cities]);

  useEffect(() => {
    setCheckAll(selectedItems.length === filteredOptions.length);
  }, [selectedItems, filteredOptions.length]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedItems((prevSelected) =>
      prevSelected.includes(value)
        ? prevSelected.filter((selectedValue) => selectedValue !== value)
        : [...prevSelected, value]
    );
  };

  const handleCheckAllChange = (event) => {
    if (event.target.checked) {
      const allIds = filteredOptions.map((item) => item.value.toString());
      setSelectedItems(allIds);
    } else {
      setSelectedItems([]);
    }
  };

  const handleSave = () => {
    const selected = selectedItems.map((value) =>
      optionsList.find((item) => item.value.toString() === value)
    );
    onFilterChange(selected);
    onCloseModal();
  };

  const handleSelectStateChange = (event, newValue) => {
    setFilteredStates(newValue);
  };

  const handleSelectCityChange = (event, newValue) => {
    setFilteredCities(newValue);
  };

  const handleClear = () => {
    setSearchTerm("");
  };

  const getFilterLabel = () => filterOptions[filterType]?.display || filterType;

  const renderRow = ({ index, style }) => {
    const item = filteredOptions[index];
    if (!item) return null;

    return (
      <Box
        key={item.value}
        display="flex"
        justifyContent="space-between"
        width="100%"
        style={style}
        className={classnames(classes.checkboxItem, {
          [classes.selectedCheckbox]: selectedItems.includes(
            item.value.toString()
          ),
        })}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedItems.includes(item.value.toString())}
              value={item.value.toString()}
              onChange={handleCheckboxChange}
            />
          }
          label={item.label}
          style={{ flex: 1 }}
        />

        {/* Show City/State rows */}
        {filterType === filterOptions.zipCodes.key && (
          <>
            <Box className={classes.flexRowText}>{item.city}</Box>
            <Box className={classes.flexRowText}>{item.state_abbreviation}</Box>
          </>
        )}

        {/* Show Abbreviation rows */}
        {(filterType === filterOptions.state.key ||
          filterType === filterOptions.city.key) && (
          <Box className={classes.flexRowText}>
            {filterType === filterOptions.state.key
              ? item.value
              : item.state_abbreviation}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Modal open={isOpen} onClose={onCloseModal} className={classes.modal}>
      <Box className={classes.modalContent}>
        <Box className={classes.modalHeader}>
          <Box className={classes.btnContainer}>
            <Button
              variant="contained"
              color="primary"
              className={classes.btnApply}
              onClick={handleSave}
              autoFocus
            >
              Apply
            </Button>
            <IconButton
              className={classes.modalCloseButton}
              onClick={onCloseModal}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="caption" className={classes.selectedText}>
            {selectedItems.length}/{filteredOptions.length} {getFilterLabel()}{" "}
            selected for this partner
          </Typography>
        </Box>

        <Box className={classes.modalBody}>
          <Box className={classes.bottomRow}>
            <Box>
              <Typography className={classes.itemCount}>
                {filteredOptions.length} {getFilterLabel()}
              </Typography>

              <TextField
                variant="outlined"
                placeholder={`Search ${getFilterLabel()}`}
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      {searchTerm ? (
                        <IconButton onClick={handleClear}>
                          <ClearIcon />
                        </IconButton>
                      ) : (
                        <SearchIcon />
                      )}
                    </InputAdornment>
                  ),
                  className: classes.inputPropsStyle,
                }}
                className={classnames(classes.searchInput, classes.noFocus)}
              />
            </Box>

            <Box className={classes.containerInternalFilters}>
              {filterTypesWithCitySelection.includes(filterType) && (
                <Box className={classes.stateBox}>
                  <Typography className={classes.stateLabel}>
                    Cities Serviced
                  </Typography>
                  <FormSelectAuto
                    name="cities"
                    multiple
                    placeholder="Select City"
                    options={currentSelections.cities}
                    value={currentSelections.cities.filter((city) =>
                      filteredCities
                        .map((selectedCity) => selectedCity.value)
                        .includes(city.value)
                    )}
                    handleChange={handleSelectCityChange}
                  />
                </Box>
              )}
              {filterTypesWithStateSelection.includes(filterType) && (
                <Box
                  className={classnames(
                    classes.stateBox,
                    classes.searchInputWithStateSelection
                  )}
                >
                  <Typography className={classes.stateLabel}>
                    States Serviced
                  </Typography>
                  <FormSelectAuto
                    name="states"
                    multiple
                    placeholder="Select State"
                    options={currentSelections.states}
                    value={currentSelections.states.filter((state) =>
                      filteredStates
                        .map((selectedState) => selectedState.value)
                        .includes(state.value)
                    )}
                    handleChange={handleSelectStateChange}
                  />
                </Box>
              )}
            </Box>
          </Box>
          {optionsList.length === 0 || filterOptions.length === 0 ? (
            <Box mt={5} style={{ backgroundColor: "#ffffff" }}>
              <LoadingIndicator />
            </Box>
          ) : (
            <Box className={classes.checkboxContainer}>
              <Box
                className={classes.checkboxGroupContainer}
                display="flex"
                alignItems="center"
                width="100%"
              >
                {/* Check All Checkbox */}
                <Box style={{ flex: 1 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkAll}
                        onChange={handleCheckAllChange}
                      />
                    }
                    label={
                      <span className={classes.checkboxGroupLabel}>
                        {getFilterLabel()}
                      </span>
                    }
                  />
                </Box>
                {/* Title - Conditional rendering based on filterType */}
                {filterType === filterOptions.zipCodes.key && (
                  <>
                    <Box className={classes.leftAlignedFlexBox}>
                      <Typography className={classes.checkboxGroupLabel}>
                        City
                      </Typography>
                    </Box>
                    <Box className={classes.leftAlignedFlexBox}>
                      <Typography className={classes.checkboxGroupLabel}>
                        State
                      </Typography>
                    </Box>
                  </>
                )}
                {(filterType === filterOptions.state.key ||
                  filterType === filterOptions.city.key) && (
                  <>
                    <Box className={classes.leftAlignedFlexBox}>
                      <Typography className={classes.checkboxGroupLabel}>
                        {filterType === filterOptions.state.key
                          ? "Abbreviation"
                          : "State"}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>

              <List
                height={600}
                itemCount={filteredOptions.length}
                itemSize={itemSize}
                width="100%"
              >
                {renderRow}
              </List>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default FilterModal;
